import * as React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, TextInput} from 'react-native';
import Colors from '../constants/Colors';

export default class FilterBar extends React.Component {

  constructor(props){
    super(props);
    this.state={value:""};
  }


  filterData(e){
      let result= this.props.data.filter(item=>{
            let name= (item.lastname+" "+item.firstname).toUpperCase();
            return name.indexOf(this.state.value.toUpperCase())!==-1;
      });
      this.props.onResult(result);
  }

  render(){
    return  <View style={styles.searchBar}>
              <TextInput style={styles.searchInput} 
                        onChangeText={(text)=>this.setState({value:text})}
                        onBlur={()=>this.filterData()}
                        placeholder="Recherche un étudiant"/>
              <TouchableOpacity style={styles.itemStudent}
                        style={styles.searchButton} 
                        onPress={()=>{this.filterData()}}>
                <Text style={{color:"white"}}>Filtrer</Text>
              </TouchableOpacity>
            </View>
  }
}

const styles = StyleSheet.create({
  searchBar:{
    flexDirection:"row"
  },
  searchInput:{
    marginLeft: 18,
    padding: 5,
    flex:1
  },
  searchButton:{
    padding: 5,
    backgroundColor: Colors.tintColor,
  }
});
