import * as React from 'react';
import { Picker } from 'react-native';

export default function(props){
    return <Picker
                selectedValue={props.selectedValue}
                style={props.style}
                onValueChange={(item,index)=>{
                    props.onValueChange(item,index)
                }}
                >
                {props.data.map((item, index)=>{
                    return <Picker.Item key={index} 
                                 label={item.name} 
                                 value={item.id} />
                })}
            </Picker>
}

//onValueChange={(itemValue, itemIndex)=>{props.onValueChange(itemValue, itemIndex)}}>
                