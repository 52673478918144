import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../../constants/Colors';
import FormTutor from '../../components/FormTutor';
import SelectTutor from '../society/SelectTutor';
import { updateRessource } from '../../services/server_api';
import Layout from '../../constants/Layout';

export default class FormContratTutor extends Component{

    constructor(props){
        super(props);
        this.state={showSelectForm:false, showCreateForm:false}
    }

    onSelectTutor(tutor){
        if(confirm("Etes-vous sur de lier ce tuteur à ce contrat ?")){
            updateRessource("inscription", this.props.inscription.id, {tutor:tutor.id}).then(result=>{
                let inscription= Object.assign({}, this.props.inscription);
                inscription.tutor= tutor.id;
                this.props.refresh(inscription);
            });
        }
    }

    unlinkTutor(){
        if(confirm("Etes-vous sur de délier ce tuteur à ce contrat ?")){
            updateRessource("inscription", this.props.inscription.id, {tutor:null}).then(result=>{
                let inscription= Object.assign({}, this.props.inscription);
                inscription.tutor= null;
                this.props.refresh(inscription);
            });
        }
    }

    onCreateTutor(tutorId){
        updateRessource("inscription", this.props.inscription.id, {tutor:tutorId}).then(result=>{
            let inscription= Object.assign({}, this.props.inscription);
            inscription.tutor= tutorId;
            this.props.refresh(inscription);
        });
    }

    render(){
        return <View style={styles.container}>
                    {this.props.data&&
                        <View style={styles.rowPart}>
                            <FormTutor data={this.props.data}
                                       society={this.props.society}
                                       onHide={()=>this.setState({showCreateForm:false})}/>
                            {Layout.window.width > 450 &&
                                <TouchableOpacity style={styles.button}
                                                    onPress={()=>this.unlinkTutor()}>
                                    <Text style={styles.text}>Délier ce tuteur</Text>
                                </TouchableOpacity>
                            }
                        </View>
                    }

                    {!this.props.data && this.state.showSelectForm && Layout.window.width > 450 &&
                        <SelectTutor society={this.props.society}
                                     onHide={()=>this.setState({showSelectForm:false})}
                                     onSelect={this.onSelectTutor.bind(this)}/>
                    }

                    {!this.props.data && this.state.showCreateForm && Layout.window.width > 450 &&
                        <FormTutor data={null}
                                    society={this.props.society}
                                    onHide={()=>this.setState({showCreateForm:false})}
                                    onCreate={this.onCreateTutor.bind(this)}
                        />
                    }

                    {!this.props.data && !this.state.showSelectForm &&  !this.state.showCreateForm && Layout.window.width > 450 &&
                        <View style={styles.rowPart}>
                            <TouchableOpacity style={styles.button}
                                                onPress={()=>this.setState({showSelectForm:true})}>
                                <Text style={styles.text}>Lier à un employé existant</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button}
                                                onPress={()=>this.setState({showCreateForm:true})}>
                                <Text style={styles.text}>Créer un nouvel employé</Text>
                            </TouchableOpacity>
                        </View>
                    }
                </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    title:{
        flex:1,
        backgroundColor:Colors.tintColor,
        color:"white",
        padding:10,
        marginTop:10,
        textAlign:"center",
        fontWeight:"bold"
    },
    rowPart:{
        flexDirection:'row',
        flex:1,
        justifyContent:"space-evenly",
        marginTop:10,
    },
    button:{
        padding:10,
        backgroundColor:Colors.tintColor,
        margin:5,
        flex:1,
        justifyContent:"center",
        alignItems:"center",
        alignSelf:"stretch"
    },
    text:{
        color:"white",
    }
});