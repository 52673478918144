import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../../constants/Layout';
import {setModalVisibleCandidate, setSelectedStudent, setModalVisibleStudent} from "../../../store/actions/adminActions";
import { connect } from 'react-redux';
import Colors from '../../../constants/Colors';
import Loader from '../../../components/Loader';

class ModalListCandidates extends Component{
    render(){
        //console.log("Candidate data",this.props)
        return(
            
            <View style={this.props.showModalCandidate ? styles.container : {display:"none"}}>
            {
                this.props.showModalCandidate &&
                <View style={styles.container}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={[styles.title, {color: Colors.getPromoColor(this.props.dataPromo)}]}>Promotion #{this.props.selectedPromotion}</Text>
                        { this.props.listStudentsCandidates.length<1&&
                            <Loader isVisible={true}></Loader>   
                        }
                        <ScrollView contentContainerStyle={styles.content}>                
                        { this.props.listStudentsCandidates.map((item, index) => {
                            return <TouchableHighlight key={index} style={styles.itemStudent}
                                onPress={()=>{this.props.setSelectedStudent(item.id);
                                            this.props.setModalVisibleStudent(true)}}>
                                <View>
                                    <Text style={styles.itemStudentTextH1}>{item.lastname}</Text>
                                    <Text style={styles.itemStudentText}>{item.firstname}</Text>
                                    <Text style={styles.itemStudentType}>{InscrTypeName[item.type]}</Text>
                                </View>
                            </TouchableHighlight>
                        })}
                        </ScrollView>
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>this.props.setModalVisibleCandidate(false)}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }   
            </View>
        );
    }

}

const InscrTypeName={1:"Contrat Pro", 2:"Contrat Appr", 3:"Initiale"};

const mapStateToProps = state => {
    return {
        showModalCandidate: state.admin.showModalCandidate,
        listStudentsCandidates: state.admin.listStudentsCandidates,
        selectedPromotion: state.admin.selectedPromotion,
        dataPromo: state.admin.selectedPromotionData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setModalVisibleCandidate: data => {dispatch(setModalVisibleCandidate(data));},
        setModalVisibleStudent:data => {dispatch(setModalVisibleStudent(data));},
        setSelectedStudent: data => {dispatch(setSelectedStudent(data));},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalListCandidates);

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        flex:1,
        zIndex: 100,
        width: "100%",
        height: "100%",
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    content:{
        zIndex: 120,
        alignSelf: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    title:{
        width: Layout.window.width,
        fontSize: 24,
        textAlign:"center",
        backgroundColor:"white",
        marginBottom:10,
        padding:10
    },
    buttonClose:{
        position: "absolute",
        zIndex: 130,
        top:  0,
        right:10,
        backgroundColor:  'white',
    },
    itemStudent:{
      overflow:"hidden",
      padding:10,
      margin:3,
      backgroundColor: Colors.tintColor
    },
    itemStudentTextH1:{
      fontWeight:'bold',
      color:"white"
    },
    itemStudentText:{
      color:"white"
    },itemStudentType:{
        color: "yellow"
    }
});