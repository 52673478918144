export const USERAVATAR= "USERAVATAR";
export const USERDATA= "USERDATA";
export const TOKENID= "TOKENID";


export const FILTERDATE= "FILTERDATE";
export const FILTERTYPE= "FILTERTYPE";
export const FILTERLEVEL= "FILTERLEVEL";

export const storeData = (key, data) => {
    localStorage.setItem(key, data);
};

export const retrieveData = (key) => {
    return localStorage.getItem(key);
};

export const removeData = (key) => {
    return localStorage.removeItem(key);
};

export const isConnect = () => {
    return localStorage.getItem(TOKENID)!=null;
};