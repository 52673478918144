import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../constants/Colors';
import { getRessources } from '../services/server_api';
import ModalFormModule from '../containers/module/ModalFormModule';
import Layout from '../constants/Layout';

export default class ManageModulesScreen extends Component{

    constructor(props){
        super(props);
        this.state={modules:[], filtered:[], value:"", showModal:false, selectedModule:null}
    }

    componentDidMount(){
        this.refresh();
    }

    refresh(){
        getRessources('module').then(result=>{
            let modules= result.response.sort((a,b)=>a.code<b.code);
            this.setState({modules: modules, filtered: modules});
            this.filtered(this.state.value);
        });
    }

    filtered(value){
        let result= this.state.modules.filter(item=>{
            let name= (item.code+" "+item.name).toUpperCase();
            return name.indexOf(value.toUpperCase())!==-1;
        });
        this.setState({filtered: result, value: value});
    }

    render(){
        return <View style={styles.container}>
                <View style={styles.searchBar}>
                    <TextInput style={styles.searchInput}
                        value={this.state.value}
                        onChangeText={this.filtered.bind(this)}
                    />
                    <TouchableOpacity style={styles.button}
                        onPress={()=>this.setState({showModal:true, selectedModule:null})}
                        >
                        <Text style={styles.textTitleButton}>Nouveau module</Text>
                    </TouchableOpacity>
                </View>
                {Layout.window.width>450&&
                    <View style={{margin:10}}>
                    <Text>Les prefixes des codes correspondent à: </Text>
                    <View style={{flexDirection:"row"}}>
                        <Text style={{flex:1}}>ALLBTS = tous modules présents en BTS peut importe la filière </Text>
                        <Text style={{flex:1}}>BTSSIO = tous modules relatifs au BTS SIO </Text>
                        <Text style={{flex:1}}>BTSCG = tous modules relatifs au BTS CG </Text>
                    </View>
                    <View style={{flexDirection:"row"}}>
                        <Text style={{flex:1}}>ANR = tous modules relatifs aux Analystes Réseaux (RNCP N.6) </Text>
                        <Text style={{flex:2}}>AND = tous modules relatifs aux Analystes Développeurs (RNCP N.6) </Text>
                    </View>
                    <View style={{flexDirection:"row"}}>
                        <Text style={{flex:1}}>ARR = tous modules relatifs aux Architectes Réseaux (RNCP N.7) </Text>
                        <Text style={{flex:1}}>ARD = tous modules relatifs aux Architectes Développeurs (RNCP N.7) </Text>
                        <Text style={{flex:1}}>MSTL = tous modules relatifs au MASTER STL</Text>
                    </View>
                </View>
                }
                <ScrollView contentContainerStyle={{flexDirection:'row', flexWrap:"wrap", alignContent:"stretch"}}>
                    {this.state.filtered.map((item, index)=>{
                        return <TouchableOpacity key={index} style={styles.itemButton}
                                    onPress={()=>this.setState({showModal:true, selectedModule:item})}
                                >
                                <Text style={styles.textTitleButton}>{item.name}</Text>
                                <Text style={styles.textButton}>{item.code}</Text>
                            </TouchableOpacity>
                    })}
                </ScrollView>
                {this.state.showModal&&
                    <ModalFormModule isVisible={this.state.showModal}
                                    data= {this.state.selectedModule}
                                    onHide={()=>this.setState({showModal:false})}
                                    refresh={()=>this.refresh()}
                        />
                }
            </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    searchBar:{
        flexDirection:"row"
    },
    searchInput:{
        flex:8,
        margin:5,
        padding:5,
        borderBottomWidth:1,
        borderBottomColor:Colors.tintColor
    },
    itemButton:{
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    button:{
        flex:2,
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    textButton:{
        color:"white",
        fontSize:"12"
    },
    textTitleButton:{
        color:"white",
        fontWeight:"bold",
        fontSize:"18"
    }
});