import * as React from 'react';
import { createDrawerNavigator, Dra } from '@react-navigation/drawer';
import { DrawerActions } from '@react-navigation/native';
import { Image, View, Text, StyleSheet, TouchableOpacity, Button } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import Colors from '../constants/Colors';
import { connect } from 'react-redux';
import LoginPage from '../containers/login/LoginPage';
import DrawerContent from './DrawerContent';
import Icon from "react-native-vector-icons/MaterialIcons";
import {USERTYPE} from "../helpers/Users";
import { AdminPromotionsScreenStack,
         ProfilScreenStack,
         ManageModulesScreenStack,
         ManageProfessorsScreenStack,
         ManageSocietiesScreenStack,
         ManageDiplomesScreenStack,
         ManagePlanningScreenStack,
         AdminShareScreenStack,
         ShareStudentScreenStack,
         ShareProfessorScreenStack,
         HomeProfessorScreenStack,
         ProfessorReportsScreenStack,
         StudentReportsScreenStack,
         StudentAbsencesScreenStack,
         HomeTutorScreenStack,
         TutorReportsScreenStack,
         TutorAbsencesScreenStack,
         ManageStudentsScreenStack,
         HomeStudentScreenStack
} from './ScreenStacks';
import { createStackNavigator } from '@react-navigation/stack';
import HomePlanningScreen from '../screens/HomePlanningScreen';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();
const INITIAL_ROUTE_NAME = 'Home';

function DrawerNavigator(props) {
  return <View style={styles.container}>
            { !props.userInfo &&  
              <Stack.Navigator screenOptions={{
                headerShown: false
              }}>
                <Stack.Screen
                  name="Login"
                  component={LoginPage}
                />
                <Stack.Screen
                  name="Planning"
                  component={HomePlanningScreen}
                />
              </Stack.Navigator>
            }
            { props.userInfo  && (props.userInfo.type===USERTYPE.ADMIN || 
                                  props.userInfo.type===USERTYPE.SUPERADMIN || 
                                  props.userInfo.type===USERTYPE.PROFESSORADMIN)&&        
              <Drawer.Navigator drawerContent={propsD=><DrawerContent {...propsD}/>}>
                <Drawer.Screen name="Promotions" component={AdminPromotionsScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="dashboard" size={size}/>}}
                />
                <Drawer.Screen name="Planning" component={ManagePlanningScreenStack}
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="perm-contact-calendar" size={size}/>}} />
                <Drawer.Screen name="Diplomes" component={ManageDiplomesScreenStack}
                   options={{
                    drawerIcon: ({ focused, size }) => <Icon name="school" size={size}/>}}/>
                <Drawer.Screen name="Modules" component={ManageModulesScreenStack}
                   options={{
                    drawerIcon: ({ focused, size }) => <Icon name="view-module" size={size}/>}}/>
                <Drawer.Screen name="Professors" component={ManageProfessorsScreenStack}
                   options={{
                    drawerIcon: ({ focused, size }) => <Icon name="perm-identity" size={size}/>}}/>
                {props.userInfo.type===USERTYPE.PROFESSORADMIN&&
                  <Drawer.Screen name="Bulletins de notes" component={ProfessorReportsScreenStack}
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="dashboard" size={size}/>}} />
                }
                <Drawer.Screen name="Students" component={ManageStudentsScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="face" size={size}/>}}/>
                <Drawer.Screen name="Share" component={AdminShareScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="cloud" size={size}/>}}/>
                <Drawer.Screen name="Society" component={ManageSocietiesScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="location-city" size={size}/>}}/>
                <Drawer.Screen name="Profil" component={ProfilScreenStack}
                   options={{
                    drawerIcon: ({ focused, size }) => <Icon name="account-circle" size={size}/>}}/>
                
              </Drawer.Navigator>
            }
             { props.userInfo  && props.userInfo.type===USERTYPE.PROFESSOR &&
              <Drawer.Navigator drawerContent={propsD=><DrawerContent {...propsD}/>}>
                <Drawer.Screen name="Home" component={HomeProfessorScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="perm-contact-calendar" size={size}/>}} />
                <Drawer.Screen name="Share" component={ShareProfessorScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="cloud" size={size}/>}}/>
                <Drawer.Screen name="Bulletins de notes" component={ProfessorReportsScreenStack}
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="dashboard" size={size}/>}} />
                <Drawer.Screen name="Profil" component={ProfilScreenStack}
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="account-circle" size={size}/>}}/>
              </Drawer.Navigator>
            }
            { props.userInfo  && props.userInfo.type===USERTYPE.STUDENT &&
              <Drawer.Navigator drawerContent={propsD=><DrawerContent {...propsD}/>}>
                <Drawer.Screen name="Planning" component={HomeStudentScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="perm-contact-calendar" size={size}/>}} />
                <Drawer.Screen name="Share" component={ShareStudentScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="cloud" size={size}/>}}/>
                <Drawer.Screen name="Bulletins de notes" component={StudentReportsScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="dashboard" size={size}/>}}/>
                <Drawer.Screen name="Absences" component={StudentAbsencesScreenStack} 
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="face" size={size}/>}}/>
                <Drawer.Screen name="Profil" component={ProfilScreenStack}
                    options={{
                      drawerIcon: ({ focused, size }) => <Icon name="account-circle" size={size}/>}}/>
                
              </Drawer.Navigator>
            }
            { props.userInfo  && (props.userInfo.type===USERTYPE.TUTOR || 
                                  props.userInfo.type===USERTYPE.RESPONSABLEADMINISTRATIF)&&
              <Drawer.Navigator drawerContent={propsD=><DrawerContent {...propsD}/>}>
                
                <Drawer.Screen name="Planning" component={HomeTutorScreenStack}/>
                <Drawer.Screen name="Bulletins de notes" component={TutorReportsScreenStack} />
                <Drawer.Screen name="Absences" component={TutorAbsencesScreenStack} />
                <Drawer.Screen name="Profil" component={ProfilScreenStack}/>
              </Drawer.Navigator>
            }
          </View>;
}

const mapStateToProps = state => {
  return {
      userInfo: state.user.data
  }
};

export default connect(mapStateToProps)(DrawerNavigator);


function LogoTitle(props) {
  const navigation= props.navigation;
  return (
    <View style={{flexDirection:"row", alignItems:"center"}}>
      <TouchableOpacity onPress={()=>{
        navigation.dispatch(DrawerActions.toggleDrawer());
      }}>
      <Image
        style={{ height: 79, width:110 }}
        source={require('../assets/images/cfa_insta.png')}
      />
      </TouchableOpacity>
      <Text style={{fontWeight:"bold", 
                    color:Colors.tintColor, 
                    marginLeft:20, fontSize:24}}>
        {getHeaderTitle(props.route)}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});

function getHeaderTitle(route) {
    const routeName = route.state?.routes[route.state.index]?.name ?? INITIAL_ROUTE_NAME;
    switch (routeName) {
      case 'Home':
        return 'Schoolmanager';
      default:
        return 'Schoolmanager : '+routeName;
    }
}