import React, {Component} from 'react';
import {Text, View, StyleSheet, ScrollView, TouchableOpacity} from 'react-native';
import Layout from '../../../constants/Layout';
import { getRessourceChilds, createRessource, deleteRessourceChild } from '../../../services/server_api';
import Colors from '../../../constants/Colors';
import TabStudentModuleNotes from './TabStudentModuleNotes';
import Loader from '../../../components/Loader';
import { getModuleCode } from '../../../helpers/NoteCode';
import Icon from "react-native-vector-icons/MaterialIcons";

export default class TabReportNotes extends Component {
    
    constructor(props){
        super(props);
        this.state={notes:[], 
                    students:[], 
                    modules:[], 
                    selectedModule:null, 
                    codesNotes:[],
                    nbrDST:0, nbrExam:0,
                    loader:false}
    }

    componentDidMount(){
        this.refresh(this.props.report);
    }

    refresh(report, module=null){
        let professor= this.props.professor;
        this.setState({loader:true})
        getRessourceChilds('report', report.id, 'notes').then(async result=>{
            let modules=[]
            if(professor){
                modules= (await getRessourceChilds("professor", professor.id,"modules")).response;
            }else{
                modules= (await getRessourceChilds("promotion", this.props.report.promotion,"modules")).response;
            }
            let selectedModule= null;
            if(!module&& modules.length>0){
                selectedModule= modules[0];
            }else if(module){
                selectedModule= module;
            }
            this.setState({notes: result.response, 
                           modules:modules,
                           selectedModule: selectedModule});
        });
    }

    shouldComponentUpdate(nexProps,nextState){
        if(nexProps.report && nexProps.report!=this.props.report){
            this.refresh(nexProps.report);
        }else if(nextState.selectedModule&& nextState.selectedModule!==this.state.selectedModule){
            getRessourceChilds("promotion", this.props.report.promotion, "module/"+nextState.selectedModule.id+"/students").then(async result=>{
                let students = result.response;
                if(students.length===0){
                    students= (await getRessourceChilds("promotion", this.props.report.promotion,"students")).response;
                }
                let codesNotes=[];
                nextState.nbrDST=0;
                nextState.nbrExam=0;
                nextState.notes.map(item=>{
                    if(item.module===nextState.selectedModule.id && codesNotes.indexOf(item.code)<0){
                        codesNotes.push(item.code);
                        if(item.isexam){
                            nextState.nbrExam++;
                        }else{
                            nextState.nbrDST++;
                        }
                    }
                });
                this.setState({students:students, codesNotes:codesNotes.sort(), loader:false});
            });          
        }
        return true;
    }

    addNote(){
        if(confirm("Etes-vous sûre d'ajouter une note à ce module?")){
            this.state.students.map(async item=>{
                let data= this.generateData(0, item.id);
                await createRessource("note", data);
            });
            let selectedModule= this.state.selectedModule;
            this.setState({selectedModule:null});
            this.refresh(this.props.report, selectedModule);
        }
    }

    addNoteExamen(){
        if(confirm("Etes-vous sûre d'ajouter une note d'éxamen à ce module?")){
            this.state.students.map(async item=>{
                let data= this.generateData(1, item.id);
                await createRessource("note", data);
            });
            let selectedModule= this.state.selectedModule;
            this.setState({selectedModule:null});
            this.refresh(this.props.report, selectedModule);
        }
    }

    delete(code){
        let postData= {code:code, module: this.state.selectedModule.id, report: this.props.report.id};
        if(confirm("Etes-vous sûre de supprimer cette note?")){
            deleteRessourceChild("report", this.props.report.id, "note", {data:postData}).then(result=>{
                let selectedModule= this.state.selectedModule;
                this.setState({selectedModule:null});
                this.refresh(this.props.report, selectedModule);
            });
        }
    }

    generateData(exam, studentId){
        let code=getModuleCode(this.state.selectedModule.id);
        if(!code){
            code= this.state.selectedModule.code.substr(3,4);
        }
        if(exam){
            let temp= code+"EX"+(this.state.nbrExam+1);
            if(this.state.codesNotes.indexOf(temp)>-1){
                temp= code+"EX"+(this.state.nbrExam);
            }
            code=temp;
        }else{
            let temp= code+"CC"+(this.state.nbrDST+1);
            if(this.state.codesNotes.indexOf(temp)>-1){
                temp= code+"CC"+(this.state.nbrDST);
            }
            code=temp;
        }
        return {module:this.state.selectedModule.id,
                report: this.props.report.id,
                isexam: exam,
                value:-1,
                code: code,
                student: studentId
        };
    }

    render() {
        return (
            <View style={styles.container}>
                <TouchableOpacity style={styles.buttonAdd}
                    onPress={()=>this.addNote()}>
                    <Text style={{color:Colors.tintColor}}>Nouvelle note</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.buttonAddExam}
                    onPress={()=>this.addNoteExamen()}>
                    <Text style={{color:Colors.tintColor}}>Nouvelle note examen</Text>
                </TouchableOpacity>
                <View style={{flexDirection:"row",  flexWrap:'wrap', justifyContent:Layout.window.width>450?"center":"flex-end"}}>
                    <Text style={{width:200,padding:10}}></Text>
                    {this.state.modules.map((item,key)=>{
                        return  <TouchableOpacity key={key}
                                    onPress={()=>this.setState({selectedModule:item, loader:true})}
                                    style={styles.buttonModule}>
                                    <Text style={this.state.selectedModule&&item.id==this.state.selectedModule.id ?
                                        styles.buttonTextSelected: {}}>
                                        {item.name}
                                    </Text>
                                </TouchableOpacity>
                    })}
                </View>
                <View style={{flexDirection:"row", flexWrap:"wrap"}}>
                    <Text style={{width:200,padding:10}}></Text>
                    {this.state.codesNotes.map((item,key)=>{
                        return <View  key={key} style={{padding:10, width:150, overflow:"hidden", flexDirection:"row"}}>
                                    <Text>{item}</Text>
                                    <TouchableOpacity onPress={()=>this.delete(item)}>
                                        <Icon  name="delete" size={18} />
                                    </TouchableOpacity>
                                </View>
                    })}
                    <Text style={{flex:1, padding:10, marginLeft:5}}>Avis</Text>
                </View>
                {this.state.selectedModule&&
                    <ScrollView>
                        {this.state.students.map((item,key)=>{
                            if(item.status === 6 || item.status ==7){
                                return  <TabStudentModuleNotes
                                            key={key}
                                            student={item}
                                            report={this.props.report}
                                            refresh={()=>this.refresh(this.props.report, this.state.selectedModule)}
                                            module={this.state.selectedModule}
                                            codes={this.state.codesNotes}
                                            notes={this.state.notes}
                                        />
                            }})}
                    </ScrollView>
                }
                <Loader isVisible={this.state.loader}></Loader>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1
    },
    buttonAdd:{
        position:"absolute",
        padding:10,
        zIndex:100,
    },
    buttonAddExam:{
        position:"absolute",
        padding:10,
        marginTop:30,
        zIndex:100,
    },
    title:{
        backgroundColor:"white",
        width: Layout.window.width,
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        position: 'absolute',
        zIndex: 140,
        left:0,
        alignSelf: 'center',
        width: Layout.window.width,
    },
    buttonModule:{
        padding:5, 
        margin:5,
        borderColor:Colors.tintColor,
        borderWidth:1
    },
    buttonTextSelected:{
        color: Colors.tintColor
    },
    listEvents:{
        
    },
    moduleName:{
        paddingLeft:5
    },
    buttonClose:{
        position: "absolute",
        zIndex: 140,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    },
    buttonClassement:{
        position: "absolute",
        zIndex: 140,
        top:  0,
        left:10,
        margin:10,
        padding:10,
        backgroundColor: Colors.tintColor,
    }
});