import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../constants/Layout';
import {getRessourceChilds, getRessource} from "../../services/server_api";
import Colors from '../../constants/Colors';
import Loader from '../../components/Loader';
import FormContrat from '../contrat/FormContrat';

export default class ModalContrat extends Component {
    constructor(props){
        super(props);
        this.state={
            loader:false,
            societyData:null,
            representantData:null,
            tutorData: null,
            responsableAdministratifData: null,
            diplomeData: null,
            inscrData: props.data,
            studentData: props.selectedStudent,
        };
    }

    shouldComponentUpdate(nextProps, nextState){
        if(this.props.data!==nextProps.data && nextProps.data){
            nextState.inscrData  = nextProps.data;
        }
        return true;
    }
    
    componentDidMount(){
        this.setState({loader:true});
        this.getData();
    }

    async getData(){
        let society= null;
        let tutor= null;
        let representant= null;
        let diplome= null;
        let responsableAdministratif= null;
        if(this.props.data.society){
            society= ( await getRessource("society", this.props.data.society)).response;
            society = society ? society : null;
        }
        if(this.props.data.tutor){
            tutor= ( await getRessource("person", this.props.data.tutor)).response;
            tutor = tutor ? tutor : null;
        }
        if(this.props.data.representant){
            representant= ( await getRessource("person", this.props.data.representant)).response;
            representant= representant ? representant : null;
        }
        if(this.props.data.responsableAdministratif){
            responsableAdministratif= ( await getRessource("person", this.props.data.responsableAdministratif)).response;
            responsableAdministratif = responsableAdministratif ? responsableAdministratif : null;
        }
        if(this.props.data.promotion){
            diplome= ( await getRessourceChilds("promotion", this.props.data.promotion, "diplome")).response;
            diplome = diplome ? diplome : null;
        }
        this.setState({societyData:society, 
                        tutorData:tutor, 
                        diplomeData: diplome,
                        representantData: representant,
                        responsableAdministratifData: responsableAdministratif,
                        loader:false});
    }

    render() {
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible && this.state.inscrData && this.state.diplomeData  &&
                <View style={styles.container}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>Contrat {this.props.selectedStudent.lastname} {this.props.selectedStudent.firstname}</Text>
                        <Text style={styles.title}>Promotion  #{this.props.data.promotion} - {this.state.diplomeData.diplomaName}</Text>
                        <Loader isVisible={this.state.loader}></Loader>
                        <FormContrat data={this.state.inscrData}
                                     student={this.props.selectedStudent}
                                     societyData={this.state.societyData}
                                     tutorData={this.state.tutorData}
                                     representantData={this.state.representantData}
                                     responsableAdministratifData={this.state.responsableAdministratifData}
                                     diplomeData={this.state.diplomeData}
                                     refresh={this.props.refresh}
                                     onHide={this.props.onHide}
                        />
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>this.props.onHide()}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        top: "60",
        left: 0,
        zIndex: 200
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.5,
        zIndex: 210
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        zIndex: 220,
        alignSelf: 'center',
        justifyContent:"center",
        width:  Layout.window.width > 450 ? Layout.window.width/1.5 : Layout.window.width,
        padding:10,
        margin:10,
        backgroundColor:'white'
    },
    buttonClose:{
        zIndex: 190,
        position: "absolute",
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});