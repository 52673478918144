import React, {Component} from 'react';
import { Text, TouchableOpacity, View, StyleSheet  } from 'react-native';
import Colors from '../../constants/Colors';

export default function StudentsHeader(props){
        return (
        <View style={styles.studentsTabs}>
            {props.students.map((item,index)=>{
                return <TouchableOpacity key={index} style={styles.buttonStudent}
                            onPress={()=>props.onSelect(item)}>
                            <Text style={props.selected&&item.id==props.selected.id ?
                                    styles.buttonTextSelected: styles.buttonText}>
                                    {item.firstname} {item.lastname}
                            </Text>
                        </TouchableOpacity>
            })}
            
        </View>);
}

const styles = StyleSheet.create({
    studentsTabs:{
        flexDirection:"row"
    },
    buttonStudent:{
        flex:1,
        backgroundColor:Colors.tintColor,
        margin:5,
        padding:10,
    },
    buttonText:{
        color:"white",
        textAlign:"center"
    },
    buttonTextSelected:{
        color:"yellow",
        textAlign:"center"
    },
    detailReport:{
        flex:9,
        backgroundColor: '#bbb',
    }
});


