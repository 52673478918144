import * as React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, ScrollView } from 'react-native';
import { getRessources } from '../services/server_api';
import Colors from '../constants/Colors';
import { setSelectedStudent, setModalVisibleStudent} from "../store/actions/adminActions";
import { connect } from 'react-redux';
import ModalStudent from '../containers/promotion/Students/ModalStudent';
import FilterBar from '../components/FilterBar';

class StudentsScreen extends React.Component {

  constructor(props){
    super(props);
    this.state={students:[], filtered:[], value:""};
  }

  componentDidMount(){
    getRessources("student").then(result=>{
      this.setState({students:result.response, filtered:result.response });
    })
  }

  onValidFilter(result){
    this.setState({filtered: result});
  }

  render(){
    return <View style={styles.container}>
            <FilterBar data={this.state.students}
                       onResult={this.onValidFilter.bind(this)}
            />
            <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
              {this.state.filtered.map((item, index)=>{
                return <TouchableOpacity key={index} style={styles.itemStudent}
                          onPress={()=>{this.props.setSelectedStudent(item.id);this.props.setModalVisibleStudent(true)}}>
                          <Text style={styles.itemStudentTextH1}>{item.lastname}</Text>
                          <Text style={styles.itemStudentText}>{item.firstname}</Text>
                      </TouchableOpacity>
              })}
            </ScrollView>
            <ModalStudent />
          </View>
  }
}

const mapDispatchToProps = dispatch => {
  return {
      setSelectedStudent: data => {dispatch(setSelectedStudent(data));},
      setModalVisibleStudent: data => {dispatch(setModalVisibleStudent(data));},
  };
};

export default connect(null, mapDispatchToProps)(StudentsScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fafafa',
  },
  contentContainer: {
    paddingTop: 15,
    flexDirection:"row",
    flexWrap:"wrap",
    justifyContent:"center",
  },
  itemStudent:{
    overflow:"hidden",
    padding:10,
    margin:3,
    backgroundColor: Colors.tintColor
  },
  itemStudentTextH1:{
    fontWeight:'bold',
    color:"white"
  },
  itemStudentText:{
    color:"white"
  },
  searchBar:{
    flexDirection:"row"
  },
  searchInput:{
    marginLeft: 18,
    padding: 5,
    flex:1
  },
  searchButton:{

  }
});
