import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {setStudents, setSelectedStudent} from "../store/actions/tutorActions";
import { connect } from 'react-redux';
import StudentsHeader from '../containers/tutor/StudentsHeader';
import {getRessourceChilds} from "../services/server_api";
import TabStudentAbsences from '../containers/tutor/TabStudentAbsences';

class TutorAbsencesScreen extends Component{
  
  componentDidMount(){
    getRessourceChilds("tutor", this.props.tutor.id, "students").then(result=>{
      this.props.setStudents(result.response);
      if(result.response.length>0){
        this.props.setSelectedStudent(result.response[result.response.length-1]);
      }
    });
  }

  render(){
    return (
      <View style={styles.container}>
          <StudentsHeader 
            students={this.props.students}
            selected={this.props.selectedStudent}
            onSelect={(item)=>this.props.setSelectedStudent(item)}
          />
          { this.props.selectedStudent &&
            <TabStudentAbsences
                student={this.props.selectedStudent}
            />
          }
      </View>
    );
  }
}


const mapStateToProps = state => {
  return {
      tutor: state.user.data,
      students: state.tutor.students,
      selectedStudent: state.tutor.selectedStudent
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setStudents: data => {dispatch(setStudents(data));},
    setSelectedStudent: data => {dispatch(setSelectedStudent(data));}
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorAbsencesScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
