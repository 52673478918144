import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, Picker} from 'react-native';
import Layout from '../../../constants/Layout';
import {setModalVisibleInscriptionModule} from "../../../store/actions/adminActions";
import { connect } from 'react-redux';
import {getRessourceChilds, createRessource, deleteRessource} from "../../../services/server_api";
import Colors from '../../../constants/Colors';
import Loader from '../../../components/Loader';
import SelectableList from '../../../components/SelectableList';

class ModalInscriptionModule extends Component {
    constructor(props){
        super(props);
        this.state={
            firstload:true,
            modules: [],
            selectedModule:null,
            moduleStudents: [],
            loader:false,
            selectedToTransfert:[],
            selectedToDelete:[],
            deleteIds:[]
        };
    }

    shouldComponentUpdate(nextProps){
        if(!nextProps.selectedPromotion){
            return false;
        }
        
        if((nextProps.showModalInscriptionModule && this.state.firstload)
        || this.props.selectedPromotion!=nextProps.selectedPromotion ||
        this.props.listStudents!=nextProps.listStudents){
            this.refresh()
        }
        return true;
    }

    onSelectToTransfert(item){
        let selectedToTransfert= this.state.selectedToTransfert;
        if(selectedToTransfert.indexOf(item.id)==-1){
            selectedToTransfert.push(item.id);
        }else{
            let index= selectedToTransfert.indexOf(item.id);
            selectedToTransfert.splice(index, 1);
        }
        this.setState({selectedToTransfert:selectedToTransfert});
    }

    onSelectToDelete(item){
        let selectedToDelete= this.state.selectedToDelete;
        let deleteIds= this.state.deleteIds;
        if(selectedToDelete.indexOf(item.id)==-1){
            selectedToDelete.push(item.id);
            deleteIds.push(item.inscription);
        }else{
            let index= selectedToDelete.indexOf(item.id);
            selectedToDelete.splice(index, 1);
            deleteIds.splice(index, 1);
        }
        this.setState({selectedToDelete:selectedToDelete, deleteIds: deleteIds});
    }

    onSelectModule(selected){
        getRessourceChilds("promotion", this.props.selectedPromotion, "module/"+selected+"/students").then(result2=>{
            this.setState({loaded:false, 
                    selectedModule: selected, moduleStudents: result2.response,
                    firstload:false,
            });
        });
    }
    
    refresh(){
        if(!this.props.selectedPromotion) return;
        getRessourceChilds("promotion", this.props.selectedPromotion, "modules").then(result=>{    
            if(result.status==200 && result.response.length>0){
                let modules=result.response;
                let selected= modules[0].id;
                getRessourceChilds("promotion", this.props.selectedPromotion, "module/"+selected+"/students").then(result2=>{
                    this.setState({modules: modules, loaded:false, 
                                   selectedModule: selected, moduleStudents: result2.response,
                                   firstload:false,
                                });
                });
            }
        });
    }

    saveInscription(){
        if(confirm("Etes-vous sure de vouloir inscrire les étudiants sélectionnés dans ce module")){
            try{
                this.state.selectedToTransfert.map(async (item, index)=>{
                    await createRessource("inscription_modules",{
                        student:item,
                        module: this.state.selectedModule,
                        promotion: this.props.selectedPromotion
                    });
                });
                alert("Enregistrement éffecté.");
                this.setState({selectedToTransfert:[]});
                this.refresh();
            }catch(e){
                alert("Erreur lors de l'enregistrement.");
            }
        }
    }

    deleteInscription(){
        if(confirm("Etes-vous sure de vouloir désinscrire les étudiants sélectionnés dans ce module")){
            try{
                this.state.deleteIds.map(async (item, index)=>{
                    await deleteRessource("inscription_modules", item);
                });
                alert("Suppression éffecté.");
                this.setState({deleteIds:[], selectedToDelete:[]});
                this.refresh();
            }catch(e){
                alert("Erreur lors de l'enregistrement.");
            }
        }
    }

    render() {
        let moduleStudents= this.state.moduleStudents;
        let listStudents= this.props.listStudents.filter(item=>{
            for(var i in moduleStudents)
                if(moduleStudents[i].id==item.id)return false
            return true
        });
        return (
            <View style={this.props.showModalInscriptionModule ? styles.container : {display:"none"}}>
            {
                this.props.showModalInscriptionModule &&
                <View style={{flex:1}}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>Inscription aux modules promotion #{this.props.selectedPromotion}</Text>
                        <Loader isVisible={this.state.loader}></Loader>
                        <Picker selectedValue={this.state.selectedModule}
                            onValueChange={this.onSelectModule.bind(this)}>
                            {this.state.modules.map((item, index)=>{
                                return <Picker.Item key={index} label={item.name} value={item.id}/>
                            })}
                        </Picker>
                        <View style={{flexDirection:"row"}}>
                            <Text style={{flex:1,padding:5}}>Liste non inscrits: {listStudents.length}</Text>
                            <Text style={{flex:0.2,padding:5}}></Text>
                            <Text style={{flex:1,padding:5}}>Liste inscrits: {moduleStudents.length}</Text>
                        </View>
                        <View style={{flexDirection:"row", flex:1}}>
                            <SelectableList   
                                data={listStudents}
                                selectedItems={this.state.selectedToTransfert}
                                onSelect={this.onSelectToTransfert.bind(this)}
                            />
                            <View  style={{flex:0.2, justifyContent:"center", alignContent:"space-between"}}>
                                <TouchableHighlight style={styles.buttonInscriptions}
                                    onPress={()=>this.saveInscription()}>
                                    <Text style={{color:"white"}}>Inscrire les selectionnées.</Text>
                                </TouchableHighlight>
                                <TouchableHighlight style={styles.buttonSuppression}
                                    onPress={()=>this.deleteInscription()}>
                                    <Text style={{color:"white"}}>Désinscrire les selectionnées.</Text>
                                </TouchableHighlight>
                            </View>
                            <SelectableList
                                data={this.state.moduleStudents}
                                selectedItems={this.state.selectedToDelete}
                                onSelect={this.onSelectToDelete.bind(this)}
                            />
                        </View>
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>{this.props.setModalVisibleInscriptionModule(false)}}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalInscriptionModule: state.admin.showModalInscriptionModule,
        selectedPromotion: state.admin.selectedPromotion,
        listStudents: state.admin.listStudents,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setModalVisibleInscriptionModule: data => {dispatch(setModalVisibleInscriptionModule(data));},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalInscriptionModule);

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height,
        top: 0,
        left: 0,
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height,
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.5,
        zIndex: 110
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        zIndex: 120,
        flex:0.8,
        padding:20,
        backgroundColor:'white'
    },
    buttonClose:{
        zIndex: -1,
        position: "absolute",
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    },
    buttonInscriptions:{
        padding:5,
        margin:5,
        backgroundColor:"green"
    },
    buttonSuppression:{
        padding:5,
        margin:5,
        backgroundColor:"red"
    }
});