import React, {Component} from 'react';
import { View, TextInput, StyleSheet, TouchableOpacity, Text} from 'react-native';
import Icon from "react-native-vector-icons/MaterialIcons";
import Colors from '../../constants/Colors';
import { connect } from 'react-redux';
import { postPasswordConnection } from '../../services/server_api';
import { onConnect } from '../../store/actions/userActions';
const crypto = require("crypto");
  
class FormLogin extends Component {
    constructor(props){
        super(props);
        this.state= {login:"", password:""};
    }

    connect(){
        postPasswordConnection(this.state.login.trim(), this.encodedPassword()).then(result=>{
            if(result.status==200){
                this.props.onConnect(result.response.user, result.response.token);
            }else{
                alert("Erreur lors de l'authentification, verrifier votre identifiant et votre mot de passe.");
            }
        });
    }

    encodedPassword(){
        return crypto.createHash("sha512").update(this.state.password.trim()).digest("base64");
    }

    render(){
        return <View style={styles.container}>
            <View style={styles.fieldInput}>
                <Icon name="account-circle" size={30} style={styles.iconInput}/>
                <TextInput style={styles.textInput}
                           placeholder="Identifiant"
                           onChangeText={(txt)=>this.setState({login:txt})}/>
            </View>
            <View style={styles.fieldInput}>
                <Icon name="lock" size={30} style={styles.iconInput}/>
                <TextInput style={styles.textInput}
                           secureTextEntry={true}
                           placeholder="Mot de passe"
                           onChangeText={(txt)=>this.setState({password:txt})}/>
            </View>
            <TouchableOpacity style={styles.buttonConnect}
                            onPress={()=>this.connect()}>
                <Text style={styles.textConnect}>Valider</Text>
            </TouchableOpacity>
        </View>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onConnect: (user, token) => {dispatch(onConnect(user, token));}
    };
};

export default connect(null, mapDispatchToProps)(FormLogin);

const styles = StyleSheet.create({
    container: {
      flex: 1,
      borderTopWidth:1,
      borderTopColor:"black"
    },
    fieldInput:{
        flexDirection:"row",
        flex:1,
        paddingLeft:30,
        paddingRight:30
    },
    iconInput:{
        flex:1,
        color: Colors.tintColor,
        alignSelf:"center"
    },
    textInput:{
        flex:7,
        margin:10,
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
    },
    buttonConnect:{
        backgroundColor: Colors.tintColor,
        margin:10,
        marginLeft:30,
        marginRight:30,
    },
    textConnect:{
        padding:10,
        color:"white",
        textAlign: "center",
    }
  });
  