import React, {Component} from 'react';
import { View, StyleSheet, Picker } from 'react-native';
import { getRessources } from '../services/server_api';
import TabShareSpace from '../containers/promotion/Share/TabShareSpace';

export default class AdminShareScreen extends Component{

    constructor(props){
        super(props);
        this.state={promotions:[], selectedPromotion:null}
    }

    componentDidMount(){
        getRessources("promotion").then(result=>{
            this.setState({promotions:result.response});
        });
    }


    render(){
        return <View style={styles.container}>
                <Picker selectedValue={this.state.selectedPromotion}
                    style={{margin:10, padding:3}}
                    onValueChange={(value)=>{this.setState({selectedPromotion:value})}}>
                    <Picker.Item label={"choisissez une promotion"} value={null}/>
                        {this.state.promotions.map((item)=>{
                            return <Picker.Item key={item.id} label={"#"+item.id+" - "+item.name} value={item.id}/>
                        })}
                </Picker>
                <TabShareSpace
                    promotion={this.state.selectedPromotion}
                />
               </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    content:{
        
    },
    title:{
       
    }
});