import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../../constants/Colors';
import { getRessources } from '../../services/server_api';

export default class SelectStudent extends Component{

    constructor(props){
        super(props);
        this.state={students:[], filtered:[], value:""}
    }

    componentDidMount(){
        getRessources('student').then(result=>{
            let students= result.response.filter(item=>{
                return !this.isAlreadyInPromotion(item);
            })
            this.setState({students: students, filtered: students});
        });
    }

    isAlreadyInPromotion(student){
        for(var i in this.props.promotionStudents){
            if(this.props.promotionStudents[i].id==student.id){
                return true
            }
        }
        return false;
    }

    filtered(value){
        let result= this.state.students.filter(item=>{
            let name= (item.lastname+" "+item.firstname).toUpperCase();
            return name.indexOf(value.toUpperCase())!==-1;
        });
        this.setState({filtered: result, value: value});
    }

    render(){
        return <View style={styles.container}>
                    <View style={styles.searchBar}>
                        <TextInput style={styles.searchInput}
                            value={this.state.value}
                            onChangeText={this.filtered.bind(this)}
                        />
                    </View>
                    <ScrollView style={{height:400}} contentContainerStyle={{flexDirection:'row', flexWrap:"wrap", alignContent:"stretch"}}>
                        {this.state.filtered.map((item, index)=>{
                            return <TouchableOpacity key={index} style={styles.itemButton}
                                        onPress={()=>this.props.onSelect(item)}>
                                        <Text style={styles.textButton}>{item.lastname} {item.firstname}</Text>
                                </TouchableOpacity>
                        })}
                    </ScrollView>
            </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    searchBar:{
        flexDirection:"row"
    },
    searchInput:{
        flex:8,
        margin:5,
        padding:5,
        borderBottomWidth:1,
        borderBottomColor:Colors.tintColor
    },
    itemButton:{
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    button:{
        flex:1,
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    textButton:{
        color:"white",
    }
});