import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../../constants/Colors';
import { getRessources } from '../../services/server_api';

export default class SelectSociety extends Component{

    constructor(props){
        super(props);
        this.state={societies:[], filtered:[], value:""}
    }

    componentDidMount(){
        getRessources('society').then(result=>{
            let societies= result.response.sort((a,b)=> a.raison > b.raison);
            this.setState({societies: societies, filtered: societies});
        });
    }

    filtered(value){
        let result= this.state.societies.filter(item=>{
            let name= item.raison.toUpperCase();
            return name.indexOf(value.toUpperCase())!==-1;
        });
        this.setState({filtered: result, value: value});
    }

    render(){
        return <View style={styles.container}>
                <View style={styles.searchBar}>
                    <TextInput style={styles.searchInput}
                        value={this.state.value}
                        onChangeText={this.filtered.bind(this)}
                    />
                    <TouchableOpacity style={styles.button}
                        onPress={()=>this.props.onHide()}>
                        <Text style={styles.textButton}>Annuler</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView style={{height:150}} contentContainerStyle={{flexDirection:'row', flexWrap:"wrap", alignContent:"stretch"}}>
                    {this.state.filtered.map((item, index)=>{
                        return <TouchableOpacity key={index} style={styles.itemButton}
                                                onPress={()=>this.props.onSelect(item)}>
                                <Text style={styles.textButton}>{item.raison}</Text>
                            </TouchableOpacity>
                    })}
                </ScrollView>
            </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    searchBar:{
        flexDirection:"row"
    },
    searchInput:{
        flex:8,
        margin:5,
        padding:5,
        borderBottomWidth:1,
        borderBottomColor:Colors.tintColor
    },
    itemButton:{
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    button:{
        flex:1,
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    textButton:{
        color:"white",
    }
});