import React, {Component} from 'react';
import { getRessourceChilds } from '../../services/server_api';
import { Text, TouchableOpacity, View, StyleSheet  } from 'react-native';
import TabReportStudent from "../../containers/student/TabReportStudent";
import Colors from '../../constants/Colors';

export default class TabStudentReports extends Component{
    
    constructor(props){
        super(props);
        this.state= {selectedReport:null, reports:[]}
    }

    componentDidMount(){
        this.refresh(this.props.student.id);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.student && nextProps.student.id!=this.props.student.id){
            this.refresh(nextProps.student.id);
        }
        return true;
    }

    refresh(studentId){
        getRessourceChilds('student', studentId, 'reports').then(result=>{
            let reports= result.response;
            this.setState({reports:reports});
            if(reports.length>0){
                this.setState({selectedReport: reports[reports.length-1]})
            }
        });
    }
    
    render(){
        return (
          <View style={styles.container}>
              <View style={styles.reportsTabs}>
                {this.state.reports.map((item,index)=>{
                    return <TouchableOpacity key={index} style={styles.buttonReport}
                                onPress={()=>this.setState({selectedReport:item})}>
                                <Text style={this.state.selectedReport&&item.id==this.state.selectedReport.id ?
                                        styles.buttonTextSelected: styles.buttonText}>
                                        {item.name}
                                </Text>
                            </TouchableOpacity>
                })}
              </View>
              <View style={styles.detailReport}>
                {this.state.selectedReport&&
                    <TabReportStudent
                        report={this.state.selectedReport}
                        student={this.props.student}
                    />
                }
              </View>
        </View>);
    } 
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    reportsTabs:{
        flexDirection:"row"
    },
    buttonReport:{
        flex:1,
        backgroundColor:Colors.tintColor,
        margin:5,
        padding:10,
    },
    buttonText:{
        color:"white",
        textAlign:"center"
    },
    buttonTextSelected:{
        color:"yellow",
        textAlign:"center"
    },
    detailReport:{
        flex:9,
        backgroundColor: '#bbb',
    }
});