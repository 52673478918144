export function getCoef(reportType, moduleId){
    switch(reportType){
        case "SIO":
            switch(moduleId){
                //CULTURG
                case 1:
                    return 2
                //ANGLAIS
                case 2:
                    return 1
                //ECO DROIT
                case 3:
                    return 3
                //MATH
                case 191:
                case 4:
                    return 2
                //ALGO
                case 5:
                    return 1
                //E5 PFSI
                case 190:
                case 189:
                case 6:
                    return 4
                //E4 PPE
                case 7:
                    return 4
                //E6 PP
                case 8:
                case 192:
                    return 4
            }
        case "CG":
            switch(moduleId){
                //CULTURG
                case 1:
                    return 4
                //ANGLAIS
                case 2:
                    return 3
                //MATH
                case 4:
                    return 3
                //CULTURE MANA
                case 110: 
                    return 6
                //COMPTA E41
                case 111:
                    return 9
                //E42-E5
                case 120:
                    return 9
                //E6 PP
                case 121:
                    return 5
            }
    }
    return 1;
}