import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet} from "react-native";
import StudentModuleNotes from './StudentModuleNotes';
import {noteValue, parseStudentsNotes, getReportType, studentAddAverages, parseDataFoFile} from '../../../helpers/Reports';
import Layout from '../../../constants/Layout';
import { getRessourceChilds, postToDownLoadFile } from '../../../services/server_api';
import Loader from '../../../components/Loader';
import { formatDate } from '../../../helpers/Formats';

export default class StudentReport extends Component{

    constructor(props){
        super(props);
        this.state={data:null, loader:false}
    }

    componentDidMount(){
        this.refresh();
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.report && this.props.report!=nextProps.report){
            this.refresh();
        }
        return true;
    }

    refresh(){
        this.setState({loader:true});
        getRessourceChilds("student", this.props.student.id, `report/${this.props.report.id}/notes`).then(async result=>{
            let studentModules= (await getRessourceChilds("student",  this.props.student.id, "modules")).response;
            let dataNote= result.response.notes.filter(item=>{
                if (studentModules.length==0)return true;
                else return studentModules.indexOf(item.module) >=0;
            });
            let temp= parseStudentsNotes([this.props.student], dataNote);
            let reportType= getReportType(this.props.report.name);
            let parsedReportNotes= studentAddAverages(temp[0], reportType);
            parsedReportNotes.student= this.props.student.id;
            this.setState({data:parsedReportNotes, loader:false});
        });
    }
    
    render() {
        return <View style={styles.container}>
                <View style={styles.studentName}>
                    <Text style={styles.text}>{this.props.student.lastname}</Text>
                    <Text style={styles.text}>{this.props.student.firstname}</Text>
                    {this.state.data&&
                        <Text>Moy.Gen: {noteValue(parseFloat(this.state.data.globalAverage))}</Text>
                    }
                    {this.state.data&& this.state.data.examAverage&&
                        <Text>Moy.Exam: {noteValue(parseFloat(this.state.data.examAverage))}</Text>
                    }
                </View>
                {this.state.data ?
                <View style={styles.notes}>
                    {Object.entries(this.state.data.notes).map((item,index)=>{
                        let data= item[1];
                        return <StudentModuleNotes key={index} data={data} refresh={()=>this.refresh()} />
                    })}
                </View> : <View style={styles.notes}></View>
                }
                <View style={styles.buttons}>
                    <TouchableOpacity style={styles.button}
                        onPress={this.props.onPressDetail}>
                        <Text>Details avis</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button}
                        onPress={()=>this.props.onPressPrint()}>
                        <Text>Imprimer</Text>
                    </TouchableOpacity>
                </View>
                <Loader isVisible={this.state.loader}></Loader>
            </View>;
    }
}


const styles = StyleSheet.create({
    container:{
      margin: 5,
      flexDirection: Layout.window.width>450 ? "row" : "column",
      backgroundColor:'grey',
      flex:1
    },
    studentName:{
        backgroundColor:'white',
        padding:"10px",
        alignContent:"center",
        justifyContent:"center",
        flex:1,
    },
    text:{
        fontWeight:"bold"
    },
    notes:{
        flexDirection:'row',
        flexWrap:'wrap',
        flex:10
    },
    buttons:{
        flex:1,
    },
    button:{
        flex:1,
        backgroundColor:'white',
        margin:2,
        justifyContent:'center',
        alignItems:'center'
    }
});