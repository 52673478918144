
let INITIAL_STATE = {
    selectedStudent: null,
    students: [],
};

let tutorReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_TUTORSELECTEDSTUDENT":
            return {...state, selectedStudent: action.value};
        case "SET_TUTORSTUDENTS":
            return {...state, students: action.value};
        default:
            return state;
    }
};

export default tutorReducer;