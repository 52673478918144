import * as React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import PlanningEvent from "../../components/PlanningEvent";
import Colors from '../../constants/Colors';

export default function BuildRoom(props){
    let room= props.room;
    if(!room)return <View></View>
    let events= props.events.filter(item=>item.classroom==room.id);
    let promotions=[];
    events.map(item=>{
        if(promotions.indexOf(item.promotion)<0){
            promotions.push(item.promotion);
        }
    })
    return <View style={styles.content}>
            {promotions.length>0 ?
                promotions.map((item,key)=>{
                    return <View key={key} style={styles.item}>
                                <Text style={{color:"white", fontWeight:"bold"}}>#{item}</Text>
                                <Text>salle: {room.id}</Text>
                            </View>
                }):
                <View style={styles.itemFree}>
                    <Text style={{fontWeight:"bold"}}>{room.capacity} places</Text>
                    <Text>salle: {room.id}</Text>
                </View>
            }
            </View>;
}

const styles = StyleSheet.create({
    content:{
        flex:1,
    },
    item:{
        flex:1, 
        backgroundColor:"orange",
        justifyContent:"center",
        alignItems:"center",
        borderWidth:1,
        borderColor:"white"
    },
    itemFree:{
        flex:1,
        alignItems:"center",
        justifyContent:"center",
    }
});