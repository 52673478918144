import * as React from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import ListPromotions from '../containers/promotion/ListPromotions';
import PromotionFilter from '../components/PromotionFilter';
import PromotionDetails from '../components/PromotionDetails';
import ModalPlanning from '../containers/promotion/Plannings/ModalPlanning';
import ModalListStudents from '../containers/promotion/Students/ModalListStudents';
import ModalStudent from '../containers/promotion/Students/ModalStudent';
import ModalReports from '../containers/promotion/Reports/ModalReports';
import ModalInscription from '../containers/promotion/Students/ModalInscription';
import ModalInscriptionModule from '../containers/promotion/Students/ModalInscriptionModule';
import ModalFormRename from '../containers/promotion/ModalFormRename';
import ModalListCandidates from '../containers/promotion/Students/ModalListCandidates';

export default function HomeScreen() {
  return (
    <View style={styles.container}>
      <View style={{flex:1}}>
        <PromotionFilter />
        <ScrollView style={{flex:1}}>
          <ListPromotions></ListPromotions>
        </ScrollView>
      </View>
      <PromotionDetails/>
      
      <ModalPlanning />
      <ModalListStudents />
      <ModalListCandidates/>
      <ModalStudent />
      <ModalInscription />
      <ModalInscriptionModule />
      <ModalReports />
      <ModalFormRename />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection:"row",
    flex:1,
    backgroundColor: '#fff',
  },
});
