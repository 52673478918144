import { formatStringDate } from './Formats';


const MarkedColors= ["#a2b9bc", "#b2ad7f", "#b2ad7f", 
                     "#d6cbd3", "#d5e1df", "#eca1a6", 
                     "#e3eaa7", "#ada397", "#dac292",
                     "#92a8d1", "#b1cbbb", "#b2b2b2"];

export function planningToMarkedDates(data){
    let markedDates={};
    let modulesColors={}
    let itemColor=-1;
    let currentModule=null;
    data.map((item, index) => {
        if(currentModule!=item.module){
            itemColor++;
            currentModule=item.module;
            
        }
        if(itemColor == MarkedColors.length){
            itemColor=0;
        }
        modulesColors[item.module]=MarkedColors[itemColor];
        markedDates[formatStringDate(item.module_date)] = {color:MarkedColors[itemColor], startingDay: true, endingDay: true}
    });
    return {markedDates: markedDates, modulesColors: modulesColors};
}

export function filterEvents(events, date){
    return events.filter(item=>{
        return formatStringDate(item.module_date) === date;
    });
}


export function getStudentAbsence(studentId, dataAbsences){
    for(var i in dataAbsences){
        if(dataAbsences[i].student==studentId){
            return dataAbsences[i];
        }
    }
    return null;
}

export function parseDataForm(state){
    let data = Object.assign({}, state);
    if(data.time_start.length<7){
        data.time_start= data.time_start+":00.00"
    }
    if(data.time_end.length<7){
        data.time_end= data.time_end+":00.00"
    }
    data.module_date= formatStringDate(data.module_date);
    delete data.recursiveChange;
    delete data.frequences;
    delete data.frequenceSelected;
    delete data.selectedDays;
    delete data.recursiveEnd;
    delete data.id;
    return data;
}