import React, {Component} from 'react';
import {Text, View, StyleSheet, ScrollView, TouchableOpacity} from 'react-native';
import Layout from '../../../constants/Layout';
import {CalendarList, LocaleConfig} from 'react-native-calendars';
import {getRessourceChilds} from "../../../services/server_api";
import {planningToMarkedDates, filterEvents} from "../../../helpers/Planning";
import ModalPlanningDay from "./ModalPlanningDay";
import ModalModule from '../../module/ModalModule';

LocaleConfig.locales['fr'] = {
  monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
  monthNamesShort: ['Janv.','Févr.','Mars','Avril','Mai','Juin','Juil.','Août','Sept.','Oct.','Nov.','Déc.'],
  dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
  dayNamesShort: ['Dim.','Lun.','Mar.','Mer.','Jeu.','Ven.','Sam.'],
  today: 'Aujourd\'hui'
};
LocaleConfig.defaultLocale = 'fr';

export default class TabPlanningProfessor extends Component{
  
  constructor(props){
      super(props);
      this.state={
          events:[],
          markedDates:{}, 
          modules:[], 
          modulesColors:{},
          showModalDay: false,
          selectedDate: null,
          dayEvents: [],
      };
  }
  
  componentDidMount(){
    this.refresh(this.props.professor);
  }

  shouldComponentUpdate(nextProps, nextState){
      if(nextProps.professor && this.props.professor!==nextProps.professor){
          this.refresh(nextProps.professor);
      }
      return true;
  }

  refresh(professor){
    if(!professor){
      this.setState({
          events:[],
          markedDates:{}, 
          modules:[], 
          modulesColors:{},
          showModalDay: false,
          selectedDate: null,
          dayEvents: [],
          showModule: false,
          selectedModule: null,
      });
      return;
    }

    getRessourceChilds('professor', professor.id, 'plannings').then(result=>{
        let events= result.response;
        let parse=  planningToMarkedDates(result.response);
        let markedDates = parse.markedDates;
        let modulesColors= parse.modulesColors;
        getRessourceChilds('professor', professor.id, 'modules').then(result2=>{
            let modules= result2.response;
            modules = modules.filter(item=>{
                for(var moduleId in modulesColors){
                    if(item.id==moduleId)return true;
                }
                return false;
            });
            this.setState({events:events,
                           markedDates: markedDates, 
                           modulesColors: modulesColors, 
                           modules: modules,
                        })
            if(this.state.selectedDate){
                let dayEvents= filterEvents(events, this.state.selectedDate);
                this.setState({
                    selectedDate: this.state.selectedDate,
                    dayEvents: dayEvents
                });
            }
        });
    });
  } 

  selectDate(date){
      let dayEvents= filterEvents(this.state.events, date.dateString);
      this.setState({
          selectedDate: date.dateString,
          showModalDay: true,
          dayEvents: dayEvents
      });
  }

  hideModalDay(){
      this.setState({showModalDay: false});
  }

  render(){
    return (
      <View style={styles.container}>
          <CalendarList
              // Enable horizontal scrolling, default = false
              horizontal={true}
              calendarWidth={320}
              // Enable paging on horizontal, default = false
              pagingEnabled={true}
              // Set custom calendarWidth.
              onDayPress={this.selectDate.bind(this)}
              markedDates={this.state.markedDates}
              // Date marking style [simple/period/multi-dot/custom]. Default = 'simple'
              markingType={'period'}
          />
          <ModalPlanningDay isVisible={this.state.showModalDay} 
              promotion={this.props.promotion}
              selectedDate={this.state.selectedDate}
              editable={this.props.editable}
              events={this.state.dayEvents}
              modules={this.state.modules}
              refresh={()=>this.refresh(this.props.professor)}
              onHide={this.hideModalDay.bind(this)}
              onSelectModule={(module)=>this.setState({selectedModule:module, showModule:true})}
          />
          <ScrollView style={{backgroundColor:'white', flex: 0.7}}
              contentContainerStyle={{flexDirection:'row',
                  flexWrap:'wrap',
                  alignContent:'stretch',
                  justifyContent:'center',
                  alignItems:'center',
              }}>
              { this.state.modules.map((item, index) => (
                  <TouchableOpacity key={index} style={styles.itemModule}
                      onPress={()=>this.setState({selectedModule:item.id, showModule:true})}
                    >
                      <View style={{backgroundColor: this.state.modulesColors[item.id], width:30, height:30}}></View>
                      <Text style={styles.moduleName}>{item.name}</Text>
                  </TouchableOpacity>
              ))}
          </ScrollView>
          {this.state.showModule&&
            <ModalModule isVisible={this.state.showModule}
                      module= {this.state.selectedModule}
                      onHide={()=>this.setState({showModule:false})}
              />
            }
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  title:{
    width: Layout.window.width,
    backgroundColor:"white",
    fontSize: 24,
    textAlign:"center"
  },
  listModules:{
    flexDirection: "row",
    backgroundColor: 'white',
    flexWrap:'wrap',
    alignContent:'stretch',
    justifyContent:'center',
    alignItems:'center',
  },
  itemModule:{
      flexDirection: "row",
      width: 200,
      margin:3
  },
  moduleName:{
      paddingLeft:5
  },
});
