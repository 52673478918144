import React, {Component} from 'react';
import { View, TextInput, Text, StyleSheet, CheckBox, TouchableHighlight} from "react-native";
import { getStudentAbsence } from '../helpers/Planning';
import Colors from '../constants/Colors';
import { updateRessource, deleteRessource, createRessource } from '../services/server_api';

export default class AbsenceInput extends Component{

    constructor(props){
        super(props);
        let savedData= getStudentAbsence(this.props.student.id, this.props.dataAbsences);
        if(savedData){
            this.state={checked:true, 
                        description: savedData.description, 
                        id: savedData.id,
                        isjutified: savedData.isjutified
                    }
        }else{
            this.state={checked:false, description: "", id: null, isjutified:0}
        }
    }

    onAbsenceChange(){
        this.setState({checked:!this.state.checked});
    }

    onJustificationChange(value){
        this.setState({checked:value!="", description:value, isjutified:value!=""? 1:0});
    }

    save(){
        let postData= Object.assign({
            planning: this.props.event.id,
            student: this.props.student.id
        }, this.state);
        delete postData.checked;
        delete postData.id;
        if(this.state.id){
            //update
            if(this.state.checked){
                updateRessource("absence", this.state.id, postData).then(result=>{
                    alert("Mise à jours absence éffectué.");
                });
            //delete
            }else{
                deleteRessource("absence", this.state.id).then(result=>{
                    alert("Suppression absence éffectué.");
                    this.setState({id:null});
                });
            }
        //insert
        }else  if(this.state.checked){
            createRessource("absence", postData).then(result=>{
                this.setState({id:result.response.insertId});
                alert("Enregistrement éffectué.")
            });
        }
    }

    render() {
        return (
            <View style={styles.container}>
                <Text style={styles.text}>{this.props.student.lastname}</Text>
                <Text style={styles.text}>{this.props.student.firstname}</Text>
                <CheckBox value={this.state.checked} onChange={this.onAbsenceChange.bind(this)}/>
                <TextInput style={styles.textInput} 
                    value={this.state.description}
                    onChangeText={this.onJustificationChange.bind(this)} 
                    placeholder="justification..."/>
                <TouchableHighlight style={styles.button} onPress={this.save.bind(this)}>
                    <Text>Save</Text>
                </TouchableHighlight>
            </View>
        );
    }
}


const styles = StyleSheet.create({
    container:{
        backgroundColor:'white',
        flexDirection:'row',
        marginTop:10,
        padding:5,
        alignItems:"center"
    },
    text:{
        width:100,
        marginRight: 5,
    },
    textInput:{
        backgroundColor: Colors.tintColor,
        color: 'white',
        padding: 5,
        margin: 5,
        flex: 1,
    },
    button:{
        padding:5
    }
});