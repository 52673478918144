import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet} from 'react-native';
import Layout from '../../../constants/Layout';
import PlanningEvent from '../../../components/PlanningEvent';
import { getRessourceChilds, deleteRessourceChild, deleteRessource } from '../../../services/server_api';
import ModalPromotionAbsences from './ModalPromotionAbsences';
import FormPlanning from '../../../components/FormPlanning';
import { formatToStringFR } from '../../../helpers/Formats';
import Colors from '../../../constants/Colors';

export default class ModalPlanningDay extends Component {
    
    constructor(props){
        super(props);
        this.state= {
            showModalAbsences: false,
            dataAbsences: [],
            selectedEvent: null,
            editEvent:false,
            listStudents: props.listStudents
        };
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.listStudents!=this.props.listStudents){
            nextState.listStudents= nextProps.listStudents;
        }   
        return true;
    }



    getAbsences(selectedEvent){
        getRessourceChilds('planning', selectedEvent.id, 'absences').then(async result=>{
            if(result.status==200){
                let students= (await getRessourceChilds("promotion", selectedEvent.promotion, "module/"+selectedEvent.module+"/students")).response;
                if(students.length>0){
                    this.setState({showModalAbsences: true, 
                                dataAbsences: result.response,
                                selectedEvent: selectedEvent,
                                editEvent: false,
                                listStudents: students});
                }else{
                    this.setState({showModalAbsences: true, 
                               dataAbsences: result.response,
                               editEvent: false,
                               selectedEvent: selectedEvent});
                }
            }
        });
    }

    hideModale(){
        this.setState({showModalAbsences: false, dataAbsences: []});
    }

    editEvent(item){
        this.setState({editEvent:true, selectedEvent:item});
    }

    deleteEvent(item){
        if(confirm("Etes-vous sur de vouloir supprimer la plannification de ce module?")){
            deleteRessource("planning", item.id).then(result=>{
                this.props.refresh();
            });
        }
    }

    render() {
        let events= this.props.events;
        events.map((item,key)=>{
            if(item.time_start.length<8){
                item.time_start= "0"+item.time_start;
            }
        })
        let selectedDate= formatToStringFR(this.props.selectedDate);
        events= events.sort((a,b)=>{return parseInt(a.time_start.slice(0,2))-parseInt(b.time_start.slice(0,2))});
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible &&
                <View style={{flex:1, flexDirection:"row", justifyContent:"center"}}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        {this.props.promotion&&
                            <Text style={styles.title}>Planning promotion #{this.props.promotion}</Text>
                        }
                        <Text style={styles.title}>{selectedDate}</Text>
                        <View style={styles.listEvents}>
                            { events.map((item, index) => (
                               <PlanningEvent key={index} 
                                              data={item}
                                              displayPromo={!this.props.promotion}
                                              onSelectModule={(obj)=>this.props.onSelectModule(obj)}
                                              editable={this.props.editable}
                                              editEvent={this.editEvent.bind(this)}
                                              deleteEvent={this.deleteEvent.bind(this)}
                                              showModalAbsences={this.getAbsences.bind(this)}
                                              />
                            ))}
                        </View>
                        {this.props.editable&& this.state.editEvent &&
                            <View style={{position:"absolute", top:0}}>
                                <FormPlanning
                                    isVisible= {this.state.selectedEvent? true: false}
                                    selectedEvent= {this.state.selectedEvent}
                                    promotion={this.props.promotion}
                                    modules={this.props.modules}
                                    refresh={this.props.refresh}
                                    closeForm={()=>{
                                        this.setState({selectedEvent:null});
                                    }}
                                />
                            </View>
                        }
                        {this.props.editable&& this.state.showModalAbsences &&
                            <ModalPromotionAbsences isVisible={this.state.showModalAbsences}
                                selectedEvent= {this.state.selectedEvent}
                                students={this.state.listStudents}
                                dataAbsences={this.state.dataAbsences}
                                promotion={this.props.promotion}
                                selectedDate={this.props.selectedDate}
                                onHide={this.hideModale.bind(this)}
                            />
                         }
                        {!this.state.selectedEvent&&
                            <TouchableHighlight 
                                style={styles.buttonClose}
                                onPress={()=>{this.setState({editEvent:false, selectedEvent:null});
                                        this.props.onHide()}}>
                                <Text>X</Text>
                            </TouchableHighlight>
                        }
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    title:{
        backgroundColor:Colors.tintColor,
        fontSize: 24,
        color:"white",
        padding:10,
        textAlign:"center"
    },
    content:{
        margin: 10,
        marginTop:100,
        zIndex: 145,
        flex: Layout.window.width < 800 ? 0.9: 0.3,
    },
    listEvents:{
    },
    moduleName:{
        paddingLeft:5
    },
    buttonClose:{
        position: "absolute",
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});