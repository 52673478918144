  import React, {Component} from 'react';
import {Text, TouchableOpacity,TouchableHighlight, View, StyleSheet, TextInput, Picker,Image } from 'react-native';
import Layout from '../../../constants/Layout';
import {setModalVisibleStudent, setSelectedPromotion, setSelectedStudent, setPromotions} from "../../../store/actions/adminActions";
import { connect } from 'react-redux';
import Colors from '../../../constants/Colors';
import FormStudent from '../../../components/FormStudent';
import ModalReports from '../../student/ModalReports';
import ModalAbsences from '../../student/ModalAbsences';
import { getRessourceChilds, getRessources, postToDownLoadFile, getRessource } from '../../../services/server_api';
import ModalContrat from '../../student/ModalContrat';
import ModalFiles from '../../student/ModalFiles';
import ChangePromotion from '../../student/ChangePromotion';
import { formatToStringFR } from '../../../helpers/Formats';

class ModalStudent extends Component {
    constructor(props){
        super(props);
        this.state={showReports:false,
                    showAbsences:false,
                    showFiles:false,
                    changePromotion: false,
                    contrats:[],
                    currentPromotion: null,
                    currentContrat: null,
                    selectedContrat: null
                };
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.selectedStudent && nextProps.selectedStudent!=this.props.selectedStudent){
            getRessourceChilds("student", nextProps.selectedStudent.id, "contrats").then(result=>{
                this.setState({contrats: result.response,
                               currentPromotion:  result.response.length>0?
                                    result.response[result.response.length-1].promotion: null,
                               currentContrat:  result.response.length>0?
                                    result.response[result.response.length-1]: null,
                });
            });
        }
        return true;
    }

    refreshContrat(contrat){
        this.setState({selectedContrat:null});
        this.refreshPromotions();
        getRessourceChilds("student", this.props.selectedStudent.id, "contrats").then(result=>{
            this.setState({contrats: result.response,
                            currentPromotion:  result.response.length>0?
                                result.response[result.response.length-1].promotion: null,
                            currentContrat:  result.response.length>0?
                                     result.response[result.response.length-1]: null,
                            selectedContrat:contrat});
        });
    }

    refreshPromotions(){
        getRessources("promotion").then(result=>{
            if(result.status==200){
                this.props.setPromotions(result.response);
                if(this.props.selectedPromotion){
                    this.props.setSelectedPromotion(this.props.selectedPromotion, this.props.selectedPromotionData);
                }
            }
        });
    }

    async printCertificat(){

        let societyId= this.state.contrats[this.state.contrats.length-1].society;
        let society= societyId ?
                    (await getRessource("society", societyId)).response :"";

        let postData={
            student_gender:this.props.selectedStudent.gender==1 ? "Monsieur": "Madame",
            student_firstname:this.props.selectedStudent.firstname,
            student_lastname:this.props.selectedStudent.lastname,
            student_birthdate:formatToStringFR(this.props.selectedStudent.birthdate),
            student_birthplace:this.props.selectedStudent.birthplace,
            society: society,
            current_date: formatToStringFR(new Date()),
            contrat: this.state.currentContrat,
        }
        let fileName= this.props.selectedStudent.lastname+"_"+this.props.selectedStudent.firstname+"_certificatscolarite.docx";
        postToDownLoadFile("student/"+this.props.selectedStudent.id+"/certificat/file",fileName,postData);
    }

    async printAttestation(){

        let societyId= this.state.contrats[this.state.contrats.length-1].society;
        let society= societyId ?
                    (await getRessource("society", societyId)).response :"";

        let postData={
            student_gender:this.props.selectedStudent.gender==1 ? "Monsieur": "Madame",
            student_firstname:this.props.selectedStudent.firstname,
            student_lastname:this.props.selectedStudent.lastname,
            student_birthdate:formatToStringFR(this.props.selectedStudent.birthdate),
            student_birthplace:this.props.selectedStudent.birthplace,
            society: society,
            current_date: formatToStringFR(new Date()),
            promotion: this.props.selectedPromotion,
        }
        let fileName= this.props.selectedStudent.lastname+"_"+this.props.selectedStudent.firstname+"_attestation.docx";
        postToDownLoadFile("student/"+this.props.selectedStudent.id+"/attestation/file", fileName,postData);
    }

    render() {
        return (
        <View style={this.props.showModalStudent ? styles.container : {display:"none"}}>
        {
            this.props.showModalStudent && this.props.selectedStudent&&
            <View style={styles.container}>
                <View style={styles.filter}>
                </View>
                <View style={styles.content}>
                    <FormStudent />
                    <View style={{flexDirection:"row", justifyContent:"center"}}>
                        {this.state.contrats.map((item, index)=>{
                            //console.log(item);
                            return <TouchableOpacity key={index} style={styles.button}
                                    onPress={()=>this.setState({selectedContrat:item})}>
                                <Text style={{color:"white", textAlign:"center"}}>Contrat {this.state.contrats.length > 1 ? 
                                                                                           "n° "+ (index+1) : ""} Promo #{item.promotion}</Text>
                                <Text style={{color:"yellow", textAlign:"center"}}>{InscrTypeName[item.type]}</Text>
                            </TouchableOpacity>
                        })}
                    </View>

                    <View style={{flexDirection:"row", justifyContent:"center"}}>
                        <TouchableOpacity  style={styles.button}
                            onPress={()=>this.setState({showFiles:true})}>
                            <Text style={{color:"white", textAlign:"center"}}>Fichiers</Text>
                        </TouchableOpacity>
                        <TouchableOpacity  style={styles.button}
                            onPress={()=>this.setState({showReports:true})}>
                            <Text style={{color:"white", textAlign:"center"}}>Bulletins de notes</Text>
                        </TouchableOpacity>
                        <TouchableOpacity  style={styles.button}
                            onPress={()=>this.setState({showAbsences:true})}>
                            <Text style={{color:"white", textAlign:"center"}}>Absences</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{flexDirection:"row", justifyContent:"center"}}>
                        <TouchableOpacity  style={styles.button}
                            onPress={()=>this.printAttestation()}>
                            <Text style={{color:"white", textAlign:"center"}}>Attestation</Text>
                        </TouchableOpacity>
                        <TouchableOpacity  style={styles.button}
                            onPress={()=>this.printCertificat()}>
                            <Text style={{color:"white", textAlign:"center"}}>Certificat de scolarité</Text>
                        </TouchableOpacity>
                        { this.props.userData.type === 7 &&
                          <TouchableOpacity  style={styles.button}
                            onPress={()=>this.setState({changePromotion : true})}>
                            <Text style={{color:"white", textAlign:"center"}}>Changer de promotion</Text>
                        </TouchableOpacity>}
                    </View>
                    <ModalReports
                        selectedStudent={this.props.selectedStudent}
                        isVisible={this.state.showReports}
                        onHide={()=>this.setState({showReports:false})}
                    />
                    <ModalAbsences
                        selectedStudent={this.props.selectedStudent}
                        isVisible={this.state.showAbsences}
                        promotion={this.state.currentPromotion}
                        onHide={()=>this.setState({showAbsences:false})}
                    />

                    {this.state.changePromotion &&
                      <ChangePromotion
                        selectedStudent={this.props.selectedStudent}
                        promotion={this.state.currentPromotion}
                        isVisible={this.state.changePromotion}
                        onHide={()=>this.setState({changePromotion:false})}
                      />
                    }

                    {this.state.selectedContrat&&
                        <ModalContrat
                            isVisible={this.state.selectedContrat!==null}
                            data={this.state.selectedContrat}
                            selectedStudent={this.props.selectedStudent}
                            onHide={()=>this.setState({selectedContrat:null})}
                            refresh={this.refreshContrat.bind(this)}
                        />
                    }
                    {this.state.showFiles&&
                        <ModalFiles
                            isVisible={this.state.showFiles}
                            user={this.props.selectedStudent.id}
                            onHide={()=>this.setState({showFiles:false})}
                        />
                    }
                    <TouchableHighlight
                        style={styles.buttonClose}
                        onPress={()=>{this.props.setModalVisibleStudent(false);
                                      this.props.setSelectedStudent(null);}}>
                        <Text>X</Text>
                    </TouchableHighlight>
                </View>
            </View>
        }
        </View>
        );
    }
}

const InscrTypeName={1:"Professionnalisation", 2:"Apprentissage", 3:"Initiale"};

const mapStateToProps = state => {
    return {
        showModalStudent: state.admin.showModalStudent,
        selectedStudent: state.admin.selectedStudent,
        selectedPromotion: state.admin.selectedPromotion,
        selectedPromotionData: state.admin.selectedPromotionData,
        userData: state.user.data
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setModalVisibleStudent: data => {dispatch(setModalVisibleStudent(data));},
        setSelectedPromotion: (id,data) => {dispatch(setSelectedPromotion(id, data));},
        setSelectedStudent:data => {dispatch(setSelectedStudent(data));},
        setPromotions:data => {dispatch(setPromotions(data));},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalStudent);

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        left:0,
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    content:{
        zIndex: 120,
        margin:10,
        backgroundColor: 'white',
        flex:1,
        marginBottom:80,
        marginHorizontal: Layout.window.width>450 ? Layout.window.width/4 :10,
    },
    button:{
        flex:1,
        backgroundColor: Colors.tintColor,
        alignSelf:"center",
        padding:10,
        margin:5,
        justifyContent:"center"
    },
    buttonClose:{
        position: "absolute",
        zIndex: 180,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});
