const {PDFDocument, PDFName, PDFString, PDFNumber, PDFBool} =  require('pdf-lib');
const fs = require('fs');
const { promisify } = require('util');

const readFileAsync = promisify(fs.readFile)
const writeFileAsync = promisify(fs.writeFile)

module.exports= async function(inputFile, outputFile, datas){
    const content = await readFileAsync(inputFile)
    const pdfDoc = await PDFDocument.load(content);
    const fields = getAcroFields(pdfDoc);
    let society= datas.society;
    let student= datas.student;
    fields.map((item,key)=>{
		switch(key){
            ///
            /// Employeur
            ///
            case 0: //nom employeur
				setFieldValue(item, true)
				break;
			case 2: //nom employeur
				setFieldValue(item, society.raison)
				break;
			case 3: //numero voie employeur
				setFieldValue(item, society.numVoie)
				break;
			case 4: //voie employeur
				setFieldValue(item, society.address)
				break;
			case 5: //complément adresse employeur
				setFieldValue(item, "")
				break;
			case 6: //code postale employeur
				setFieldValue(item, fieldDoubleSpace(society.cp))
                break;
            case 47: //commune employeur
                break;
			case 7: //telephone employeur
				setFieldValue(item, fieldSimpleSpace(society.tel))
                break;
            case 8: //email employeur
				setFieldValue(item, society.email)
                break;
            case 9: //siret employeur
				setFieldValue(item, fieldDoubleSpace(society.siret.replace(/ /gi, "")))
				break;
            case 10: //type employeur
                setFieldValue(item, fieldDoubleSpace(society.typeEmployeur+""))
                break;
            case 11: //employeur spécifique
                setFieldValue(item, " "+society.employeurSpecifique+"")
                break;
            case 12: //code NAF employeur
                setFieldValue(item, fieldDoubleSpace(society.codeNAF+""))
                break;
            case 13: //effectif entreprise
                setFieldValue(item, fieldDoubleSpace(society.effectif+""))
                break;
            case 14: //convention collective
                setFieldValue(item, society.conventionCollective+"")
                break;
            case 15: //code IDCC
                setFieldValue(item, fieldDoubleSpace(society.codeIDCC+""))
                break;
            ///
            /// Apprentie
            ///
            case 17: //gender
                setFieldValue(item, student.gender==1)
                break;
            case 18: //gender
                setFieldValue(item, student.gender==2)
                break;
			case 19: //nom apprentie
                setFieldValue(item, student.lastname+" "+student.firstname)
                break;
            case 20: //numero voie apprentie
                setFieldValue(item, student.numVoie)
                break;
            case 21: //voie apprentie
                setFieldValue(item, student.address)
                break;
            case 22: //complément adresse apprentie
                setFieldValue(item, "")
                break;
            case 23: //code postale apprentie
                setFieldValue(item, fieldDoubleSpace(student.cp))
                break;
            case 46: //commune apprentie
                break;
            case 24: //telephone apprentie
                setFieldValue(item, fieldDoubleSpace(student.tel_home.replace(/\./gi, "").replace(/ /gi, "")))
                break;
            case 25: //email apprentie
                setFieldValue(item, student.email_ext)
                break;
            case 26: //date de naissance apprentie
                setFieldValue(item, fieldDoubleSpace(student.birthdate.replace(/\//gi, " ")))
                break;
            case 27: //departement de naissance apprentie
                setFieldValue(item, "")
                break;
            case 28: //commune de naissance apprentie
                setFieldValue(item, student.birthplace)
                break;
            case 29: //nationalité
                setFieldValue(item, " "+student.nationalite)
                break;
            case 30: //régime sociale apprentie
                setFieldValue(item, " "+student.regimSocial)
                break;
            case 31: //situation avant contrat 
                setFieldValue(item, fieldDoubleSpace(datas.situationAvantContrat+""))
                break;
            case 32: //dernier diplome
                setFieldValue(item, fieldDoubleSpace(datas.dernierDiplome+""))
                break;
            case 33: //derniere classe
                setFieldValue(item, fieldDoubleSpace(datas.derniereClasse+""))
                break;
            case 34: //intitulé diplome préparé
                setFieldValue(item, datas.diplome.diplomaName)
                break;
            case 35: //rqth oui
                break;
            case 36: //rqth non
                setFieldValue(item, true)
                break;
            case 43: //dernier diplome obtenu
                setFieldValue(item, fieldDoubleSpace(datas.dernierDiplome+""))
                break;
            ///
            /// Representant
            ///
            case 37: //nom et prénom
                break;
            case 38: //numero voie
                break;
            case 39: //voie
                break;
            case 40: //complément adresse
                break;
            case 41: //code postale
                break;
            case 42: //commune
                break;
            ///
            /// Le tuteur
            ///
            case 44: //date naissance tuteur
                setFieldValue(item, fieldDoubleSpace(datas.tutor.birthdate.replace(/\//gi, "  ")))
                break;
            case 45: //nom et prenom tuteur
                setFieldValue(item, datas.tutor.lastname+" "+datas.tutor.firstname);
                break;
            case 46: //nom et prenom tuteur 2
                break;
            case 47: //date naissance tuteur 2
                break;
            case 48: //attestation tuteur 
                setFieldValue(item, true)
                break;
            ///
            /// Le Contrat
            ///
            case 49: //travail sur des machines dangereuse oui
                break;
            case 50: //travail sur des machines dangereuse non
                setFieldValue(item, true)
                break;
            case 51: //type de contrat
                setFieldValue(item, " 2")
                break;
            case 52: //type de dérogation
                break;
            case 53: //numéro du contrat
                break;
            case 54: //date avenant
                setFieldValue(item, fieldDoubleSpace(datas.dateAvenant.replace(/\//gi, "  ")))
                break;
            case 55: //date exécution
                setFieldValue(item, fieldDoubleSpace(datas.dateExecution.replace(/\//gi, "  ")))
                break;
            case 56: //date conclusion
                setFieldValue(item, fieldDoubleSpace(datas.dateFinContrat.replace(/\//gi, "  ")))
                break;
            case 57: //date de fin d'apprentassage ou fin de contrat
                setFieldValue(item, fieldDoubleSpace(datas.dateFinContrat.replace(/\//gi, "  ")))
                break;
            case 58: //durée de travail (heures)
                setFieldValue(item, fieldDoubleSpace(datas.dureTravail+""))
                break;
            case 59: //durée de travail (minutes)
                break;
            case 60: //rémuneration année 1 - date debut
                setFieldValue(item, fieldSimpleSpace(datas.dateExecution.replace(/\//gi, " ")))
                break;
            case 61: //rémuneration année 1 - date fin
                setFieldValue(item, fieldSimpleSpace(datas.dateFinContrat.replace(/\//gi, " ")))
                break;
            case 62: //rémuneration année 1 - % du smic
                setFieldValue(item, datas.pourcentSmic+"")
                break;
            case 63: //rémuneration année 1 - smic
                setFieldValue(item, "1202.92")
                break;
            case 64: //rémuneration année 2 - date debut
                break;
            case 65: //rémuneration année 2 - date fin
                break;
            case 70: //rémuneration année 2- % du smic
                break;
            case 71: //rémuneration année 2 - smic
                break;
            case 66: //rémuneration année 3 - date debut
                break;
            case 67: //rémuneration année 3 - date fin
                break;
            case 72: //rémuneration année 3- % du smic
                break;
            case 73: //rémuneration année 3 - smic
                break;
            case 68: //rémuneration année 4 - date debut
                break;
            case 69: //rémuneration année 4 - date fin
                break;
            case 74: //rémuneration année 4- % du smic
                break;
            case 75: //rémuneration année 4 - smic
                break;
            case 116: //salaire brut
                setFieldValue(item, fieldDoubleSpace( datas.salaire+""))
                break;
            case 117: //caisse de retraite 
                setFieldValue(item, datas.retraite+"")
                break;
            case 122: //nourriture 
                setFieldValue(item, fieldDoubleSpace( datas.nouriture+""))
                break;
            case 123: //logement 
                setFieldValue(item, fieldDoubleSpace( datas.logement+""))
                break;
            ///
            /// La Formation
            ///
            case 88: // code diplome(titre visée)
                setFieldValue(item, fieldDoubleSpace(datas.diplome.diplomeNum+""))
                break;
            case 89: //intitulé diplome préparé
                setFieldValue(item, datas.diplome.diplomaName)
                break;
            case 90: //Dénomination du CFA
                setFieldValue(item, "CFA INSTA")
                break;
            case 91: //UAI du CFA
                setFieldValue(item, fieldDoubleSpace("0755432X"))
                break;
            case 92: //Code du  diplome
                setFieldValue(item, fieldDoubleSpace(datas.diplome.diplomeNum+""))
                break;
            case 93: //Siret du CFA
                setFieldValue(item, fieldDoubleSpace("50947289000029"))
                break;
            case 94: // numero de voie du CFA
                setFieldValue(item, "12")
                break;
            case 95: // voie du CFA
                setFieldValue(item, "rue de Cléry")
                break;
            case 96: // complément adresse du CFA
                break;
            case 97: // cp du CFA
                setFieldValue(item, fieldDoubleSpace("75002"))
                break;
            case 98: // commune du CFA
                setFieldValue(item, "Paris")
                break;
            case 99: // date debut formation
                setFieldValue(item, fieldSimpleSpace(datas.promotion.date_start.replace(/\//gi, " ")))
                break;
            case 100: // cfa entreprise oui
                setFieldValue(item, true)
                break;
            case 101: // cfa entreprise non
                break;
            case 102: // date fin des épreuves
                setFieldValue(item, fieldSimpleSpace(datas.promotion.date_end.replace(/\//gi, " ")))
                break;
            case 103: // nombre heures formation
                setFieldValue(item, fieldDoubleSpace(datas.promotion.hours+""))
                break;
            case 104: // attestation employeur
                setFieldValue(item, true)
                break;
            case 105: // fait à 
                setFieldValue(item, "Paris le "+datas.dateSignatureContrat)
                break;
            default:
                //console.log(key, item)
                break;
		}
    });
    const pdfBytes = await pdfDoc.save();
	return await writeFileAsync(outputFile, pdfBytes);
}


const getAcroFields = (pdfDoc) => {
	if (!pdfDoc.catalog.get(PDFName.of('AcroForm'))) return [];
	const acroForm = pdfDoc.context.lookup(pdfDoc.catalog.get(PDFName.of('AcroForm')));
    acroForm.set( PDFName.of( 'NeedAppearances' ), PDFBool.True );
	if (!acroForm.get(PDFName.of('Fields'))) return [];
	const acroFields = pdfDoc.context.lookup(acroForm.get(PDFName.of('Fields')));
  
	return acroFields.array.map(item=>{
		return pdfDoc.context.lookup(item);	
	});
};

const setFieldValue = (acroField, valueField) => {
	let value= acroField.get(PDFName.of('AS'));
	let isCheckCase= true;
	if(!value){
		isCheckCase= false;
	}
	if(!isCheckCase){
        acroField.set(PDFName.of('V'), PDFString.of(valueField));
        //acroField.set(PDFName.of('Ff'), PDFNumber.of(1));
	}else{
		acroField.set(PDFName.of('AS'), valueField? PDFName.of('Yes'):PDFName.of('Off'));
	}
};

function fieldDoubleSpace(value){
    let temp= value+"";
    let result="";
    for(var i in temp){
        if(i==9){
            result+= " "+temp[i];
        }else{
            result+= " "+temp[i]+" ";
        }
    }
    return result;
}

function fieldSimpleSpace(value){
    let temp= value+"";
    let result="";
    for(var i in temp){
        result+= " "+temp[i];
    }
    return result;
}