import axios from 'axios';
import { retrieveData, TOKENID } from './localStorage';

const BASE_URL = window.location.protocol+'//'+window.location.hostname+":3030";

export function postGoogleConnection(tokenId, email) {
    const url = `${BASE_URL}/api/user/googleConnect`;
    return axios.post(url, {email:email}, { headers: { Authorization: `${tokenId}` }}).then((response) => {
        return response.data;
    });
}

export function postPasswordConnection(login, password) {
    const url = `${BASE_URL}/api/user/connect`;
    return axios.post(url, {login:login,password:password}).then((response) => {
        return response.data;
    });
}


export function getRessources(ressourceName) {
    const url = `${BASE_URL}/api/${ressourceName}`;
    let token= retrieveData(TOKENID);
    return axios.get(url, { headers: { Authorization: `${token}`,
                                        'Cache-Control': 'no-cache',
                                        'Pragma': 'no-cache',
                                        'Expires': '0'}}).then((response) => {
        return response.data;
    });
}

export function getRessource(ressourceName, ressourceId) {
    const url = `${BASE_URL}/api/${ressourceName}/${ressourceId}`;
    let token= retrieveData(TOKENID);
    if(!ressourceId||ressourceId=="null")return new Promise((resolve, reject)=>reject("no id"));
    return axios.get(url, { headers: { Authorization: `${token}` }}).then((response) => {
        return response.data;
    });
}

export function createRessource(ressourceName, data) {
    const url = `${BASE_URL}/api/${ressourceName}`;
    let token= retrieveData(TOKENID);
    return axios.post(url, data, { headers: { Authorization: `${token}` }}).then((response) => {
        return response.data;
    });
}

export function updateRessource(ressourceName, ressourceId, data) {
    const url = `${BASE_URL}/api/${ressourceName}/${ressourceId}`;
    let token= retrieveData(TOKENID);
    return axios.put(url, data, { headers: { Authorization: `${token}` }}).then((response) => {
        return response.data;
    });
}

export function deleteRessource(ressourceName, ressourceId) {
    const url = `${BASE_URL}/api/${ressourceName}/${ressourceId}`;
    let token= retrieveData(TOKENID);
    return axios.delete(url, { headers: { Authorization: `${token}` }}).then((response) => {
        return response.data;
    });
}

export function getRessourceChilds(ressourceName, ressourceId, ressourceChildsName) {
    const url = `${BASE_URL}/api/${ressourceName}/${ressourceId}/${ressourceChildsName}`;
    let token= retrieveData(TOKENID);
    if(!ressourceId||ressourceId=="null")return new Promise((resolve, reject)=>reject("no id"));
    return axios.get(url, { headers: { Authorization: `${token}` }}).then((response) => {
        return response.data;
    });
}

export function createRessourceChild(ressourceName, ressourceId, ressourceChildName, data) {
    const url = `${BASE_URL}/api/${ressourceName}/${ressourceId}/${ressourceChildName}`;
    let token= retrieveData(TOKENID);
    return axios.post(url, data, { headers: { Authorization: `${token}` }}).then((response) => {
        return response.data;
    });
}

export function updateRessourceChild(ressourceName, ressourceId, ressourceChildName,  data) {
    const url = `${BASE_URL}/api/${ressourceName}/${ressourceId}/${ressourceChildName}`;
    let token= retrieveData(TOKENID);
    return axios.put(url, data, { headers: { Authorization: `${token}` }}).then((response) => {
        return response.data;
    });
}

export function deleteRessourceChild(ressourceName, ressourceId, ressourceChildName,  data) {
    const url = `${BASE_URL}/api/${ressourceName}/${ressourceId}/${ressourceChildName}`;
    let token= retrieveData(TOKENID);
    return axios.delete(url, { headers: { Authorization: `${token}` }, data}).then((response) => {
        return response.data;
    });
}

export function downLoadFile(urlFile, fileName) {
    const url = `${BASE_URL}/api/${urlFile}`;
    let token= retrieveData(TOKENID);
    return axios.get(url, { responseType: 'blob', headers: { Authorization: `${token}` }}).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    });
}

export function postToDownLoadFile(urlFile, fileName, data) {
    const url = `${BASE_URL}/api/${urlFile}`;
    let token= retrieveData(TOKENID);
    return axios.post(url, data, { responseType: 'blob', headers: { Authorization: `${token}` }}).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    });
}
