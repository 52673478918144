import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';
import Icon from "react-native-vector-icons/FontAwesome";
import * as DocumentPicker from 'expo-document-picker';
import { connect } from 'react-redux';
import { getRessourceChilds, createRessource, getRessource, updateRessource, deleteRessource } from '../../../services/server_api';
import Colors from '../../../constants/Colors';
import Loader from '../../../components/Loader';
import { formatDate } from '../../../helpers/Formats';
import { USERTYPE } from '../../../helpers/Users';

class TabShareSpace extends Component{

    constructor(props){
        super(props);
        this.state={files:[], folders:[], selectedFolder:null, loader:false, showAddFolder:false}
    }

    componentDidMount(){
        if(this.props.promotion){
            this.refresh(this.props.promotion);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.promotion && (nextProps.promotion!=this.props.promotion)){
            this.refresh(nextProps.promotion);
        }
        return true;
    }

    async openPickerDocument(){
        let pickerResult = await DocumentPicker.getDocumentAsync();
        if(!pickerResult.cancelled){
            let data= {name:pickerResult.file.name,
                       type:pickerResult.file.type,
                       size:pickerResult.file.size,
                       data:pickerResult.uri,
                       folder:this.state.selectedFolder,
                       promotion:this.props.promotion,
                       creator:this.props.userInfo.id
                    };
            getRessource("promotion", this.props.promotion+"/folder/"+this.state.selectedFolder+"/file/"+data.name).then(r=>{
                if(r.response){
                    if(confirm("Le fichier est déjà éxistant, voulez-vous le remplacer?")){
                        data.modified= formatDate(new Date())+" "+(new Date()).toLocaleTimeString();
                        updateRessource("file", r.response.id, data).then(result=>{
                            this.refresh(this.props.promotion);
                        });
                    }
                }else{
                    createRessource("file", data).then(result=>{
                        this.refresh(this.props.promotion);
                    });
                }
            });
        }
    }

    addFolder(){
        let name = prompt('Entrez un nom de dossier :');
        if(name!=null&&name!=""){
            let data={
                parent:this.state.selectedFolder,
                name:name,
                promotion: this.props.promotion
            }
            createRessource("folder", data).then(result=>{
                this.refresh(this.props.promotion);
            });
        }
    }

    deleteFolder(){
        if(confirm("Etes-vous sure de vouloir supprimer ce dossier?")){
            deleteRessource("folder", this.state.selectedFolder).then(result=>{
                this.refresh(this.props.promotion);
            });
        }
    }

    refresh(promotion){
        this.setState({loader:true})
        getRessourceChilds("promotion", promotion, "files").then(async result=>{
            let files= result.response;
            let folders= (await  getRessourceChilds("promotion", promotion, "folders")).response;
            this.setState({files:files, folders:folders, loader:false, showAddFolder:promotion!=null});
        });
    }

    downLoad(item){
        let buffer= Buffer.from(item.data.data);
        let link = document.createElement('a');
        link.href = buffer.toString();
        link.setAttribute('download', item.name);
        document.body.appendChild(link);
        link.click();
    }

    delete(item){
        if(confirm("Etes-vous sure de vouloir supprimer ce fichier?")){
            deleteRessource("file",item.id).then(result=>{
                this.refresh(this.props.promotion);
            })
        }
    }

    render(){
        return <View style={styles.container}>
                    <View style={styles.folders}>
                        <TouchableOpacity style={styles.folder}
                            onPress={()=>this.setState({selectedFolder:null, showAddFolder:true})}>
                            <Icon name="folder" size={24} 
                                  style={this.state.selectedFolder==null?
                                        styles.selectedText: styles.text}/>
                            <Text style={this.state.selectedFolder==null?
                                        styles.selectedText: styles.text}> Home</Text>
                        </TouchableOpacity>
                        {this.state.folders.filter(item=>item.parent==null).map((item,key)=>{
                            return  <TouchableOpacity key={key} style={styles.folder}
                                        onPress={()=>this.setState({selectedFolder:item.id, showAddFolder:true})}>
                                        <Icon name="folder" size={24} 
                                            style={this.state.selectedFolder==item.id?
                                                    styles.selectedText: styles.text}/>
                                        <Text style={this.state.selectedFolder==item.id?
                                                    styles.selectedText: styles.text}> {item.name}</Text>
                                    </TouchableOpacity>
                        })}
                        {this.state.showAddFolder&& this.props.userInfo.type!==USERTYPE.STUDENT&&
                            <TouchableOpacity  style={styles.addFolder}
                                onPress={()=>this.addFolder()}>
                                <Icon name="plus" size={24} />
                                <Text style={styles.text}>Nouveau dossier</Text>
                            </TouchableOpacity>
                        }
                        {this.state.selectedFolder&& this.props.userInfo.type!==USERTYPE.STUDENT&&
                            <TouchableOpacity  style={styles.addFolder}
                                onPress={()=>this.deleteFolder()}>
                                <Icon name="trash" size={24} />
                                <Text style={styles.text}>Supprimer dossier selectionné</Text>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={styles.folders}>
                        {this.state.folders.filter(item=>((item.parent==this.state.selectedFolder 
                                                    && this.state.selectedFolder!=null)
                                                    ||(item.id==this.state.selectedFolder && item.parent!=null)))
                            .map((item,key)=>{
                            return  <TouchableOpacity key={key} style={styles.folder}
                                        onPress={()=>this.setState({selectedFolder:item.id, showAddFolder:false})}>
                                        <Icon name="folder" size={24} 
                                            style={this.state.selectedFolder==item.id?
                                                    styles.selectedText: styles.text}/>
                                        <Text style={this.state.selectedFolder==item.id?
                                                    styles.selectedText: styles.text}> {item.name}</Text>
                                    </TouchableOpacity>
                        })}
                    </View>
                    <ScrollView style={styles.files}>
                        {this.state.files.filter(item=>item.folder==this.state.selectedFolder)
                        .map((item,key)=>{
                            return  <View key={key} style={styles.itemFile}>
                                        <TouchableOpacity style={{flexDirection:"row", flex:1}}
                                            onPress={()=>this.downLoad(item)}>
                                            <Icon name={getIcon(item)} size={24} />
                                            <Text style={styles.text}>{item.name}</Text>
                                        </TouchableOpacity>
                                        {item.creator==this.props.userInfo.id&&
                                        <TouchableOpacity
                                            onPress={()=>this.delete(item)}>
                                            <Icon name="trash" size={24} />
                                        </TouchableOpacity>
                                        }
                                    </View>
                        })}
                    </ScrollView>
                    <Loader isVisible={this.state.loader}/>
                    {this.props.promotion&&
                        <TouchableOpacity  style={styles.addFile}
                            onPress={()=>this.openPickerDocument()}>
                            <Icon name="plus" size={24} />
                            <Text style={styles.text}>Nouveau fichier</Text>
                        </TouchableOpacity>
                    }
               </View>
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.user.data
    }
};
  
export default connect(mapStateToProps)(TabShareSpace);

const styles = StyleSheet.create({
    container:{
        flex:1,
        padding:10
    },
    folders:{
        flexDirection:"row",
        flexWrap:"wrap"
    },
    folder:{
        flexDirection:"row",
        alignItems:"center",
        marginHorizontal:25,
    },
    selectedText:{
        color:Colors.tintColor,
        padding:3
    },
    text:{
        padding:3
    },
    files:{
        flex:1
    },
    addFile:{
        flexDirection:"row",
        alignItems:"center",
        margin:25,
        padding:5
    },
    addFolder:{
        flexDirection:"row",
        alignItems:"center",
        marginHorizontal:10
    },
    itemFile:{
        flexDirection:"row",
        marginHorizontal:25,
        padding:5,
        borderBottomWidth:1
    }
});

const getIcon= (item)=>{
    let array= item.name.split(".");
    let ext = array[array.length-1].toLowerCase();
    switch(ext){
        case 'pdf':
            return "file-pdf-o";
        case 'doc':
        case 'docx':
        case 'odt':
            return "file-word-o";
        case 'xls':
        case 'xlsx':
        case 'csv':
            return "file-excel-o"
        case 'ppt':
        case 'pptx':
            return "file-powerpoint-o"
        case 'zip':
        case 'rar':
        case 'zip':
            return "file-archive-o";
        default:
            return "file-o";
    }
}