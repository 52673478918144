import React, {Component} from 'react';
import { View, StyleSheet, Text, Picker } from 'react-native';
import { getRessourceChilds } from '../services/server_api';
import { connect } from 'react-redux';
import TabShareSpace from '../containers/promotion/Share/TabShareSpace';

class ShareScreenProfessor extends Component{

    constructor(props){
        super(props);
        this.state={promotions:[], selectedPromotion:null};
    }

    componentDidMount(){
        getRessourceChilds('professor', this.props.professor.id, 'promotions').then(result=>{
            let promotions= result.response;
            if(promotions.length>0){
                this.setState({promotions: promotions, selectedPromotion: promotions[0].id})
            }
        });
    }


    render(){
        return <View style={styles.container}>
                    <Text style={styles.title}>Espace promotions</Text>
                    <Picker selectedValue={this.state.selectedPromotion}
                        style={{margin:10, padding:3}}
                        onValueChange={(value)=>{this.setState({selectedPromotion:value})}}>
                        {this.state.promotions.map((item)=>{
                                return <Picker.Item key={item.id} label={"#"+item.id+" - "+item.name} value={item.id}/>
                            })}
                    </Picker>
                    <TabShareSpace
                        promotion={this.state.selectedPromotion}
                    />
               </View>
    }
}

const mapStateToProps = state => {
    return {
        professor: state.user.data,
    }
};
  
export default connect(mapStateToProps)(ShareScreenProfessor);

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    content:{
        
    },
    title:{
       fontSize:24,
       marginLeft:25,
       padding:5,
    }
});