import * as React from 'react';
import { Text, Image, StyleSheet, View } from 'react-native';
import { getRessources } from '../../services/server_api';
import BuildRoom from './BuildRoom';
import Layout from '../../constants/Layout';

export default class Building extends React.Component {

    constructor(props){
        super(props);
        this.state={rooms:[], floors:[1,2,3,4,5]};
    }

    componentDidMount(){
        getRessources('classroom').then(result=>{
            this.setState({rooms:result.response});
        })
    }

    render(){
        return <View>
            <Image source={require('./batiment_mobil.svg')} style={{height:Layout.window.width>800? 589: 958, width:Layout.window.width>800? 275: 400}}/>
            <View style={styles.floors}>
                { this.state.rooms.length>0&&
                    <View style={styles.floor}>
                        {this.state.rooms.filter(item=>item.floor==0).map((item, key)=>{
                            return <View key={key} style={styles.roomRDC}>
                                    <BuildRoom room={item} 
                                               events={this.props.events}
                                    />
                                </View>
                        })}
                    </View>
                }

                {this.state.rooms.length>0&& 
                    this.state.floors.map((floor,key)=>{
                        return <View key={key} style={styles.floor}>
                            {this.state.rooms.filter(item=>item.floor==floor).map((item,index)=>{
                                return  <View key={index} style={styles.room}>
                                            <BuildRoom room={item} 
                                                       events={this.props.events}
                                            />
                                        </View>
                        })}
                        </View>
                    })
                }
            </View>
            <Image source={require('./my-hero-academia.gif')} style={{height:Layout.window.width>800? 275: 400, width:Layout.window.width>800? 275: 400}}/>
            
        </View>;
    }
}

const styles = StyleSheet.create({
    floors:{
        position:"absolute",
        top: Layout.window.width>800? 91: 147,
        right: Layout.window.width>800? 15: 4,
        flexDirection:"column-reverse"
    },
    floor:{
        flexDirection:"row",
        marginBottom: Layout.window.width>800? 12:21,
    },
    room:{
        backgroundColor:"#b5e7a0",
        width:Layout.window.width>800? 71: 115,
        height:Layout.window.width>800? 51: 84,
        margin:Layout.window.width>800? 3:4,
    },
    roomRDC:{
        backgroundColor:"#d5e1df",
        width:Layout.window.width>800? 71: 115,
        height:Layout.window.width>800? 56: 89,
        margin:Layout.window.width>800? 3:4,
    }
    
});