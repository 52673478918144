import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet, TextInput} from "react-native";
import Colors from '../constants/Colors';
import { noteValue } from '../helpers/Reports';
import { updateRessource, createRessource} from '../services/server_api';

export default class EditableComment extends Component{
    
    constructor(props){
        super(props);
        if(!props.data){
            this.state={modeEdit:false, value:"", id:null}
        }else{
            this.state={modeEdit:false, value:props.data.value, id:props.data.id}
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.data && 
            nextProps.data.value!==this.props.value
            && nextProps.data!==this.props.data){
           nextState.value= nextProps.data.value;
           nextState.id= nextProps.data.id;
           this.setState({value:nextState.value, id :nextState.id});
        }else if(!nextProps.data && this.props.data){
            nextState.value= "";
            nextState.id= null;
            this.setState({value:"", id :null});
        }
        return true;
    }

    onEndChange(e){
        if(!this.state.id){
            createRessource("report_appreciation",{
                value:this.state.value,
                student: this.props.student,
                report: this.props.report,
                module: this.props.module
            }).then(result=>{
                this.setState({modeEdit:false, id:result.response.insertId});
            });
       //Modification avis
        }else{
            updateRessource("report_appreciation", this.state.id, {value:this.state.value}).then(result=>{
                this.setState({modeEdit:false});
            });
       }
    }


    render() {
        return (
        <View style={styles.container}>
            {!this.state.modeEdit &&
                <TouchableOpacity style={styles.buttonAvis}
                    onPress={()=>this.setState({modeEdit:true})}
                >
                    <Text style={styles.textNote}>{noteValue(this.state.value)}</Text>
                </TouchableOpacity>
            }

            {this.state.modeEdit&&
               <TextInput style={styles.textAvisInput} 
                    value={this.state.value} 
                    onBlur={this.onEndChange.bind(this)}
                    onChangeText={(txt)=>this.setState({value:txt})}/>
            }
        </View>
        )
    }
}


const styles = StyleSheet.create({
    container:{
      margin: 5,
      flex:1
    },
    buttonAvis:{
        flex:1,
        padding:10,
        margin:5,
        borderColor: Colors.tintColor,
        borderWidth:1
    },
    textAvisInput:{
        padding:10,
        margin:5,
        flex:1,
        backgroundColor:Colors.tintColor,
        color:'white'
    },
});