import React, {Component} from 'react';
import {Text, TouchableOpacity, View, StyleSheet, TextInput, Picker, Image } from 'react-native';
import DatePicker from "react-datepicker";
import { setModalVisibleRename } from "../../store/actions/adminActions";
import Layout from '../../constants/Layout';
import { connect } from 'react-redux';
import Colors from '../../constants/Colors';
import { formatStringDate, formatDate,formatToStringFR } from '../../helpers/Formats';
import { updateRessource, getRessource, getRessources, createRessource } from '../../services/server_api';

class ModalFormRename extends Component {
    constructor(props){
        super(props);
        this.state={name:"",
                    diplome:1,
                    date_start:new Date(),
                    date_end:new Date(),
                    type:"Informatique",
                    level:1,
                    hours:"",
                    option:"",
                    diplomes:[]
                };
    }

    save(){
        let postData= Object.assign({}, this.state);
        delete postData.diplomes;
        postData.date_start = formatStringDate(postData.date_start);
        postData.date_end = formatStringDate(postData.date_end);
        if(this.props.selectedPromotion){
            updateRessource("promotion", this.props.selectedPromotion, postData).then(result=>{
                if(result.status===200){
                  alert("Enregistrement effectué");
                } else {
                  alert("Promotion non modifié")
                }
             });
             this.onHide();
        }else{
            let promotionId= parseInt(this.props.promotions[this.props.promotions.length-1].id)+1
            postData.id= promotionId;
            createRessource("promotion", postData).then(result=>{
                if(result.status===200){
                    alert("Enregistrement éffectué");
                    this.onHide();
                }
            })
        }
       
    }

    onHide(){
      this.props.setModalVisibleRename(false);
    }

    componentDidMount(){
        getRessources("diploma").then(result=>{
            this.setState({diplomes: result.response});
        });
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.selectedPromotion !== this.props.selectedPromotion && nextProps.data && nextProps.selectedPromotion){
            nextState.name = nextProps.data.name;
            getRessource("promotion", nextProps.selectedPromotion).then(result => {
              this.setState({name:result.response.name,
                             diplome:result.response.diplome,
                             date_start : new Date(formatDate(result.response.date_start)),
                             date_end : new Date(formatDate(result.response.date_end)),
                             type:result.response.type,
                             level:result.response.level,
                             hours:result.response.hours,
                             option:result.response.option,
                           });
            });
        }else if(nextProps.selectedPromotion !== this.props.selectedPromotion && !nextProps.selectedPromotion){
            this.setState({name:"",
                    diplome:1,
                    date_start:new Date(),
                    date_end:new Date(),
                    type:"Informatique",
                    level:1,
                    hours:"",
                    option:""
                });
        }
        return true;
    }

    render() {
        return (
        <View style={this.props.showModalRename ? styles.container : {display:"none"}}>
        {
            this.props.showModalRename&&
            <View style={styles.container}>
                <View style={styles.filter}>
                </View>
                <View style={styles.content}>
                    <Text style={styles.title}>Promotion</Text>
                    <View style={{flexDirection:"row"}}>
                        <TextInput  placeholder="Name"
                                style={[styles.inputText, {flex:1}]}
                                value={this.state.name}
                                onChangeText={(text)=>this.setState({name:text})}
                        />
                    </View>
                    <View style={{flexDirection:"row"}}>
                        <TextInput  placeholder="Option"
                                style={[styles.inputText, {flex:1}]}
                                value={this.state.option}
                                onChangeText={(text)=>this.setState({option:text})}
                        />
                        <TextInput  placeholder="Heures (nombre)"
                                style={[styles.inputText, {width:150}]}
                                value={this.state.hours}
                                onChangeText={(text)=>this.setState({hours:text})}
                        />
                    </View>
                    <Picker
                        style={styles.select}
                        selectedValue={this.state.type}
                        onValueChange={(itemValue, itemIndex) =>
                            this.setState({type: itemValue})
                        }>
                        <Picker.Item label="Informatique" value="Informatique" />
                        <Picker.Item label="Comptabilité" value="Comptabilité" />
                    </Picker>
                    <Picker
                        style={styles.select}
                        selectedValue={this.state.level}
                        onValueChange={(itemValue, itemIndex) =>
                            this.setState({level: itemValue})
                        }>
                        {tabLevel.map((item,index)=>{
                            return <Picker.Item key={index} label={item} value={index} />
                        })}
                    </Picker>
                    <Picker
                        style={styles.select}
                        selectedValue={this.state.diplome}
                        onValueChange={(itemValue, itemIndex) =>
                            this.setState({diplome: itemValue})
                        }>
                        {this.state.diplomes.map((item,index)=>{
                            return <Picker.Item key={index} label={item.diplomaName} value={item.id} />
                        })}
                    </Picker>
                    <View style={{flexDirection:"row", alignItems:"center", margin:5, justifyContent:"space-between", zIndex:10}}>
                        <Text>Date de début:</Text>
                        <DatePicker
                                className="datepicker-content"
                                selected={this.state.date_start}
                                placeholderText="date de début"
                                showYearDropdown={true}
                                onChange={(date)=>{
                                    this.setState({date_start: date});
                                }}
                                dateFormat="dd/MM/yyyy"/>
                        <Text>Date de fin:</Text>
                        <DatePicker
                            className="datepicker-content"
                            selected={this.state.date_end}
                            placeholderText="date de fin"
                            showYearDropdown={true}
                            onChange={(date)=>{
                                this.setState({date_end: date});
                            }}
                            dateFormat="dd/MM/yyyy"/>
                    </View>
                    <View style={{flexDirection:"row"}}>
                        <TouchableOpacity onPress={()=>{this.save()}}
                            style={[styles.button, {flex:1}]}>
                            <Text style={styles.buttonText}>Valider</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={()=>{this.onHide();}}
                             style={[styles.button, {flex:1}]}>
                            <Text style={styles.buttonText}>Annuler</Text>
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity
                        style={styles.buttonClose}
                        onPress={()=>{this.onHide();}}>
                        <Text>X</Text>
                    </TouchableOpacity>
                </View>
            </View>
        }
        </View>
        );
    }
}

const tabLevel= ["Niveau de diplome", "Niveau 7", "Niveau 6", "Niveau 5"];

const mapStateToProps = state => {
    return {
        showModalRename: state.admin.showModalRename,
        selectedPromotion: state.admin.selectedPromotion,
        data: state.admin.selectedPromotionData,
        promotions: state.admin.promotions
    }
};

const mapDispatchToProps = dispatch => {
    return {
      setModalVisibleRename: data => {dispatch(setModalVisibleRename(data));},
      setPromotions:  data => {dispatch(setPromotions(data));},
      setPromotionsName: data => {dispatch(setPromotionsName(data));},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFormRename);

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    content:{
        zIndex: 120,
        margin:10,
        marginHorizontal:50,
        backgroundColor: 'white',
        flex: Layout.window.width < 800 ? 0.7: 0.5,
    },
    title:{
        textAlign: "center",
        fontWeight:"bold",
        padding:10,
        color:Colors.tintColor,
        fontSize:24,
    },
    inputText:{
        margin: "5px",
        padding: "5px",
        borderBottomColor: "blue",
        borderBottomWidth:1
    },
    select:{
        margin: "5px",
        padding: "5px",
    },
    button:{
        margin: "5px",
        padding: "5px",
        backgroundColor:"blue",
        textAlign: "center"
    },
    buttonText:{
        color:"white"
    },
    buttonClose:{
        position: "absolute",
        zIndex: 180,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});
