exports.getAll= function(connection){
    try {
        return new Promise(resolve => {
        let request= "SELECT id, firstname, lastname FROM schoolmanager.person where type=1 order by lastname;";
            connection.query(request, function (err, result) {
               if (err) throw err;
               resolve(result);
            });
        });
    } catch (e) {
       throw e;
    }
};

exports.getAllRepresentants= function(connection, societyID){
    try {
        return new Promise(resolve => {
        let request= "SELECT * FROM schoolmanager.person where type=5 or (type!='1' and society="+societyID+") order by lastname;";
            connection.query(request, function (err, result) {
               if (err) throw err;
               resolve(result);
            });
        });
    } catch (e) {
       throw e;
    }
};

exports.getAllBirthdates= function(connection){
    try {
        return new Promise(resolve => {
        let request= "SELECT lastname, firstname, YEAR(NOW())-YEAR(birthdate) as age FROM schoolmanager.person "+
                     "where  DAY(Now())= DAY(birthdate) and MONTH(Now())=MONTH(birthdate) and type=1";
            connection.query(request, function (err, result) {
               if (err) throw err;
               resolve(result);
            });
        });
    } catch (e) {
       throw e;
    }
};

exports.getContrats= function(connection, studentId){
    try {
        return new Promise(resolve => {
        let request= "select * from inscription "+
                     "where student="+studentId;
            connection.query(request, function (err, result) {
               if (err) throw err;
               resolve(result);
            });
        });
    } catch (e) {
       throw e;
    }
};

exports.getReports= function(connection, studentId){
    try {
        return new Promise(resolve => {
        let request= "select report.* from report inner join note on note.report=report.id "+
                     "where student="+studentId+" group by report.id;";
            connection.query(request, function (err, result) {
               if (err) throw err;
               resolve(result);
            });
        });
    } catch (e) {
       throw e;
    }
};


exports.getReportAvisGlobal = function(connection, studentId, reportId){
    try {
        return new Promise(resolve => {
        let request= "SELECT reportappr.value, reportappr.id "
                        +"from report_appreciation as reportappr "
                    +"where reportappr.report="+reportId+" and reportappr.student="+studentId+" and reportappr.module is null;";
            connection.query(request, function (err, result) {
               if (err) throw err;
               if(result.length==1){ 
                resolve(result[0]);
               }else{
                resolve(null)
               }
            });
        });
    } catch (e) {
       throw e;
    }
};

exports.getReportNotes = function(connection, studentId, reportId){
    try {
        return new Promise(resolve => {
        let request= "SELECT note.*, name, reportappr.value as avis, reportappr.id as avisId FROM schoolmanager.note "
                    +"left join module as reportmodule on note.module=reportmodule.id  "
                    +"left join report_appreciation as reportappr "
                    +"on note.module=reportappr.module and note.report=reportappr.report and note.student=reportappr.student "
                    +"where note.report="+reportId+ " and note.student="+studentId+" order by note.module";
            connection.query(request, function (err, result) {
               if (err) throw err;
               resolve(result);
            });
        });
    } catch (e) {
       throw e;
    }
};

exports.getModules = function(connection, studentId){
    try {
        return new Promise(resolve => {
        let request= "SELECT module FROM schoolmanager.inscription_modules where student="+studentId+";";
            connection.query(request, function (err, result) {
                if (err) throw err;
                if(result.length>0){
                   let modules=[];
                   result.map(item=>modules.push(item.module));
                   resolve(modules);
                }else{
                    resolve(result);
                }
            });
        });
    } catch (e) {
       throw e;
    }
};

exports.getAbsences = function(connection, studentId, startDate, endDate){
    try {
        return new Promise(resolve => {
            var request = "Select DATE_FORMAT(module_date, '%d/%m/%Y') as absence_date, module_date, "+
                          "DATE_FORMAT(time_start, '%H:%i') as absence_start, "+
                          "date_format(time_end, '%H:%i')as absence_end, "+
                          "description, "+
                          "isjutified "+
                          "FROM schoolmanager.absence inner join planning on planning.id=absence.planning "+
                           "where student='"+studentId+"' "+
                           "and module_date >= '"+startDate+"' and module_date <= '"+endDate+"' "+
                           "order by module_date asc, absence_start";
            connection.query(request, function (err, result) {
                if (err) throw err;
                resolve(result);
            });
        });
    } catch (e) {
        console.log('Error', e);
    }
};

exports.getAbsencesHours= function(connection, studentId, isJustified, startDate, endDate){
    try {
        return new Promise(resolve => {
             var d = new Date();
            d.setMonth(d.getMonth() - 2);
            var query = "SELECT TIME_FORMAT(sec_to_time(sum(time_to_sec(TIMEDIFF(time_end, time_start)))), '%H') as heures_absences, student "+
                        "FROM schoolmanager.absence inner join planning on planning.id=absence.planning "+
                        "where student='"+studentId+"' and isjutified="+(isJustified ? 1 : 0)+" "+
                        "and module_date >= '"+startDate+"'  and module_date <= '"+endDate+"' group by student;";
            connection.query(query, function (err, result) {
                if (err) throw err;
                if(result.length===1){
                    resolve(parseInt(result[0].heures_absences));
                }else{
                    resolve("aucune");
                }
            });
        });
    } catch (e) {
        console.log('Error', e);
    }
};


exports.getPromotionHours= function(connection, promotion, startDate, endDate){
    try {
        return new Promise(resolve => {
             var d = new Date();
            d.setMonth(d.getMonth() - 2);
            var query = "SELECT TIME_FORMAT(sec_to_time(sum(time_to_sec(TIMEDIFF(time_end, time_start)))), '%H') as heures_total, "+
                        "promotion FROM planning "+
                        "where promotion="+promotion+" "+
                        "and module_date >= '"+startDate+"'  and module_date <= '"+endDate+"' group by promotion";
            connection.query(query, function (err, result) {
                if (err) throw err;
                if(result.length===1){
                    resolve(parseInt(result[0].heures_total));
                }else{
                    resolve("aucune");
                }
            });
        });
    } catch (e) {
        console.log('Error', e);
    }
};

exports.updatePromotion = function(connection, id, data){
    try {
        return new Promise((resolve, reject) => {
          let request = "UPDATE inscription SET promotion="+data.newPromotion+
                        " WHERE student="+id+" AND promotion="+data.oldPromotion+";";
                        console.log(request);
          connection.query(request, function (err, result) {
            if (err) throw err;
            resolve(result);
          });
        });
    } catch (e) {
      console.log('Error', e);
    }
};

