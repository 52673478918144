import * as React from 'react';
import { ScrollView, TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import Colors from "../constants/Colors";

export default class SelectDays extends React.Component{
    constructor(props){
        super(props);
        this.state={days:["L","Ma", "Me", "J", "V"],
                    daysSelected:[]};
    }

    isSelected(item){
        return this.state.daysSelected.indexOf(item)>=0;
    }

    select(item){
        let index= this.state.daysSelected.indexOf(item)
        let daysSelected= this.state.daysSelected
        if(index>=0){
            daysSelected.splice(index, 1);
        }else{
            daysSelected.push(item);
        }
        this.setState({daysSelected:daysSelected});
        this.props.onSelect(daysSelected);
    }

    render(){
        return <View style={{flexDirection:"row"}}>
                <Text style={{ margin:10, width:60, alignSelf:"center"}}>jour(s):</Text>            
                {this.state.days.map((item, key)=>{
                    return <TouchableOpacity key={key}
                        onPress={()=>this.select(item)}
                        style={this.isSelected(item)? styles.selected : styles.item}>
                        <Text style={this.isSelected(item)? styles.selectedText : styles.itemText}>{item}</Text>
                    </TouchableOpacity>
                })}
        </View>
    }
}

const styles = StyleSheet.create({
    selected: {
        backgroundColor: Colors.tintColor,
        padding:5,
        margin:10,
        width:35,
        height:35,
        justifyContent:"center",
        alignItems:"center",
        borderRadius:17.5
    },
    item: {
        padding:5,
        margin:10,
        width:35,
        height:35,
        borderWidth:1,
        justifyContent:"center",
        alignItems:"center",
        borderRadius:17.5
    },
    selectedText:{
        color:"white"
    },
    itemText:{

    }
  })