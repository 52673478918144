import React, {Component} from 'react';
import {Text, TouchableHighlight, TouchableOpacity, View, StyleSheet, TextInput, Picker,Image } from 'react-native';
import Layout from '../constants/Layout';
import {setModalVisibleStudent, setSelectedPromotion} from "../store/actions/adminActions";
import { connect } from 'react-redux';
import {updateRessource} from '../services/server_api';
import Colors from '../constants/Colors';
import * as ImagePicker from 'expo-image-picker';
import {formatStringDate} from '../helpers/Formats';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";
  
class FormStudent extends Component {
    constructor(props){
        super(props);
        this.state={firstname:"", lastname:"", address: "", type:"1", poste:"",
                    birthdate: new Date("2000-01-01"), birthplace: "", city: "",
                    birthDepNum: "",
                    cp: "", email_int: "", email_ext: "",
                    gender: "1", mobile: "", tel_home: "", nationalite: "", regimSocial: "",
                    photo: null, handicape:0, NIR:""};
    }

    openImagePickerAsync = async () => {
        let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync();

        if (permissionResult.granted === false) {
            alert("Permission to access camera roll is required!");
            return;
        }

        let pickerResult = await ImagePicker.launchImageLibraryAsync();
        if(!pickerResult.cancelled){
            this.setState({photo:pickerResult.uri});
        }
    }

    componentDidMount(){
        let nextState={};
        if(!this.props.selectedStudent)return;
        nextState= Object.assign(nextState, this.props.selectedStudent);
        nextState.birthdate = this.props.selectedStudent.birthdate ? new Date(this.props.selectedStudent.birthdate) : "";
        if(this.props.selectedStudent.photo){
            var buffer = Buffer.from(this.props.selectedStudent.photo, "utf8");
            nextState.photo= buffer.toString();
        }else{
            nextState.photo= null;
        }
        this.setState(nextState);
    }
  
    shouldComponentUpdate(nextProps, nextState){
        //nextState= nextProps.selectedStudent;
        if(nextProps.selectedStudent&& (this.props.selectedStudent!=nextProps.selectedStudent)){
            nextState= Object.assign(nextState, nextProps.selectedStudent);
            nextState.birthdate = nextProps.selectedStudent.birthdate ? new Date(nextProps.selectedStudent.birthdate) : "";
            if(nextProps.selectedStudent.photo){
                var buffer = Buffer.from(nextProps.selectedStudent.photo, "utf8");
                nextState.photo= buffer.toString();
            }else{
                nextState.photo= null;
            }
        }
        return true;
    }

    save(){
        let postData= Object.assign({}, this.state);
            postData.birthdate= postData.birthdate!=""? formatStringDate(postData.birthdate):"1900-01-01";
            postData.regimSocial= postData.regimSocial ? postData.regimSocial: "0";
            postData.cp= postData.cp ? postData.cp: "0";
            postData.birthDepNum= postData.birthDepNum ? postData.birthDepNum: "0";
        if(this.props.selectedStudent){
            //helper check date a faire
            updateRessource('person',this.props.selectedStudent.id, postData).then(result=>{
                if(result.status==200){
                    this.props.setModalVisibleStudent(false);
                    if(this.props.selectedPromotion){
                        this.props.setSelectedPromotion(this.props.selectedPromotion, this.props.selectedPromotionData);
                    }   
                }else{
                    //message d'erreur a faire
                    alert("Erreur lors de l'enregistrement");
                }
            });
        }else{
            this.props.onSave(postData);
        }
    }

    render() {
        return (
            <View style={styles.content}>
                <View style={styles.formContent}>
                    <TouchableHighlight onPress={this.openImagePickerAsync.bind(this)} 
                        style={styles.buttonPhoto}>
                        <Text style={styles.buttonText}>Choisir une photo</Text>
                    </TouchableHighlight>
                    <View style={styles.formRow}>
                        <Image 
                            style={{width: 100, height: 100}}
                            source={{uri: this.state.photo ? this.state.photo : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"}} />
                        <Picker
                            selectedValue={this.state.gender}
                            style={styles.textInput}
                            onValueChange={(itemValue, itemIndex) =>
                                this.setState({gender: itemValue})
                            }>
                            <Picker.Item label="Monsieur" value="1" />
                            <Picker.Item label="Madame" value="2" />
                        </Picker>
                        <TextInput onChangeText={text => this.setState({lastname:text})}
                                placeholder="Nom"
                                style={styles.textInput}
                                value={this.state.lastname}></TextInput>
                        <TextInput onChangeText={text => this.setState({firstname:text})}
                                placeholder="Prénom"
                                style={styles.textInput}
                                value={this.state.firstname}></TextInput>
                    </View>
                    <View style={styles.formRow}>
                        <Text style={styles.Text}>EN situation de handicape :</Text>
                        <Picker
                            selectedValue={this.state.handicape}
                            style={styles.textInput}
                            onValueChange={(itemValue, itemIndex) =>
                                this.setState({handicape: itemValue})
                            }>
                            <Picker.Item label="Oui" value={1} />
                            <Picker.Item label="Non" value={0} />
                        </Picker>
                    </View>
                    <View style={styles.formRow}>
                        <TextInput onChangeText={text => this.setState({poste:text})}
                                placeholder="Poste"
                                style={styles.textInput}
                                value={this.state.poste}></TextInput>
                    </View>
                    <View style={styles.formRow}>
                        <TextInput onChangeText={text => this.setState({address:text})}
                                placeholder="Adresse"
                                style={styles.textInput}
                                value={this.state.address}></TextInput>
                    </View>
                    <View style={styles.formRow}>
                        <TextInput onChangeText={text => this.setState({cp:text})}
                                placeholder="CP"
                                style={styles.textInput}
                                value={this.state.cp+""}></TextInput>
                        <TextInput onChangeText={text => this.setState({city:text})}
                                placeholder="Ville"
                                style={styles.textInput}
                                value={this.state.city}></TextInput>
                    </View>
                    <View style={styles.formRow}>
                        <TextInput onChangeText={text => this.setState({email_int:text})}
                                placeholder="Email interne"
                                style={styles.textInput}
                                value={this.state.email_int}></TextInput>
                        <TextInput onChangeText={text => this.setState({email_ext:text})}
                                placeholder="Email externe"
                                style={styles.textInput}
                                value={this.state.email_ext}></TextInput>
                    </View>
                    <View style={styles.formRow}>
                        <TextInput onChangeText={text => this.setState({mobile:text})}
                                placeholder="Mobile"
                                style={styles.textInput}
                                value={this.state.mobile}></TextInput>
                        <TextInput onChangeText={text => this.setState({tel_home:text})}
                                placeholder="Téléphone fixe"
                                style={styles.textInput}
                                value={this.state.tel_home}></TextInput>
                    </View>
                    {Layout.window.width > 450 &&
                        <View style={styles.formRow}>
                            <DatePicker
                                className="datepicker-content"
                                selected={this.state.birthdate}
                                placeholder={"date de naissance"}
                                showYearDropdown={true}
                                onChange={(date)=>{
                                    this.setState({birthdate: date});
                                }}
                                dateFormat="dd/MM/yyyy"/>
                            <TextInput onChangeText={text => this.setState({birthDepNum:text})}
                                    placeholder="Département de naissance"
                                    style={styles.textInput}
                                    value={this.state.birthDepNum}></TextInput>
                            <TextInput onChangeText={text => this.setState({birthplace:text})}
                                    placeholder="Lieu de naissance"
                                    style={styles.textInput}
                                    value={this.state.birthplace}></TextInput>
                            <TextInput onChangeText={text => this.setState({nationalite:text})}
                                    placeholder="nationalite"
                                    style={styles.textInput}
                                    value={this.state.nationalite ? this.state.nationalite: ""}></TextInput>
                            <TextInput onChangeText={text => this.setState({regimSocial:text})}
                                    placeholder="regime social"
                                    style={styles.textInput}
                                    value={this.state.regimSocial+""}></TextInput>
                        </View>
                    }
                    <View style={styles.formRow}>
                        <TextInput onChangeText={text => this.setState({NIR:text})}
                            placeholder="numéro de sécurité social"
                            style={styles.textInput}
                            value={this.state.NIR+""}></TextInput>
                    </View>
                    
                    <TouchableHighlight 
                        style={styles.buttonSave}
                        onPress={this.save.bind(this)}>
                        <Text style={{color:"white", padding:10, textAlign:"center"}}>Enregistrer</Text>
                    </TouchableHighlight>
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalStudent: state.admin.showModalStudent,
        selectedStudent: state.admin.selectedStudent,
        selectedPromotion: state.admin.selectedPromotion,
        selectedPromotionData: state.admin.selectedPromotionData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setModalVisibleStudent: data => {dispatch(setModalVisibleStudent(data));},
        setSelectedPromotion: (id,data) => {dispatch(setSelectedPromotion(id,data));},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormStudent);

const styles = StyleSheet.create({
    content:{
        zIndex: 120,
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin:10,
        padding:10,
        overflow:"auto",
    },
    formContent:{
        flex: 1,
    },
    formRow:{
        flexDirection: Layout.window.width > 450 ? 'row' :"column",
        alignItems:  Layout.window.width > 450 ? 'baseline' : "stretch",
        zIndex:600,
    },
    textInput:{
        flex:1,
        borderBottomWidth: 1,
        borderBottomColor: Colors.tintColor,
        color: Colors.tintColor,
        padding:2,
        margin:2
    },
    Text:{
        flex:1,
        borderBottomWidth: 1,
        borderBottomColor: Colors.tintColor,
        color: Colors.tintColor,
        padding:2,
        margin:2    
    },

    buttonPhoto:{
        padding:4,
        borderColor: Colors.tintColor
    },
    buttonSave:{
        backgroundColor: Colors.tintColor,
        padding:10,
        marginTop:20,
    },
    buttonClose:{
        position: "absolute",
        zIndex: 130,
        top:  0,
        right:10,
        backgroundColor: 'white',
        padding:3,
    }
});