import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../../constants/Colors';
import { getRessources } from '../../services/server_api';

export default class SelectProfessor extends Component{

    constructor(props){
        super(props);
        this.state={professors:[], selected:null, filtered:[], value:"", showSelectForm:false}
    }

    componentDidMount(){
        getRessources('professor').then(result=>{
            let professors= result.response;
            let selectedId= this.props.selectedId
            this.setState({professors: professors, 
                          filtered: professors, 
                          selected: professors.find((item)=>{return item.id===selectedId})});
        });
    }

    shouldComponentUpdate(nextProps, nextState){
        if(this.props.selectedId!=nextProps.selectedId){
            nextState.selected = nextState.professors.find((item)=>{return item.id===nextProps.selectedId});
        }
        return true;
    }

    filtered(value){
        let result= this.state.professors.filter(item=>{
            let name= (item.lastname+" "+item.firstname).toUpperCase();
            return name.indexOf(value.toUpperCase())!==-1;
        });
        this.setState({filtered: result, value: value});
    }

    render(){
        return <View style={styles.container}>
                <View style={{flexDirection:"row", flexWrap:"wrap",justifyContent:"flex-start", padding:10}}>
                    <Text>Intervenant: </Text>
                    {this.state.selected ?
                    <Text>{this.state.selected.lastname} {this.state.selected.firstname}</Text> :
                    <Text> aucun </Text>
                    }
                    <TouchableOpacity style={{marginLeft:10}}
                        onPress={()=>this.setState({showSelectForm:true})}>
                        <Text style={{color:Colors.tintColor}}>Selectionner</Text>
                    </TouchableOpacity>
                </View>
                {this.state.showSelectForm&&
                <View style={{flex:1}}>
                    <View style={styles.searchBar}>
                        <TextInput style={styles.searchInput}
                            value={this.state.value}
                            onChangeText={this.filtered.bind(this)}
                        />
                        <TouchableOpacity style={styles.button}
                            onPress={()=>this.setState({showSelectForm:false})}>
                            <Text style={styles.textButton}>X</Text>
                        </TouchableOpacity>
                    </View>
                    <ScrollView style={{height:150}} contentContainerStyle={{flexDirection:'row', flexWrap:"wrap", alignContent:"stretch"}}>
                        {this.state.filtered.map((item, index)=>{
                            return <TouchableOpacity key={index} style={styles.itemButton}
                                                    onPress={()=>{this.setState({selected:item, showSelectForm:false});
                                                                 this.props.onSelect(item)}}>
                                    <Text style={styles.textButton}>{item.lastname} {item.firstname}</Text>
                                    <Text>{item.poste}</Text>
                                </TouchableOpacity>
                        })}
                    </ScrollView>
                </View>
                }
            </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
        flexWrap:"wrap"
    },
    searchBar:{
        flexDirection:"row",
        flexWrap:"wrap"
    },
    searchInput:{
        flex:8,
        margin:5,
        padding:5,
        borderBottomWidth:1,
        borderBottomColor:Colors.tintColor
    },
    itemButton:{
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    button:{
        flex:1,
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    textButton:{
        color:"white",
    }
});