export default {
    default: "000396",
    theme1: "rgba(0, 3, 150,0.7)",
    theme2: "rgba(153, 10, 219,0.7)",
    theme3: "rgba(157, 143, 143,0.7)",
    theme4: "rgba(248, 172, 19,0.7)",
    theme5: "rgba(12, 171, 254,0.7)",
    theme6: "rgba(121, 159, 40,0.7)",
    theme7: "rgba(235, 117, 45,0.7)",
    theme8: "rgba(41, 109, 193,0.7)",
}