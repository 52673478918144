import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../constants/Colors';
import { getRessources } from '../services/server_api';
import ModalSociety from '../containers/society/ModalSociety';

export default class ManageSocietiesScreen extends Component{

    constructor(props){
        super(props);
        this.state={societies:[], filtered:[], value:"", showModal:false, selectedSociety:null}
    }

    componentDidMount(){
        this.refresh(null);
    }

    refresh(society){
        getRessources('society').then(result=>{
            let societies= result.response;
            this.setState({societies: societies, filtered: societies, selectedSociety:society});
            this.filtered(this.state.value);
        });
    }

    filtered(value){
        let result= this.state.societies.filter(item=>{
            let name= (item.raison).toUpperCase();
            return name.indexOf(value.toUpperCase())!==-1;
        });
        this.setState({filtered: result, value: value});
    }

    render(){
        return <View style={styles.container}>
                <View style={styles.searchBar}>
                    <TextInput style={styles.searchInput}
                        value={this.state.value}
                        onChangeText={this.filtered.bind(this)}
                    />
                    <TouchableOpacity style={styles.button}
                        onPress={()=>this.setState({showModal:true})}>
                        <Text style={styles.textTitleButton}>Nouvelle société</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView contentContainerStyle={{flexDirection:'row', flexWrap:"wrap", alignContent:"stretch"}}>
                    {this.state.filtered.map((item, index)=>{
                        return <TouchableOpacity key={index} style={styles.itemButton}
                                    onPress={()=>this.setState({showModal:true, selectedSociety:item})}
                                >
                                <Text style={styles.textTitleButton}>{item.raison}</Text>
                            </TouchableOpacity>
                    })}
                </ScrollView>
                {this.state.showModal&&
                    <ModalSociety
                        onHide={()=>this.setState({showModal:false, selectedSociety:null})} 
                        isVisible={this.state.showModal}
                        refresh={(society)=>this.refresh(society)}
                        society={this.state.selectedSociety}/>
                }
            </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    searchBar:{
        flexDirection:"row"
    },
    searchInput:{
        flex:8,
        margin:5,
        padding:5,
        borderBottomWidth:1,
        borderBottomColor:Colors.tintColor
    },
    itemButton:{
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    button:{
        flex:1,
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    textButton:{
        color:"white",
        fontSize:"12"
    },
    textTitleButton:{
        color:"white",
        fontWeight:"bold",
        fontSize:"18"
    }
});