import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet, TextInput, Picker, CheckBox} from "react-native";
import Colors from '../constants/Colors';
import { createRessourceChild, deleteRessourceChild, updateRessourceChild, updateRessource, createRessource, getRessources, getRessourceChilds } from '../services/server_api';
import { parseDataForm } from '../helpers/Planning';

export default class FormPlanningProfessor extends Component{

    constructor(props){
        super(props);
        this.state={types:["Informatique", "Comptabilité"], selectedType:"Informatique",
            promotions:[], filteredPromotions:[], modules:[], selectedPromotion:null, selectedModule:null }
    }

    componentDidMount(){
        getRessources("promotion").then(result=>{
            let promotions= result.response;
            if(promotions.length>0){
                let filteredPromotions= promotions.filter(item=>{
                    return item.type==this.state.selectedType;
                });
                let selected= null;
                if(filteredPromotions.length>0){
                    selected= filteredPromotions[0].id;
                }
                this.setState({promotions:promotions, 
                               filteredPromotions: filteredPromotions,
                               selectedPromotion:selected});
            }
        });
    }

    refreshPromotionType(type){
        let filteredPromotions= this.state.promotions.filter(item=>{
            return item.type==type;
        })
        let selected= null;
        if(filteredPromotions.length>0){
            selected= filteredPromotions[0].id;
        }
        this.setState({filteredPromotions: filteredPromotions,
                       selectedPromotion:selected, selectedType: type});
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextState.selectedPromotion && this.state.selectedPromotion!== nextState.selectedPromotion ){
            getRessourceChilds('promotion', nextState.selectedPromotion, 'modules').then(result=>{
                let modules= result.response;
                let selected= null;
                if(modules.length>0){
                    selected= modules[0].id;
                }
                this.setState({modules:modules, selectedModule:selected});
            });
        }
        return true;
    }

    save(){
       updateRessourceChild("professor", this.props.professor, "plannings", 
            {module:this.state.selectedModule, promotion: this.state.selectedPromotion}).then(result=>{
                if(result.status==200){
                    alert("Plannification enregistré");
                }else{
                    alert("Erreur lors de la plannification");
                }
                this.props.closeForm();
            });
    }

    render() {    
        return (
            <View>
                {this.props.isVisible&&
                <View style={styles.formPlanning}>
                    <View style={{flexDirection:"row"}}>
                        <Picker selectedValue={this.state.selectedType}
                                style={{}}
                                onValueChange={(value)=>this.refreshPromotionType(value)}>
                            {this.state.types.map((item, index)=>{
                                return <Picker.Item key={index} label={item} value={item}/>
                            })}
                        </Picker>
                        <Picker selectedValue={this.state.selectedPromotion}
                                style={{}}
                                onValueChange={(value)=>this.setState({selectedPromotion:value})}>
                            {this.state.filteredPromotions.map((item, index)=>{
                                return <Picker.Item key={index} label={"Promo #"+item.id} value={item.id}/>
                            })}
                        </Picker>
                        <Picker selectedValue={this.state.selectedModule}
                                style={{flex:1}}
                                onValueChange={(value)=>this.setState({selectedModule:value})}>
                            {this.state.modules.map((item, index)=>{
                                return <Picker.Item key={index} label={item.name} value={item.id}/>
                            })}
                        </Picker>
                    </View>
                    <View style={{flexDirection:"row"}}>
                        <TouchableOpacity style={styles.button}
                                            onPress={()=>{this.save()}}>
                            <Text style={styles.buttonText}>Attribuer</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button}
                                            onPress={()=>{this.props.closeForm()}}>
                            <Text style={styles.buttonText}>Annuler</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                }
            </View>
        );
    }   
}

const styles = StyleSheet.create({
    formPlanning:{
        marginVertical:45
    },
    button:{
        padding:10,
        backgroundColor: Colors.tintColor,
        margin:5,
        flex:1,
    },
    buttonText:{
        flex:1,
        textAlign:"center",
        color: 'white'
    }
});