import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, TextInput } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../constants/Colors';
import {parseData} from '../helpers/Contrats';
import {updateRessource, createRessource} from '../services/server_api';
import Layout from '../constants/Layout';


export default class FormSociety extends Component{

    constructor(props){
        super(props);
        if(props.data){
            this.state=props.data;
        }else{
            this.state={raison:"", cp:""}
        }
    }

    save(){
        let postData= parseData(this.state);
        if(postData.raison==""){
            alert("Vous devez entrez une raison sociale");
            return;
        }
        if(this.state.id){
            delete postData.id;
            updateRessource('society', this.state.id, postData).then(result=>{
                if(result.status==200){
                    alert("Enregistrement éffectué.");
                }else{
                    alert("Erreur lors de l'enregistrement. Verrifier que l'entreprise n'est pas existante.");
                }
                if(this.props.refresh){
                    this.props.refresh(postData);
                }
            });
        }else{
            createRessource("society",postData).then(result=>{
                if(result.status==200){
                    postData = Object.assign(postData, {id: result.response.insertId});
                    if(this.props.refresh){
                        this.props.refresh(postData);
                    }
                    alert("Enregistrement éffectué.");
                }else{
                    alert("Erreur lors de l'enregistrement. Verrifier que l'entreprise n'est pas existante.");
                }
            });
        }
    }

    render(){
        return <View style={styles.container}>
                    <View style={styles.contentPart}>
                        <View style={styles.rowPart}>
                            <Text style={styles.fieldText}>Nom ou dénomination:</Text>
                        </View>
                        <View style={styles.rowPart}>
                            <TextInput
                                style={styles.textInput}
                                placeholder="Raison sociale"
                                value={this.state.raison}
                                onChangeText={text => this.setState({raison:text})}
                            />
                        </View>
                        <View style={styles.rowPart}>
                            <Text style={styles.fieldText}>Adresse de l'établissement:</Text>
                        </View>
                        <View style={styles.rowPart}>
                            <TextInput
                                style={styles.textInput}
                                placeholder="Adresse"
                                value={this.state.address}
                                onChangeText={text => this.setState({address:text})}
                            />
                        </View>
                        <View style={styles.rowPart}>
                            <Text style={styles.fieldText}>Code Postal:</Text>
                            <TextInput
                                style={styles.textInput}
                                placeholder="CP"
                                value={this.state.cp+""}
                                onChangeText={text => this.setState({cp:text})}
                            />
                        </View>
                        <View style={styles.rowPart}>
                            <Text style={styles.fieldText}>Commune:</Text>
                            <TextInput
                                style={styles.textInput}
                                placeholder="Ville"
                                value={this.state.city}
                                onChangeText={text => this.setState({city:text})}
                            />
                        </View>
                        <View style={styles.rowPart}>
                            <Text style={styles.fieldText}>Téléphone:</Text>
                            <TextInput
                                style={styles.textInput}
                                placeholder="Tel"
                                value={this.state.tel? this.state.tel: ""}
                                onChangeText={text => this.setState({tel:text})}
                            />
                        </View>
                        <View style={styles.rowPart}>
                            <Text style={styles.fieldText}>Courriel:</Text>
                            <TextInput
                                style={styles.textInput}
                                placeholder="Email"
                                value={this.state.email? this.state.email:""}
                                onChangeText={text => this.setState({email:text})}
                            />
                        </View>
                        <View style={styles.rowPart}>
                            <Text style={{flex:1,textAlign:"center",marginTop:10,color:"dodgerblue"}}>OPCO</Text>
                        </View>
                        <View style={styles.rowPart}>
                            <TextInput
                                style={styles.textInput}
                                placeholder="OPCO"
                                value={this.state.opco? this.state.opco:""}
                                onChangeText={text => this.setState({opco:text})}
                            />
                            <TextInput
                                style={styles.textInput}
                                placeholder="Numéro adhérent"
                                value={this.state.numAdherentOpco? this.state.numAdherentOpco: ""}
                                onChangeText={text => this.setState({numAdherentOpco:text})}
                            />
                        </View>
                        <TouchableOpacity 
                                style={styles.buttonSave}
                                onPress={this.save.bind(this)}>
                                <Text style={{color:"white", padding:10, textAlign:"center"}}>Enregistrer</Text>
                        </TouchableOpacity>
                    </View>
                    {Layout.window.width > 450 &&
                        <View style={styles.contentPart}>
                            <View style={styles.rowPart}>
                                <Text style={styles.fieldText}>N° SIRET:</Text>
                            </View>
                            <View style={styles.rowPart}>
                                <TextInput
                                    style={styles.textInput}
                                    placeholder="Siret"
                                    value={this.state.siret? this.state.siret: ""}
                                    onChangeText={text => this.setState({siret:text})}
                                />
                            </View>
                            <View style={styles.rowPart}>
                                <Text style={styles.fieldText}>Type d'employeur:</Text>
                                <TextInput
                                    style={styles.textInputLitle}
                                    value={this.state.typeEmployeur+""}
                                    onChangeText={text => this.setState({typeEmployeur:text})}
                                />
                            </View>
                            <View style={styles.rowPart}>
                                <Text style={styles.fieldText}>Employeur specifique:</Text>
                                <TextInput
                                    style={styles.textInputLitle}
                                    value={this.state.employeurSpecifique+""}
                                    onChangeText={text => this.setState({employeurSpecifique:text})}
                                />
                            </View>
                            <View style={styles.rowPart}>
                                <Text style={styles.fieldText}>Code activité de l'entreprise(NAF):</Text>
                                <TextInput
                                    style={styles.textInputLitle}
                                    value={this.state.codeNAF+""}
                                    onChangeText={text => this.setState({codeNAF:text})}
                                />
                            </View>
                            <View style={styles.rowPart}>
                                <Text style={styles.fieldText}>Effectif total salariés de l'entreprise:</Text>
                                <TextInput
                                    style={styles.textInputLitle}
                                    value={this.state.effectif+""}
                                    onChangeText={text => this.setState({effectif:text})}
                                />
                            </View>
                            <View style={styles.rowPart}>
                                <Text style={styles.fieldText}>Convention collective applicable:</Text>
                            </View>
                            <View style={styles.rowPart}>
                                <TextInput
                                    style={styles.textInput}
                                    value={this.state.conventionCollective+""}
                                    onChangeText={text => this.setState({conventionCollective:text})}
                                />
                            </View>
                            <View style={styles.rowPart}> 
                                <Text style={styles.fieldText}>Code IDCC:</Text>
                                <TextInput
                                    style={styles.textInputLitle}
                                    value={this.state.codeIDCC+""}
                                    onChangeText={text => this.setState({codeIDCC:text})}
                                />
                            </View>
                            <View style={styles.rowPart}>
                                <Text style={styles.fieldText}>Prevoyance:</Text>
                                <TextInput
                                    style={styles.textInputLitle}
                                    value={this.state.prevoyance+""}
                                    onChangeText={text => this.setState({prevoyance:text})}
                                />
                                <Text style={styles.fieldText}>Adhes chom:</Text>
                                <TextInput
                                    style={styles.textInputLitle}
                                    value={this.state.adhesionChomage+""}
                                    onChangeText={text => this.setState({adhesionChomage:text})}
                                />
                            </View>
                            <View style={styles.rowPart}>
                                <Text style={styles.fieldText}>Urssaf:</Text>
                                <TextInput
                                    style={styles.textInputLitle}
                                    value={this.state.urssaf+""}
                                    onChangeText={text => this.setState({urssaf:text})}
                                />
                                <Text style={styles.fieldText}>Emp particulier:</Text>
                                <TextInput
                                    style={styles.textInputLitle}
                                    value={this.state.employeurParticulier+""}
                                    onChangeText={text => this.setState({employeurParticulier:text})}
                                />
                            </View>
                            {this.props.onHide &&
                                <TouchableOpacity 
                                        style={styles.buttonSave}
                                        onPress={()=>this.props.onHide()}>
                                        <Text style={{color:"white", padding:10}}>Annuler</Text>
                                </TouchableOpacity>
                            }
                        </View>
                    }
                </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:4,
        flexDirection:"row"
    },
    title:{
        flex:1,
        backgroundColor:Colors.tintColor,
        color:"white",
        padding:10,
        textAlign:"center",
        marginTop:10,
        textAlign:"center",
        fontWeight:"bold"
    },
    rowPart:{
        flex:1,
        flexDirection:'row',
        alignItems: "center"
    },
    contentPart:{
        flex:1,
    },
    textInput:{
        flex:1,
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        color:Colors.tintColor,
        padding:5,
        marginLeft:5
    },
    textInputLitle:{
        width:50,
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        color:Colors.tintColor,
        padding:5,
        marginLeft:5
    },
    fieldText:{
        color: Colors.tintColor,
        padding:5,
        marginLeft:5,
        color: 'black'
    },
    buttonSave:{
        flex:1,
        backgroundColor: Colors.tintColor,
        padding:1,
        margin:3,
        alignItems:"center",
        justifyContent:"center",
        marginTop:10,
    }
});