import React, {Component} from 'react';
import {Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import Layout from '../../constants/Layout';
import Colors from '../../constants/Colors';
import FormSociety from '../../components/FormSociety';
import FormTutor from '../../components/FormTutor';
import { getRessourceChilds } from '../../services/server_api';
import { setSelectedStudent, setModalVisibleStudent} from "../../store/actions/adminActions";
import { connect } from 'react-redux';
import ModalStudent from '../promotion/Students/ModalStudent';

class ModalSociety extends Component {
    constructor(props){
        super(props);
        this.state={selectedEmploye:null, employes:[], students:[]}
    }

    componentDidMount(){
        this.refresh(null);
    }

    refresh(employe){
        if(!this.props.society) return;
        getRessourceChilds("society", this.props.society.id,"employes").then(result=>{
            this.setState({employes: result.response});
            getRessourceChilds("society", this.props.society.id, "students").then(result2=>{
                this.setState({students: result2.response, selectedEmploye:employe});
            });
        })
    }

    render() {
        return (
        <View style={this.props.isVisible ? styles.container : {display:"none"}}>
        {
            this.props.isVisible&&
            <View style={{flex:1, flexDirection:"row", justifyContent:"center"}}>
                <View style={styles.filter}>
                </View>
                {this.props.society ? 
                <View style={styles.content}>
                    <Text style={styles.title}>{this.props.society.raison}</Text>
                    {this.state.createEmploye ? <FormTutor data={{society : this.props.society.id}}
                        onHide={()=>this.setState({createEmploye:false, selectedEmploye:null})}
                        refresh={(data)=>this.refresh(data)}
                      /> : this.state.selectedEmploye ?
                      <FormTutor data={this.state.selectedEmploye}
                          onHide={()=>this.setState({selectedEmploye:null, createEmploye:false})}
                          refresh={(data)=>this.refresh(data)}
                          /> :
                        <FormSociety data={this.props.society}
                                 refresh={this.props.refresh}/>
                      }
                    <View style={{flexDirection:"row"}}>
                        <View style={{flex:1}}>
                            <Text>Liste des employés: </Text>
                        </View>
                        <View style={{flex:1}}>
                            <Text>Liste des alternants: </Text>
                        </View>
                    </View>
                    <View style={{overflow:"auto", flex:4, padding:10, flexDirection:"row"}}>
                        <View style={{flex:1}}>
                            {this.state.employes.map((item, key)=>{
                                return <TouchableOpacity key={key}
                                            onPress={()=>this.setState({selectedEmploye:item, createEmploye:false})}
                                        >
                                        <Text  style={styles.buttonEmploye}>{item.lastname} {item.firstname}</Text>
                                        <Text>{item.poste}</Text>
                                </TouchableOpacity>
                            })}
                            <TouchableOpacity  style={styles.button}
                                  onPress={()=>this.setState({createEmploye:true, selectedEmploye:null})}>
                                <Text style={styles.text} >Ajout Employe</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{flex:1}}>
                            {this.state.students.map((item, key)=>{
                                return <TouchableOpacity key={key}
                                         onPress={()=>{this.props.setSelectedStudent(item.id);this.props.setModalVisibleStudent(true)}}>
                                        <Text style={styles.buttonStudent}>{item.lastname} {item.firstname}</Text>
                                        <Text>{item.poste}</Text>
                                </TouchableOpacity>
                            })}
                        </View>
                    </View>
                    <TouchableOpacity  style={styles.button}>
                        <Text style={styles.text} onPress={()=>{this.props.onHide();}}>Fermer</Text>
                    </TouchableOpacity>
                    {this.props.showModalStudent &&
                     <View style={{position:"fixed", left:0, width:"100%", height:"100%"}}>
                        <ModalStudent />
                     </View>
                    }
                </View>
                :
                <View style={[styles.content, {height:500}]}>
                    <FormSociety data={this.props.society}
                             refresh={this.props.refresh}/>
                    <TouchableOpacity  style={styles.button}>
                        <Text style={styles.text} onPress={()=>{this.props.onHide();}}>Fermer</Text>
                    </TouchableOpacity>
                </View>
                }
            </View>
        }
        </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalStudent: state.admin.showModalStudent,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSelectedStudent: data => {dispatch(setSelectedStudent(data));},
        setModalVisibleStudent: data => {dispatch(setModalVisibleStudent(data));},
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(ModalSociety);

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    content:{
        zIndex: 120,
        margin:10,
        backgroundColor: 'white',
        flex: Layout.window.width < 800 ? 0.9: 0.5,
        height: 800
    },
    title:{
        textAlign: "center",
        fontWeight:"bold",
        padding:10,
        color:Colors.tintColor,
        fontSize:24,
    },
    headerInputs:{
        flexDirection:"row",
    },

    button:{
        backgroundColor: Colors.tintColor,
        padding:3,
        margin:5,
        justifyContent:"center"
    },
    text:{
        textAlign: "center",
        fontWeight:"bold",
        color:"white",
        padding:10,
    },
    buttonClose:{
        position: "absolute",
        zIndex: 180,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    },
    buttonEmploye:{
        fontWeight:"bold",
        color:Colors.tintColor,
        padding:3
    },
    buttonStudent:{
        fontWeight:"bold",
        padding:3
    }
});
