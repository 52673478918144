import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import NavigationDrawerStructure from './NavigationDrawerStructure';
import HomeScreen from '../screens/HomeScreen';
import ProfilScreen from '../screens/ProfilScreen';
import HomeScreenTutor from '../screens/HomeScreenTutor';
import TutorReportsScreen from '../screens/TutorReportsScreen';
import TutorAbsencesScreen from '../screens/TutorAbsencesScreen';
import ManageModulesScreen from '../screens/ManageModulesScreen';
import ManageProfessorsScreen from '../screens/ManageProfessorsScreen';
import ManageSocietiesScreen from '../screens/ManageSocietiesScreen';
import ManageDiplomesScreen from '../screens/ManageDiplomesScreen';
import HomeScreenProfessor from '../screens/HomeScreenProfessor';
import ProfessorReportsScreen from '../screens/ProfessorReportsScreen';
import ManagePlanningScreen from '../screens/ManagePlanningScreen';
import AdminShareScreen from '../screens/AdminShareScreen';
import ShareScreenStudent from '../screens/ShareScreenStudent';
import ShareScreenProfessor from '../screens/ShareScreenProfessor';
import StudentReportsScreen from '../screens/StudentReportsScreen';
import StudentAbsencesScreen from '../screens/StudentAbsencesScreen';
import HomeScreenStudent from '../screens/HomeScreenStudent';
import StudentsScreen from '../screens/StudentsScreen';

const Stack = createStackNavigator();

export function AdminPromotionsScreenStack({ navigation }) {
    return (
        <Stack.Navigator initialRouteName="AdminPromotions">
          <Stack.Screen
            name="AdminPromotions"
            component={HomeScreen}
            options={{
              title: 'Schoolmanager - Promotions', //Set Header Title
              headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
              headerStyle: {
                backgroundColor: '#FFF', //Set Header color
              },
              headerTintColor: '#2f95dc', //Set Header text color
              headerTitleStyle: {
                fontWeight: 'bold', //Set Header text style
                marginLeft:20
              },
            }}
          />
        </Stack.Navigator>
    );
}

export function ProfilScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="Profile">
        <Stack.Screen
          name="Profile"
          component={ProfilScreen}
          options={{
            title: 'Schoolmanager - Profil', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function HomeTutorScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="HomeTutor">
        <Stack.Screen
          name="HomeTutor"
          component={HomeScreenTutor}
          options={{
            title: 'Schoolmanager', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function TutorReportsScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="TutorReports">
        <Stack.Screen
          name="TutorReports"
          component={TutorReportsScreen}
          options={{
            title: 'Schoolmanager - Bulletins de notes', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function TutorAbsencesScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="TutorAbsences">
        <Stack.Screen
          name="TutorAbsences"
          component={TutorAbsencesScreen}
          options={{
            title: 'Schoolmanager - Absences', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function ManageModulesScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="ManageModules">
        <Stack.Screen
          name="ManageModules"
          component={ManageModulesScreen}
          options={{
            title: 'Schoolmanager - Modules', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function ManageProfessorsScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="ManageProfessors">
        <Stack.Screen
          name="ManageProfessors"
          component={ManageProfessorsScreen}
          options={{
            title: 'Schoolmanager - Profésseurs', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function ManageSocietiesScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="ManageSocieties">
        <Stack.Screen
          name="AdminPromotions"
          component={ManageSocietiesScreen}
          options={{
            title: 'Schoolmanager - Sociétées', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function ManageDiplomesScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="ManageDiplomes">
        <Stack.Screen
          name="ManageDiplomes"
          component={ManageDiplomesScreen}
          options={{
            title: 'Schoolmanager - Diplomes', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function HomeProfessorScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="HomeProfessor">
        <Stack.Screen
          name="HomeProfessor"
          component={HomeScreenProfessor}
          options={{
            title: 'Schoolmanager', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function ProfessorReportsScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="ProfessorReports">
        <Stack.Screen
          name="ProfessorReports"
          component={ProfessorReportsScreen}
          options={{
            title: 'Schoolmanager - Bulletins de notes', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}


export function ManagePlanningScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="ManagePlanning">
        <Stack.Screen
          name="ManagePlanning"
          component={ManagePlanningScreen}
          options={{
            title: 'Schoolmanager - Promotions', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function AdminShareScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="AdminShare">
        <Stack.Screen
          name="AdminShare"
          component={AdminShareScreen}
          options={{
            title: 'Schoolmanager - Promotions', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function ShareStudentScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="ShareStudent">
        <Stack.Screen
          name="ShareStudent"
          component={ShareScreenStudent}
          options={{
            title: 'Schoolmanager - Partages', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function ShareProfessorScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="ShareProfessor">
        <Stack.Screen
          name="ShareProfessor"
          component={ShareScreenProfessor}
          options={{
            title: 'Schoolmanager - Promotions', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function StudentReportsScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="StudentReports">
        <Stack.Screen
          name="StudentReports"
          component={StudentReportsScreen}
          options={{
            title: 'Schoolmanager - Bulletins de notes', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function StudentAbsencesScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="StudentAbsences">
        <Stack.Screen
          name="StudentAbsences"
          component={StudentAbsencesScreen}
          options={{
            title: 'Schoolmanager - Absences', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function ManageStudentsScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="ManageStudents">
        <Stack.Screen
          name="ManageStudents"
          component={StudentsScreen}
          options={{
            title: 'Schoolmanager - Students', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}

export function HomeStudentScreenStack({ navigation }) {
  return (
      <Stack.Navigator initialRouteName="HomeStudent">
        <Stack.Screen
          name="HomeStudent"
          component={HomeScreenStudent}
          options={{
            title: 'Schoolmanager', //Set Header Title
            headerLeft: ()=> <NavigationDrawerStructure navigationProps={navigation} />,
            headerStyle: {
              backgroundColor: '#FFF', //Set Header color
            },
            headerTintColor: '#2f95dc', //Set Header text color
            headerTitleStyle: {
              fontWeight: 'bold', //Set Header text style
              marginLeft:20
            },
          }}
        />
      </Stack.Navigator>
  );
}