exports.fieldsToInstert= (data)=>{
    let fields=[];
    let values=[];
    for(field in data){
        fields.push("`"+field+"`");
        let value= data[field]+"";
        value= value.replace(/\"/g,'\\\"')
                    .replace(/\'/g,'\\\'').trim();
        if(value=="null"){
            values.push("null");
        }else{
            values.push("'"+value+"'");
        }
        
    }
    return "("+fields.join(", ")+") values ("+values.join(", ")+")";
}

exports.fieldsToUpdate= (data)=>{
    let fields=[];
    for(field in data){
        let value= data[field]+"";
        value= value.replace(/\"/g,'\\\"')
                    .replace(/\'/g,'\\\'').trim();
        if(value=="null"){
            fields.push("`"+field+"`"+"=null");
        }else{
            fields.push("`"+field+"`"+"='"+value+"'");
        }
    }
    return fields.join(", ");
}

exports.formatDate= (date)=>{
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
};

exports.formatDateFR= (date)=>{
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
};

exports.parseStudents= (students)=>{
    let datas={};
    datas['students_professionnalisation'] = [];
    datas['students_apprentissage'] = [];
    datas['students_initiale'] = [];
    for(var i in students){
        switch(students[i].type){
            case 1:
                datas.students_professionnalisation.push(students[i]);
                break;
            case 2:
                datas.students_apprentissage.push(students[i]);
                break;
            default:
                datas.students_initiale.push(students[i]);
                break;
        }
    }
    return datas;
}

exports.getPromotionModuleCodes= (type, level, option)=>{
    switch(type){
        case "Informatique":
            if(option=="STL") return ["MSTL"];
            switch(level){
                case 1:
                    return option=="DEV" || option=="Logiciel" ? ["ARD"] :["ARR"];
                case 2:
                    return option=="DEV" || option=="Logiciel" ? ["AND"] :["ANR"];
                default:
                    return ["ALLBTS", "BTSSIO"]
            }
        default:
            switch(level){
                case 1:
                    return ["DSCG"];
                case 2:
                    return ["DCG"];
                default:
                    return ["ALLBTS", "BTSCG"]
            }
    }
    return [""];
}

exports.getPromotionExportHeader= ()=>{
    return [
        {id: 'type_inscription', title: 'TYPE'},
        {id: 'gender', title: 'GENDER'},
        {id: 'lastname', title: 'LASTNAME'},
        {id: 'firstname', title: 'FIRSTNAME'},
        {id: 'email_ext', title: 'EMAILEXT'},
        {id: 'email_int', title: 'EMAILINT'},
        {id: 'tel_home', title: 'PHONE'},
        {id: 'mobile', title: 'MOBILE'},
        {id: 'address', title: 'ADRESSE'},
        {id: 'cp', title: 'CP'},
        {id: 'city', title: 'CITY'},
        {id: 'society', title: 'SOCIETY'},
        {id: 'society_address', title: 'SOCIETY_ADRESSE'},
        {id: 'society_cp', title: 'SOCIETY_CP'},
        {id: 'society_city', title: 'SOCIETY_CICTY'},
        {id: 'tutor_gender', title: 'TUTOR_GENDER'},
        {id: 'tutor_lastname', title: 'TUTOR_LASTNAME'},
        {id: 'tutor_firstname', title: 'TUTOR_FIRSTNAME'},
        {id: 'tutor_email', title: 'TUTOR_EMAIL'},
        {id: 'tutor_tel', title: 'TUTOR_phone'},
        {id: 'tutor_mobile', title: 'TUTOR_mobile'}
    ];
}
