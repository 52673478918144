import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet} from "react-native";
import {setModalVisiblePromoStudents, setModalVisiblePromotionPlanning,
        setModalVisiblePromotionReports, setModalVisibleInscription,
        setModalVisibleInscriptionModule, setModalVisibleRename,
        setModalVisibleCandidate} from "../store/actions/adminActions";
import { connect } from 'react-redux';
import Colors  from '../constants/Colors';
import { downLoadFile, getRessource, getRessources, postToDownLoadFile } from '../services/server_api';
import { formatToStringFR } from '../helpers/Formats';

class PromotionDetails extends Component{

        downloadExport(promotionId){
                let url= `promotion/${promotionId}/studentsExport`;
                let fileName= "#"+promotionId+"_export.csv";
                downLoadFile(url,fileName);
        }

        downloadTrombi(promotionId){
                let url= `promotion/${promotionId}/trombi`;
                let fileName= "#"+promotionId+"_trombi.pdf";
                downLoadFile(url, fileName);
        }

        async printCertificat(promotionId){
                let students = (await getRessources("promotion/"+promotionId+"/students")).response;
                for(var i=0; i< students.length; i++){
                        let contracts = (await getRessources("student/"+students[i].id+"/contrats")).response;
                        let societyId= contracts[contracts.length-1].society;
                        if(contracts[contracts.length-1].status!=5){
                                let society= societyId ?
                                (await getRessource("society", societyId)).response :"";
                                let student= (await getRessource("person", students[i].id)).response;
                                let postData={
                                        student_gender:students[i].gender==1 ? "Monsieur": "Madame",
                                        student_firstname:students[i].firstname,
                                        student_lastname:students[i].lastname,
                                        student_birthdate:formatToStringFR(student.birthdate),
                                        student_birthplace: student.birthplace,
                                        society: society,
                                        current_date: formatToStringFR(new Date()),
                                        contrat: contracts[contracts.length-1],
                                }
                                let fileName= students[i].lastname+"_"+students[i].firstname+"_certificatscolarite.docx";
                                postToDownLoadFile("student/"+students[i].id+"/certificat/file",fileName,postData);
                        }
                }
        }

        render(){
                //console.log("data", this.props)
                let color= "#ccc";
                let fontColor= "#FFF";
                if(this.props.data){
                        color= Colors.getPromoColor(this.props.data);
                        fontColor= Colors.getPromoColorFont(this.props.data);
                }

                return <View style={[styles.container, {backgroundColor: color}]}>
                {this.props.data && this.props.selectedPromotion &&
                <View>
                        <Text style={[styles.title, styles.font, {color: fontColor}]}>#{this.props.data.id} {this.props.data.name}</Text>
                        <View style={{flexDirection:"row"}}>
                                <View style={{flex:1}}>
                                        <TouchableOpacity onPress={this.props.onSelect}>
                                                <Text style={{color: fontColor}}>du {this.props.data.date_start} au {this.props.data.date_end}</Text>
                                        </TouchableOpacity>
                                        <Text style={{color: fontColor}}>Nombre inscrits : {this.props.data.nbrInscrit}</Text>
                                        {this.props.data.nbrPro!==0 &&
                                                <Text style={{color: fontColor}}>contrats professionel : {this.props.data.nbrPro}</Text>
                                        }
                                        {this.props.data.nbrAppr!==0 &&
                                                <Text style={{color: fontColor}}>contrats apprentissage : {this.props.data.nbrAppr}</Text>
                                        }
                                        {this.props.data.nbrInit!==0 &&
                                                <Text style={{color: fontColor}}>en initiale : {this.props.data.nbrInit}</Text>
                                        }
                                        {this.props.data.nbrMaintien!==0 &&
                                                <Text style={{color: fontColor}}>nombre en maintien :{this.props.data.nbrMaintien}</Text>
                                        }
                                        {this.props.data.nbrAbandon!==0 &&
                                                <Text style={{color: fontColor}}>nombre abandon :{this.props.data.nbrAbandon}</Text>
                                        }
                                        {this.props.data.nbrAttente!==0 &&
                                                <Text style={{color: fontColor}}>nombre en attente :{this.props.data.nbrAttente}</Text>
                                        }
                                        {this.props.data.nbrRefus!==0 &&
                                                <Text style={{color: fontColor}}>nombre refus :{this.props.data.nbrRefus}</Text>
                                        }
                                        {this.props.data.nbrAdmis!==0 &&
                                                <Text style={{color: fontColor}}>nombre admis :{this.props.data.nbrAdmis}</Text>
                                        }
                                        {this.props.data.nbrCandidat!==0 &&
                                                <Text style={{color: fontColor}}>nombre candidats :{this.props.data.nbrCandidat}</Text>
                                        }
                                        {
                                                this.props.data.type=="Comptabilité" &&  this.props.data.level<3&&
                                                <TouchableOpacity style={[styles.buttonLeft, {position: "absolute", bottom: 43, width: "99%"}]}
                                                        onPress={()=>{
                                                        this.props.setModalVisibleInscriptionModule(true);
                                                        }}>
                                                        <Text>Inscription aux modules</Text>
                                                </TouchableOpacity>
                                        }
                                        <TouchableOpacity style={[styles.buttonLeft, {position: "absolute", bottom: 0, width: "99%"}]}
                                                onPress={()=>this.downloadExport(this.props.data.id)}>
                                                <Text>Export</Text>
                                        </TouchableOpacity>
                                </View>
                                <View style={{flex:1}}>
                                        <TouchableOpacity style={styles.button}
                                                onPress={()=>{
                                                        this.props.setModalVisiblePromoStudents(true);
                                                }}
                                        >
                                                <Text>Inscrits</Text>
                                        </TouchableOpacity>
                                        {this.props.data.nbrCandidat!==0 &&
                                        <TouchableOpacity style={styles.button}
                                                onPress={()=>{
                                                        
                                                        this.props.setModalVisibleCandidate(true);
                                                }}
                                        >
                                                <Text>Candidats</Text>
                                        
                                        </TouchableOpacity>}
                                        <TouchableOpacity style={styles.button}
                                                onPress={()=>{
                                                this.props.setModalVisiblePromotionPlanning(true);
                                        }}>
                                                <Text>Planning</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.button}
                                                onPress={()=>this.downloadTrombi(this.props.data.id)}>
                                                <Text>Trombinoscope</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.button}
                                                onPress={()=>{
                                                this.props.setModalVisiblePromotionReports(true);
                                        }}>
                                                <Text>Notes</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.button}
                                                onPress={()=>{
                                                        this.printCertificat(this.props.data.id)
                                        }}>
                                                <Text>Tous les certificats</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.button}
                                                onPress={()=>{
                                                this.props.setModalVisibleInscription(true);
                                        }}>
                                                <Text>Inscrire</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.button}
                                                onPress={()=>{
                                                this.props.setModalVisibleRename(true);
                                        }}>
                                                <Text>Modifier</Text>
                                        </TouchableOpacity>
                                </View>
                        </View>
                </View>
                }
        </View>
        }
}
const mapStateToProps = state => {
        return {
                data: state.admin.selectedPromotionData,
                selectedPromotion: state.admin.selectedPromotion,
        }
};

const mapDispatchToProps = dispatch => {
        return {
            setModalVisiblePromoStudents: data => {dispatch(setModalVisiblePromoStudents(data));},
            setModalVisiblePromotionPlanning: data => {dispatch(setModalVisiblePromotionPlanning(data));},
            setModalVisiblePromotionReports: data => {dispatch(setModalVisiblePromotionReports(data));},
            setModalVisibleInscription: data => {dispatch(setModalVisibleInscription(data));},
            setModalVisibleInscriptionModule: data=> {dispatch(setModalVisibleInscriptionModule(data));},
            setModalVisibleRename: data => {dispatch(setModalVisibleRename(data));},
            setModalVisibleCandidate: data => {dispatch(setModalVisibleCandidate(data));},
        };
};
    
export default connect(mapStateToProps, mapDispatchToProps)(PromotionDetails);

const styles = StyleSheet.create({
        container:{
          margin: 5,
          padding: 5,
          flex:2,
        },
        title:{
            fontSize: 24,
            fontWeight:'bold',
            padding:10
        },
        row:{
            flexDirection: 'row',
            justifyContent:'space-between',
            paddingLeft:25,
            paddingRight:18,
            paddingTop:10,
            paddingBottom:10,
            height:70,
            alignItems:'center',
            backgroundColor: Colors.tintColor,
        },
        parentHr:{
            height:1,
            width:'100%'
        },
        child:{
            padding:16,
            flexDirection: 'row',
            justifyContent:'space-between',
            backgroundColor: '#ccc'
        },
        button:{
            margin: 2,
            padding: 10,
            flex: 1,
            backgroundColor: 'white',
        },
        buttonLeft:{
            margin: 2,
            padding: 10,
            alignItems:"center",
            backgroundColor: 'white',
        }
    
    });