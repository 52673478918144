import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../../constants/Layout';
import { parseStudentsNotes, studentAddAverages, getReportType, parseDataFoFile } from '../../../helpers/Reports';
import StudentReport from './StudentReport';
import ModalReportStudent from '../../student/ModalReportStudent';
import { getRessourceChilds, postToDownLoadFile, updateRessource } from '../../../services/server_api';
import Colors from '../../../constants/Colors';
import ModalReportStructure from './ModalReportStructure';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../components/CustomDatePicker.css";
import { formatDate } from '../../../helpers/Formats';

export default class ModalReportNotes extends Component {
    
    constructor(props){
        super(props);
        this.state={modalIsVisible:false, 
                   modalEditIsVisible:false, 
                   selectedStudent:null,
                   council: this.props.report.council? 
                            new Date(this.props.report.council):""};
    }
    
    openModalStructure(){
        this.setState({modalEditIsVisible:true})
    }

    onSelectedStudentReport(student){
        this.setState({modalIsVisible:true, selectedStudent:student});
    }

    onPressPrint(student){
        getRessourceChilds("student", student.id, `report/${this.props.report.id}/notes`).then(async result=>{
            let studentModules= (await getRessourceChilds("student", student.id, "modules")).response;
            let dataNote= result.response.notes.filter(item=>{
                if (studentModules.length==0)return true;
                else return studentModules.indexOf(item.module) >=0;
            });
            let temp= parseStudentsNotes([student], dataNote);
            let reportType= getReportType(this.props.report.name);
            let parsedReportNotes= studentAddAverages(temp[0], reportType);
            parsedReportNotes.student= student.id;
            //oral d'anglais
            if(temp[0].notes["module_2"] && temp[0].notes["module_2"].notesExams.length>1){
                temp[0].notes["module_2.1"]= Object.assign({}, temp[0].notes["module_2"]);
                temp[0].notes["module_2.1"].avis=null;
                temp[0].notes["module_2.1"].name=temp[0].notes["module_2.1"].name+ " ORAL";
                temp[0].notes["module_2.1"].notes=[];
                temp[0].notes["module_2.1"].average=null;
                temp[0].notes["module_2.1"].notesExams=[temp[0].notes["module_2"].notesExams[1]];
                temp[0].notes["module_2"].notesExams=  [temp[0].notes["module_2"].notesExams[0]];
            }
            this.printReport(parsedReportNotes, result.response.avisGlobal);
        });
    }

    async printReport(parsedReportNotes, avisGlobal){
        let url= `student/${parsedReportNotes.id}/report/${this.props.report.id}/file`;
        let fileName= "#"+parsedReportNotes.lastname+"_"+parsedReportNotes.firstname
                         +"_"+this.props.report.name.replace(/ /g,"").replace(/\//g,"")+".docx";
        let postData =Object.assign({}, parsedReportNotes);
        
        postData= Object.assign(postData, this.props.report);
        let startDate= formatDate(new Date(postData.council).setMonth((new Date()).getMonth()-2));
        let endDate = formatDate(new Date(postData.council));
        postData.absences= (await getRessourceChilds('student', postData.student, 'absences/'+startDate+"/"+endDate)).response;    
        postData= parseDataFoFile(postData);
        postData.avisGlobale= avisGlobal ? avisGlobal.value : "";
        postToDownLoadFile(url, fileName, postData);
    }

    saveDateCouncil(){
        if(this.state.council!=""){
            let date= formatDate(this.state.council);
            updateRessource("report", this.props.report.id, {council:date}).then(result=>{
                if(result.status==200){
                    alert("Date enregistré.");
                    this.props.refresh();
                }
            })
        }
    }

    render() {
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible &&
                <View style={styles.container}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>promotion #{this.props.promotion}</Text>
                        <Text style={styles.title}>{this.props.report.name}</Text>
                        <View style={{flexDirection:"row", justifyContent:"center", backgroundColor:"white", alignItems:"center", zIndex:200}}>
                            <Text>Date du conseil: </Text>
                            <DatePicker
                            className="datepicker-content"
                                selected={this.state.council}
                                placeholderText="dd/MM/yyyy"
                                showYearDropdown={true}
                                onChange={(date)=>{
                                    this.setState({council: date});
                                }}
                                dateFormat="dd/MM/yyyy"/>
                                <TouchableHighlight
                                    onPress={()=>this.saveDateCouncil()}
                                    style={{margin:5, padding:5, backgroundColor:Colors.tintColor}}>
                                    <Text style={{color:"white"}}>Valider</Text>
                                </TouchableHighlight>
                        </View>
                        <ScrollView style={{height:475}}>
                            {this.props.students.map((item,index)=>{
                               return <StudentReport key={index} 
                                            student={item} 
                                            report={this.props.report}
                                            onPressPrint={()=>{this.onPressPrint({id:item.id, 
                                                                                  firstname: item.firstname,
                                                                                  lastname: item.lastname,
                                                                                  society_name: item.society_name,
                                                                                  gender: item.gender})}}
                                            onPressDetail={()=>this.onSelectedStudentReport({id:item.id, 
                                                                                             firstname: item.firstname,
                                                                                             lastname: item.lastname})}/>
                                            })}
                        </ScrollView>
                        {this.state.selectedStudent&&
                            <ModalReportStudent
                                isEditable={true}
                                isVisible={this.state.modalIsVisible}
                                report={this.props.report}
                                student={this.state.selectedStudent}
                                onHide={()=>{this.setState({modalIsVisible:false})}}
                            />
                        }
                        {this.state.modalEditIsVisible&&
                            <ModalReportStructure
                                isVisible={this.state.modalEditIsVisible}
                                promotion={this.props.promotion}
                                report={this.props.report}
                                refresh={this.props.refresh}
                                onHide={()=>{this.setState({modalEditIsVisible:false})}}
                            />
                        }
                        <TouchableHighlight style={styles.buttonClassement}
                                            onPress={()=>this.openModalStructure()}>
                            <Text style={{color:'white'}}>Structure bulletins</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={this.props.onHide}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'fixed',
        width: "100%",
        height: "90%",
        zIndex: 100,
        left:0,
        top:60
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center",
        width:"100%",
    },
    content:{
        zIndex: 140,
        left:0,
        alignSelf: 'center',
        width:"100%",
        flex:1
    },
    listEvents:{
        
    },
    moduleName:{
        paddingLeft:5
    },
    buttonClose:{
        position: "absolute",
        zIndex: 140,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    },
    buttonClassement:{
        position: "absolute",
        zIndex: 140,
        top:  0,
        left:10,
        margin:10,
        padding:10,
        backgroundColor: Colors.tintColor,
    }
});