
let INITIAL_STATE = {
    promotion: null,
    reports: [],
};

let studentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_STUDENTPROMOTION":
            return {...state, promotion: action.value};
        case "SET_STUDENTREPORTS":
            return {...state, reports: action.value};
        default:
            return state;
    }
};

export default studentReducer;