import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../constants/Colors';
import { getRessources } from '../services/server_api';
import ModalDiplome from '../containers/diplome/ModalDiplome';

export default class ManageDiplomesScreen extends Component{

    constructor(props){
        super(props);
        this.state={diplomes:[], filtered:[], value:"", showModal:false, selected:null}
    }

    componentDidMount(){
        this.refresh();
    }

    refresh(){
        getRessources('diploma').then(result=>{
            let diplomes= result.response.sort((a, b)=> a.type - b.type);
            this.setState({diplomes: diplomes, filtered: diplomes});
        });
    }

    filtered(value){
        let result= this.state.diplomes.filter(item=>{
            let name= (item.diplomaName).toUpperCase();
            return name.indexOf(value.toUpperCase())!==-1;
        });
        this.setState({filtered: result, value: value});
    }

    render(){
        return <View style={styles.container}>
                <View style={styles.searchBar}>
                    <TextInput style={styles.searchInput}
                        value={this.state.value}
                        onChangeText={this.filtered.bind(this)}
                    />
                    <TouchableOpacity style={styles.button}
                                      onPress={()=>this.setState({showModal:true, selected:null})}>
                        <Text style={styles.textTitleButton}>Nouveau diplome</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView contentContainerStyle={{flexDirection:'row', flexWrap:"wrap", alignContent:"stretch"}}>
                    {this.state.filtered.map((item, index)=>{
                        return <TouchableOpacity key={index} style={
                                                                    item.type===1 && item.typeDiplome==="informatique"? styles.itemButton :
                                                                    item.type===2 && item.typeDiplome==="informatique"? styles.itemButton2 :
                                                                    item.type===3 && item.typeDiplome==="informatique"? styles.itemButton3 :
                                                                    item.type===4 && item.typeDiplome==="informatique"? styles.itemButton4 :
                                                                    item.typeDiplome==="informatique"? styles.itemButton5 :
                                                                    item.type===1 ? styles.itemButtonCompta :
                                                                    item.type===2 ? styles.itemButtonCompta2:
                                                                    item.type===3 ? styles.itemButtonCompta3 :
                                                                    item.type===4 ? styles.itemButtonCompta4 :
                                                                    styles.itemButtonCompta5 
                                                                }
                                                 onPress={()=>this.setState({showModal:true, selected:item})}>
                                <Text style={styles.textTitleButton}>{item.diplomaName}</Text>
                            </TouchableOpacity>
                    })}
                </ScrollView>
                {this.state.showModal&&
                    <ModalDiplome 
                        onHide={()=>this.setState({showModal:false, selected:null})}
                        selected={this.state.selected} 
                        refresh={()=> this.refresh()}
                        isVisible={this.state.showModal}/>
                }
            </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    searchBar:{
        flexDirection:"row"
    },
    searchInput:{
        flex:8,
        margin:5,
        padding:5,
        borderBottomWidth:1,
        borderBottomColor:Colors.tintColor
    },
    itemButton:{
        padding:10,
        margin:2,
        backgroundColor:"#000396",
    },
    itemButton2:{
        padding:10,
        margin:2,
        backgroundColor:"#0004dc",
    },
    itemButton3:{
        padding:10,
        margin:2,
        backgroundColor:"#2629ff",
    },
    itemButton4:{
        padding:10,
        margin:2,
        backgroundColor:"#6e71ff",
    },
    itemButton5:{
        padding:10,
        margin:2,
        backgroundColor:"#b7b8ff",
    },
    itemButtonCompta:{
        padding:10,
        margin:2,
        backgroundColor:"#f8ac13",
    },
    itemButtonCompta2:{
        padding:10,
        margin:2,
        backgroundColor:"#f9bc41",
    },
    itemButtonCompta3:{
        padding:10,
        margin:2,
        backgroundColor:"#fbcd71",
    },
    itemButtonCompta4:{
        padding:10,
        margin:2,
        backgroundColor:"#fcdda0",
    },
    itemButtonCompta5:{
        padding:10,
        margin:2,
        backgroundColor:"#feeed0",
    },
    button:{
        flex:1,
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    textButton:{
        color:"white",
        fontSize:"12"
    },
    textTitleButton:{
        color:"white",
        fontWeight:"bold",
        fontSize:"18"
    }
});