import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../../constants/Layout';
import AbsenceInput from '../../../components/AbsenceInput';

export default class ModalPromotionAbsences extends Component {
    
    render() {
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible && this.props.promotion &&
                <View style={styles.container}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>Absences promotion #{this.props.promotion}</Text>
                        <Text style={styles.title}>{this.props.selectedDate} ({this.props.students.length} inscrits)</Text>
                        <Text style={styles.title}>{this.props.selectedEvent.name} : {this.props.selectedEvent.time_start} - {this.props.selectedEvent.time_end}</Text>
                        <View style={styles.listStudents}>
                            <ScrollView style={{height:400}}>
                            { this.props.students.map((item, index) => (
                               <AbsenceInput key={index} 
                                            student={item} 
                                            event={this.props.selectedEvent} 
                                            dataAbsences={this.props.dataAbsences}
                                />
                            ))}
                            </ScrollView>
                        </View>
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={this.props.onHide}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 200
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
    },
    title:{
        width: Layout.window.width < 700 ? Layout.window.width: 700,
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        alignSelf: 'center',
        justifyContent:"center",
        marginTop: 10,
    },
    listStudents:{
        
    },
    moduleName:{
        paddingLeft:5
    },
    buttonClose:{
        position: "absolute",
        zIndex: 180,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});