import React, {Component} from 'react';
import {setReports} from "../store/actions/studentActions";
import { connect } from 'react-redux';
import { getRessourceChilds } from '../services/server_api';
import { Text, TouchableOpacity, View, StyleSheet  } from 'react-native';
import TabReportStudent from "../containers/student/TabReportStudent";
import Colors from '../constants/Colors';

class StudentReportsScreen extends Component{
    
    constructor(props){
        super(props);
        this.state= {selectedReport:null}
    }

    componentDidMount(){
        getRessourceChilds('student', this.props.student.id, 'reports').then(result=>{
            let reports= result.response;
            this.props.setReports(reports);
            if(reports.length>0){
                this.setState({selectedReport: reports[reports.length-1]})
            }
        });
    }
    
    render(){
        return (
          <View style={styles.container}>
              <View style={styles.reportsTabs}>
                {this.props.reports.map((item,index)=>{
                    return <TouchableOpacity key={index} style={styles.buttonReport}
                                onPress={()=>this.setState({selectedReport:item})}>
                                <Text style={this.state.selectedReport&&item.id==this.state.selectedReport.id ?
                                        styles.buttonTextSelected: styles.buttonText}>
                                        {item.name}
                                </Text>
                            </TouchableOpacity>
                })}
              </View>
              <View style={styles.detailReport}>
                {this.state.selectedReport&&
                    <TabReportStudent
                        report={this.state.selectedReport}
                        student={this.props.student}
                    />
                }
              </View>
        </View>);
    } 
}

const mapStateToProps = state => {
    return {
        student: state.user.data,
        reports: state.student.reports
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setReports: data => {dispatch(setReports(data));}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentReportsScreen);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    reportsTabs:{
        flexDirection:"row"
    },
    buttonReport:{
        flex:1,
        backgroundColor:Colors.tintColor,
        margin:5,
        padding:10,
    },
    buttonText:{
        color:"white",
        textAlign:"center"
    },
    buttonTextSelected:{
        color:"yellow",
        textAlign:"center"
    },
    detailReport:{
        flex:9,
        backgroundColor: '#bbb',
    }
});