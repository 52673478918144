export function getModuleCode(moduleId){
    switch(moduleId){
        //BTS CODE
        case 1:
            return "CULT";
        case 2:
            return "ANGL";
        case 3:
            return "ECOD";
        case 4:
            return "MATH";
        case 5:
            return "ALGO";
        case 6:
            return "INFO";
        case 7:
            return "CMSI";
        case 8:
            return "PPE6";
        case 109:
            return "ATEL";
        case 110:
            return "CUEC";
        case 111:
            return "COMP";
        case 120:
            return "E42E";
        case 121:
            return "PPE6";
        case 122:
            return "MANA";
        default:
            return null;
    }
}