import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../constants/Layout';
import {getRessourceChilds, postToDownLoadFile, getRessource} from "../../services/server_api";
import Colors from '../../constants/Colors';
import Loader from '../../components/Loader';
import { formatDate,formatToStringFR } from '../../helpers/Formats';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class ModalAbsences extends Component {
    constructor(props){
        super(props);
        this.state={
            absences:[],
            loader:false,
            startDate: new Date((new Date()).setMonth((new Date()).getMonth()-2)),
            houresNoJustified: 0,
            houresJustified: 0,
            endDate: new Date()
        };
    }

    componentDidMount(){
        this.refresh();
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.isVisible && this.props.isVisible!=nextProps.isVisible){
            this.refresh();
            return true;
        }

        if(this.state.startDate!=nextState.startDate || this.state.endDate!=nextState.endDate){
            this.state.startDate=nextState.startDate;
            this.state.endDate=nextState.endDate;
            this.refresh();
        }
        return true;
    }

    refresh(){
        let startDate = formatDate(this.state.startDate);
        let endDate=  formatDate(this.state.endDate);
        this.setState({loader:true});
        getRessourceChilds('student', this.props.selectedStudent.id, 'absences/'+startDate+"/"+endDate).then(result=>{
            this.setState({ absences: result.response.absences, 
                            houresNoJustified: result.response.houresNoJustified, 
                            houresJustified: result.response.houresJustified, 
                            loader:false});
        });
    }

    async downloadPresence(){
        let society= this.props.selectedStudent.society ? 
                    (await getRessource("society", this.props.selectedStudent.society)).response:"";
        let postData={
            student_gender:this.props.selectedStudent.gender==1 ? "Monsieur": "Madame",
            student_firstname:this.props.selectedStudent.firstname,
            student_lastname:this.props.selectedStudent.lastname,
            society: society,
            promotion: this.props.promotion,
            current_date: formatToStringFR(new Date()),
            date_start: formatDate(this.state.startDate),
            date_end: formatDate(this.state.endDate),
            date_startFR: formatToStringFR(this.state.startDate),
            date_endFR: formatToStringFR(this.state.endDate),
            houresNoJustified: this.state.houresNoJustified!="aucune" ? this.state.houresNoJustified: 0,
            houresJustified: this.state.houresJustified!="aucune" ? his.state.houresJustified: 0,
        }
        let fileName= this.props.selectedStudent.lastname+"_"+this.props.selectedStudent.firstname+"_presences.docx";
        postToDownLoadFile("student/"+this.props.selectedStudent.id+"/presences/file",fileName,postData);
    }

    async download(){
        let society= this.props.selectedStudent.society ? 
                    (await getRessource("society", this.props.selectedStudent.society)).response:"";
        let absences=  Array.from(this.state.absences);
        
        absences.map((item,key)=>{
            item.isjutified= (item.isjutified==1 ? "oui": "non");
        });
        let postData={
            student_firstname:this.props.selectedStudent.firstname,
            student_lastname:this.props.selectedStudent.lastname,
            society_name: society&&society!==""? society.raison:"",
            date_start: formatToStringFR(this.state.startDate),
            date_end: formatToStringFR(this.state.endDate),
            absences: absences
        }
        let fileName= this.props.selectedStudent.lastname+"_"+this.props.selectedStudent.firstname+"_absences.docx";
        postToDownLoadFile("student/"+this.props.selectedStudent.id+"/absences/file",fileName,postData);
    }

    render() {
        let noJustified= this.state.houresNoJustified!=="aucune"? this.state.houresNoJustified:0
        let total = (this.state.houresJustified!=="aucune"? this.state.houresJustified : 0) + noJustified;
        let pNoJustified= 0;
        if(total>0){
            pNoJustified= Math.round((noJustified/total)*100);
        }
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible && this.props.selectedStudent &&
                <View style={styles.container}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>Absences de {this.props.selectedStudent.lastname} {this.props.selectedStudent.firstname}</Text>
                        <View  style={styles.selectBar}>
                            <Text style={{margin:10}}>du :</Text>
                            <DatePicker
                            selected={this.state.startDate}
                            onChange={(date)=>{
                                this.setState({startDate: date});
                            }}
                            dateFormat="dd/MM/yyyy"/>
                            <Text style={{margin:10}}>au :</Text>
                            <DatePicker
                            selected={this.state.endDate}
                            onChange={(date)=>{
                                this.setState({endDate: date});
                            }}
                            dateFormat="dd/MM/yyyy"/>
                        </View>
                        <View style={styles.itemAbsence}>
                            <Text style={{flex:1}}>Date</Text>
                            <Text style={{flex:1}}>Debut</Text>
                            <Text style={{flex:1}}>Fin</Text>
                            <Text style={{flex:1}}>Description</Text>
                        </View>
                        <ScrollView style={{backgroundColor:'white', height:300}}>
                            { this.state.absences.map((item, index) => (
                               <View key={index} style={styles.itemAbsence}>
                                   <Text style={{flex:1}}>{item.absence_date}</Text>
                                   <Text style={{flex:1}}>{item.absence_start}</Text>
                                   <Text style={{flex:1}}>{item.absence_end}</Text>
                                   <Text style={{flex:1}}>{item.description}</Text>
                               </View>
                            ))}
                        </ScrollView>
                        <View style={{flex:1, flexDirection:'row'}}>
                            <View style={{flex:1}}>
                                <Text>Total absences : {total} heure(s)</Text>
                                <Text>{pNoJustified}% non justifié(s)</Text>
                                <Text>{this.state.houresJustified} heure(s) justifié(s)</Text>
                                <Text>{this.state.houresNoJustified} heure(s) non justifié(s)</Text>
                            </View>
                            <View style={{flex:1}}>
                                <TouchableHighlight 
                                    style={styles.buttonDownload}
                                    onPress={()=>{this.download()}}>
                                    <Text style={{color:"white", textAlign:"center"}}>Télécharger récapitulatif</Text>
                                </TouchableHighlight>
                                <TouchableHighlight 
                                    style={styles.buttonDownload}
                                    onPress={()=>{this.downloadPresence()}}>
                                    <Text style={{color:"white", textAlign:"center"}}>Attestation de présence</Text>
                                </TouchableHighlight>
                            </View>
                        </View>
                        <Loader isVisible={this.state.loader}></Loader>
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>this.props.onHide()}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 200
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 210
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        zIndex: 220,
        alignSelf: 'center',
        padding:20,
        width: Layout.window.width>450?500 : Layout.window.width,
        height: 700,
        backgroundColor:'white'
    },
    selectBar:{
        flex:1,
        flexDirection:"row",
        margin:10,
        zIndex:300,
        alignItems:"center"
    },
    itemAbsence:{
        padding:5,
        flexDirection:"row",
        flex:1
    },
    buttonDownload:{
        padding:5,
        backgroundColor: Colors.tintColor,
        marginTop: 5
    },
    buttonClose:{
        zIndex: 190,
        position: "absolute",
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});