import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import adminReducer from "./reducers/adminReducer";
import userReducer from "./reducers/userReducer";
import studentReducer from "./reducers/studentReducer";
import tutorReducer from "./reducers/tutorReducer";

import thunk from 'redux-thunk';

let reducers = combineReducers({
    admin: adminReducer,
    user: userReducer,
    student: studentReducer,
    tutor: tutorReducer
});

const store = createStore(reducers, compose(applyMiddleware(thunk)));
export default store;
