import * as React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import Icon from "react-native-vector-icons/MaterialIcons";
import Colors from '../constants/Colors';
import { connect } from 'react-redux';
import { disconnect } from '../store/actions/userActions';

function UserDrawerInfo(props) {
    return <View style={styles.container}>
            {//props.avatar ?
                //<Image style={styles.avatar} source={{uri:props.avatar}}/> : />
            }
            <Icon name="account-circle" size={65} style={{color: Colors.tintColor}} />
            <View style={styles.userInfo}>
                <Text style={styles.userText}>{props.data.firstname} {props.data.lastname}</Text>
                <TouchableOpacity style={styles.userDisconnect}
                    onPress={()=>props.disconnect()}    
                >
                    <Text>Se déconnecter</Text>
                </TouchableOpacity>
            </View>
        </View>
}

const mapStateToProps = state => {
    return {
        data: state.user.data,
        avatar: state.user.avatar
    }
};

const mapDispatchToProps = dispatch => {
    return {
        disconnect: data => {dispatch(disconnect(data));}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDrawerInfo);


const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection:"row",
        marginLeft:10,
        marginRight:10,
        borderBottomWidth:1,
        borderBottomColor:"#007AFF"
    },
    avatar: {
       width:65,
       height:65,
       borderRadius: 32.5,
    },
    userInfo: {
        flex: 2,
    },
    userText: {
        flex: 1,
        fontWeight:"bold",
        color: Colors.tintColor,
        padding:10,
    },
    userDisconnect: {
        flex: 1,
        padding:10,
    }
});