import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet} from "react-native";
import {setSelectedPromotion} from "../store/actions/adminActions";
import { connect } from 'react-redux';
import Colors  from '../constants/Colors';

class Promotion extends Component{

    toggleExpand=()=>{
        this.props.setSelectedPromotion(this.props.data.id, this.props.data);
    }

    render() {
        let color= Colors.getPromoColor(this.props.data);
        let fontColor= Colors.getPromoColorFont(this.props.data);
        return (
        <View style={styles.container}>
            <TouchableOpacity style={[styles.row, {backgroundColor: color}]} onPress={()=>this.toggleExpand()}>
                <Text style={[styles.title, styles.font, {color: fontColor}]}>#{this.props.data.id} {this.props.data.name}</Text>
            </TouchableOpacity>
            <View style={styles.parentHr}/>
        </View>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSelectedPromotion: (promotionId, data) => {dispatch(setSelectedPromotion(promotionId, data));}
    };
};

export default connect(null, mapDispatchToProps)(Promotion);

const styles = StyleSheet.create({
    container:{
      margin: 5
    },
    title:{
        fontSize: 14,
        fontWeight:'bold'
    },
    row:{
        flexDirection: 'row',
        justifyContent:'space-between',
        paddingLeft:25,
        paddingRight:18,
        paddingTop:10,
        paddingBottom:10,
        height:70,
        alignItems:'center'
    },
    parentHr:{
        height:1,
        width:'100%'
    },
});
