import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';
import Layout from '../constants/Layout';
import { connect } from 'react-redux';
import TabPlanningProfessor from '../containers/promotion/Plannings/TabPlanningProfessor';

class HomeScreenProfessor extends Component{
  

  render(){
    return (
      <View style={styles.container}>
          <TabPlanningProfessor
              professor={this.props.professor}
              editable={false}
            />
      </View>
    );
  }
}


const mapStateToProps = state => {
  return {
      professor: state.user.data,
  }
};


export default connect(mapStateToProps)(HomeScreenProfessor);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  title:{
    width: Layout.window.width,
    backgroundColor:"white",
    fontSize: 24,
    textAlign:"center"
  },
  listModules:{
    flexDirection: "row",
    backgroundColor: 'white',
    flexWrap:'wrap',
    alignContent:'stretch',
    justifyContent:'center',
    alignItems:'center',
  },
  itemModule:{
      flexDirection: "row",
      width: 200,
      margin:3
  },
  moduleName:{
      paddingLeft:5
  },
});
