import { getCoef } from "./ReportsCoefs";
import { formatToStringFR } from "./Formats";

export function parseDataFoFile(data){
    data.gender= data.gender==2?"Madame": "Monsieur";
    data.society_name= data.society_name? data.society_name: "";
    data.notes= Object.values(data.notes);
    for(let i in data.notes){
        if(data.notes[i].avis){
            data.notes[i].avis= data.notes[i].avis.value;
        }else{
            data.notes[i].avis="";
        }
        for(let j in data.notes[i].notes){
            data.notes[i].notes[j].value= noteValue(data.notes[i].notes[j].value);
            if(j<data.notes[i].notes.length-1){
                data.notes[i].notes[j].value+=" |";
            }
        }
        for(let j in data.notes[i].notesExams){
            data.notes[i].notesExams[j].value= noteValue(data.notes[i].notesExams[j].value);
            if(j<data.notes[i].notesExams.length-1) data.notes[i].notesExams[j].value+=" |"
        }
        data.notes[i].average= data.notes[i].average>0 ? data.notes[i].average: "";
    }
    data.globalAverage= noteValue(parseFloat(data.globalAverage));
    data.examAverage= noteValue(parseFloat(data.examAverage));
    data.avisGlobale= data.avisGlobale ? data.avisGlobale.value : "";
    data.notes= data.notes.sort((a,b)=>a.id-b.id);
    data.council= data.council? formatToStringFR(data.council) : "";
    //console.log(data);
    return data;
}

export function parseNotesByModule(dataNotes){
    //parse par matières
    let listModule={};
    for(var i in dataNotes){
        let codeModule= "module_"+dataNotes[i].module;
        if(!listModule[codeModule]){
            listModule[codeModule]= []
        }
        listModule[codeModule].push(dataNotes[i]);
    }
    return listModule;
}


export function parseStudentsNotes(students, dataNotes){
    let listModule= parseNotesByModule(dataNotes);
    //parse par étudiants
    for(var i in students){
        students[i]["notes"]={};
        for(var code in listModule){
            if(!students[i].notes[code]){
                students[i].notes[code]= { 
                    id: listModule[code][0].module,
                    name: listModule[code][0].name,
                    avis: listModule[code][0].avis ? {id: listModule[code][0].avisId, value:listModule[code][0].avis} : null,
                    notes:[],
                    notesExams:[],
                };
            }
            for(var n in listModule[code]){
                if(listModule[code][n].student===students[i].id){
                    if(listModule[code][n].isexam){
                        students[i].notes[code].notesExams.push(listModule[code][n]);
                    }else{
                        students[i].notes[code].notes.push(listModule[code][n]);
                    }
                }
            }
        }
    }
    return students;
}

export function noteValue(val){
    return val==-2 ? "ABS" : val ==-1 ? "NE" : val;
}

export function getReportType(reportName){
    if(reportName.indexOf("BTS SIO")!==-1)return "SIO";
    else if (reportName.indexOf("BTS CG")!==-1)return "CG";
    else if (reportName.indexOf("DCG")!==-1)return "DCG";
    else return "default";
}

export function studentAddAverages(studentData, reportType){
    let tempGlobalMoyenne= [];
    let tempExamMoyenne= 0;
    let sumCoefExam=0
    //calcule des moyennes
    for(var i in studentData.notes){
        var tempMoyenne= [];
        var isAbsence= false;
        for(var j in studentData.notes[i].notes){
            if(studentData.notes[i].notes[j].value!==-2){
                let tempVal= studentData.notes[i].notes[j].value < 0 ? 0 : studentData.notes[i].notes[j].value; 
                tempMoyenne.push(tempVal);
            }else{
                isAbsence= true;
            }
        }
        if(tempMoyenne.length==0 && isAbsence){
            studentData.notes[i]['average']= -2;
        }else if(tempMoyenne.length==0 && studentData.notes[i].notes.length>0){
            studentData.notes[i]['average']= -1;
        }else if(studentData.notes[i].notes.length>0){
            studentData.notes[i]['average']= parseFloat(average(tempMoyenne).toFixed(2));
        }

        studentData.notes[i]['coef']= getCoef(reportType, studentData.notes[i].id);
        //calule moyenne examen bts
        if(studentData.notes[i].notesExams.length>0){
            for(let n in studentData.notes[i].notesExams){
                sumCoefExam+=studentData.notes[i]['coef'];
                tempExamMoyenne+= studentData.notes[i]['coef']*(studentData.notes[i].notesExams[n].value > 0 ? studentData.notes[i].notesExams[n].value : 0);
            }
        }
        //calcule moyenne avec examen inclus
        let total= 0;
        if(studentData.notes[i]['average']){
            total= studentData.notes[i]['average'];
            if(studentData.notes[i].notesExams.length==1 && studentData.notes[i].notesExams[0].value!==-2){
                total= (total + (studentData.notes[i].notesExams[0].value > 0 ? studentData.notes[i].notesExams[0].value: 0))/2;
            }else if(studentData.notes[i].notesExams.length>1){
                if(studentData.notes[i].notesExams[0].value!==-2 && studentData.notes[i].notesExams[1].value!==-2){
                    total= (total + (studentData.notes[i].notesExams[0].value > 0 ? studentData.notes[i].notesExams[0].value: 0)
                            + (studentData.notes[i].notesExams[1].value > 0 ? studentData.notes[i].notesExams[1].value: 0))/3;
                }else if(studentData.notes[i].notesExams[0].value!==-2){
                    total= (total + (studentData.notes[i].notesExams[0].value > 0 ? studentData.notes[i].notesExams[0].value: 0))/2;
                }else if(studentData.notes[i].notesExams[1].value!==-2){
                    total= (total + (studentData.notes[i].notesExams[1].value > 0 ? studentData.notes[i].notesExams[1].value: 0))/2;
                }
            }
        }else if(studentData.notes[i].notesExams.length==1){
            total= studentData.notes[i].notesExams[0].value;
        }else if(studentData.notes[i].notesExams.length>1){
            total= (studentData.notes[i].notesExams[0].value + studentData.notes[i].notesExams[1].value)/2;
        }
        if(total && total!==-2){
            tempGlobalMoyenne.push( total >=0? total : 0);
        }
        
    }
    //calculeMoyenneExam
    if(sumCoefExam>=0 && reportType!=="default"){
        studentData['examAverage']= (tempExamMoyenne/sumCoefExam).toFixed(2);
    }
    //calcule Moyenne Generale
    if(reportType!=="default"){
        studentData['globalAverage']= globalAverageBTS(studentData.notes);
    }else{
        studentData['globalAverage']= average(tempGlobalMoyenne).toFixed(2);
    }
    return studentData;
}

function globalAverageBTS(notes){
    let tempMoyenne= [];
    for(let i in notes){
        if(notes[i]['average']>=0 && notes[i]['average']!==-2){
            let tempVal= notes[i]['average'] < 0 ? 0 : notes[i]['average'];
            tempMoyenne.push(parseFloat(tempVal));
        }
    }
    return average(tempMoyenne).toFixed(2);
}


function average (numbers) {
    let sum = 0;
    if(numbers.length==0)return -1;
    for (let i = 0; i < numbers.length; i++){
        sum += numbers[i];
    }
    return (sum / numbers.length);
  }