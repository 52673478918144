import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { Feather } from '@expo/vector-icons'; 

export default function NavigationDrawerStructure (props){
    //Structure for the navigatin Drawer
    const toggleDrawer = () => {
      //Props to open/close the drawer
      props.navigationProps.toggleDrawer();
    };
  
    return (
      <View style={{ flexDirection: 'row',alignItems:"center" }}>
        <TouchableOpacity onPress={()=> toggleDrawer()} style={{marginLeft:10}}>
          {/*Donute Button Image */}
          <Feather name="menu" size={24} color="#2f95dc" />
        </TouchableOpacity>
        <Image
            source={require("../assets/images/cfa_insta.png")}
            style={{ width: 220/4, height: 157/4, marginLeft: 5 }}
          />
      </View>
    );
}