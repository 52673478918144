import React, {Component} from 'react';
import { View, StyleSheet, Picker, TouchableOpacity, Text } from 'react-native';
import { getRessources } from '../services/server_api';


export default class SelectRoom extends Component{
    
    constructor(props){
        super(props);
        this.state={floors:[], floorSelected: "",
                    rooms:[], filtredRooms:[], roomSelected: this.props.selectedId ?this.props.selectedId:"" }
    }

    componentDidMount(){
        getRessources("classroom").then(result=>{
            let rooms= result.response;
            let floors= [];
            rooms.map(item=>{
                if(floors.indexOf(item.floor)<0){
                    floors.push(item.floor);
                }
            });
            if(floors.length==0)return;
            let floorSelected= floors[0];
            if(this.props.selectedId){
                let room= rooms.find(item=>{
                   return item.id==this.props.selectedId;
                })
                floorSelected= room.floor;
            }
            let filtredRooms = rooms.filter(item=>{
                return item.floor==floorSelected;
            })
            this.setState({floors:floors, floorSelected: floorSelected,
                            rooms:rooms, filtredRooms:filtredRooms,
                            roomSelected:this.props.selectedId ?this.props.selectedId:""})
        });
    }

    shouldComponentUpdate(nexProps, nextState){
        if(nextState.floorSelected && this.state.floorSelected!=nextState.floorSelected){
            let filtredRooms = nextState.rooms.filter(item=>{
                return item.floor==nextState.floorSelected;
            })
            if(filtredRooms.length>0){
                let roomSelected= filtredRooms[0].id;
                if(this.state.floorSelected=="" && this.props.selectedId){
                    roomSelected= this.props.selectedId;
                }
                this.setState({floorSelected: nextState.floorSelected,
                               filtredRooms:filtredRooms,
                               roomSelected:roomSelected})
            }
        }
        return true;
    }

    render() {
        return (
        <View style={styles.container}>
            <Picker
                selectedValue={this.state.floorSelected}
                style={{flex:1, margin:5, padding:5}}
                onValueChange={(item,index)=>{
                    this.setState({floorSelected: item})
                }}
                >
                {this.state.floors.map((item, index)=>{
                    return <Picker.Item key={index} 
                                 label={"étage "+item} 
                                 value={item} />
                })}
            </Picker>
            <Picker
                selectedValue={this.state.roomSelected}
                style={{flex:2, margin:5, padding:5}}
                onValueChange={(item,index)=>{
                    this.setState({roomSelected: item});
                    this.props.onSelect(item);
                }}
                >
                <Picker.Item label={"Choisissez une salle..."} 
                             value={""} />
                {this.state.filtredRooms.map((item, index)=>{
                    return <Picker.Item key={index} 
                                 label={item.id+": "+item.description+ " ("+item.capacity+" places)"} 
                                 value={item.id} />
                })}
            </Picker>
            <TouchableOpacity>
                <Text></Text>
            </TouchableOpacity>
        </View>
        )
    }
}


const styles = StyleSheet.create({
    container:{
        flexDirection:"row",
        flex:1,
        padding:5,
        margin:5
    },
});