module.exports = function(app) {
    var studentController = require('../controllers/student');
    //get all students
    app.route('/api/student')
    .get(studentController.getAll);
    //get all students representants
    app.route('/api/representant')
    .get(studentController.getAllRepresentants);
    app.route('/api/representant/:id')
    .get(studentController.getAllRepresentants);

    app.route('/api/birthdates')
    .get(studentController.getAllBirthdates);

    //get all contrats of a student
    app.route('/api/student/:id/contrats')
    .get(studentController.getContrats);
    //post for file of a student contrat
    app.route('/api/student/:id/attestation/file')
    .post(studentController.getAttestationFile);
    //post for file of a student contrat
    app.route('/api/student/:id/contrat/:idcontrat/file')
    .post(studentController.getContratFile);
    //post for file of a student contrat
    app.route('/api/student/:id/convention/:idcontrat/file')
    .post(studentController.getConventionFile);
    //get all notes of a student's report
    app.route('/api/student/:id/report/:idReport/notes')
    .get(studentController.getReportNotes);
    //post for file of a student report
    app.route('/api/student/:id/report/:idReport/file')
    .post(studentController.getReportFile);
    //get all reports of a student
    app.route('/api/student/:id/reports')
    .get(studentController.getReports);
    //get abences of a student during a period
    app.route('/api/student/:id/absences/:startdate/:enddate')
    .get(studentController.getAbsences);
    //post data for abences of a student during a period (file)
    app.route('/api/student/:id/absences/file')
    .post(studentController.postAbsencesFile);
    //post data for abences of a student during a period (file)
    app.route('/api/student/:id/presences/file')
    .post(studentController.postPresencesFile);
    //post data for certificat file
    app.route('/api/student/:id/certificat/file')
    .post(studentController.postCertificatFile);

    //get modules inscription of a students
    app.route('/api/student/:id/modules')
    .get(studentController.getModules);

    app.route('/api/student/:id/inscription')
    .put(studentController.updatePromotion);
}
