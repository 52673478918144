import React, {Component} from 'react';
import { View, Text, StyleSheet} from "react-native";
import Colors from '../constants/Colors';
import Typical from 'react-typical'
import { color } from 'react-native-reanimated';
import { getRessources } from '../services/server_api';
import student from '../../server/routes/student';

export default class MessageBar extends Component{

    constructor(props){
        super(props);
        let month=["janvier", "févier", "mars", "avril", "mai", "juin", "juillet",
                   "août", "septembre", "octobre", "novembre", "décembre"]
        this.state={messages:["📚Bonjour, nous sommes le "+
                                new  Date().getDate()+" "+
                                month[new Date().getUTCMonth()]+" "+
                                new  Date().getFullYear()+"📅", 2000,
                              "📚Bonjour, bienvenu au CFA INSTA🎉", 1000]}
    }

    componentDidMount(){
        let messages= this.state.messages;
        let self= this;
        getRessources("birthdates").then(result=>{
            let students= result.response;
            for(let i=0; i< students.length; i++){
                let value= students[i];
                messages.push("🎈Joyeux Anniversaire à "+value.firstname+" "+value.lastname+", "+value.age+" ans aujourd'hui🎈");
                messages.push(150);
            }
            this.setState({messages:messages});
        })
    }

    render(){
        return  <View style={styles.content}>
                    {this.state.messages.length>4 &&
                        <Typical
                            steps={this.state.messages}
                            loop={Infinity}
                            wrapper="div"
                        />
                    }
                </View>
    }
}

const styles = StyleSheet.create({
    content:{
        backgroundColor: "white",
        height: "60px",
        width: "97.2%",
        marginLeft: "5px",
        alignItems: "center",
        color: Colors.tintColor,
        fontSize:"32px",
        fontFamily: "Arial, Helvetica, sans-serif;"
    }
});