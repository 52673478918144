import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet} from "react-native";
import {setPromotions, setPromotionsFiltered, setModalVisibleRename, setSelectedPromotion} from "../store/actions/adminActions";
import { connect } from 'react-redux';
import Colors  from '../constants/Colors';
import { getRessources } from '../services/server_api';
import Layout from '../constants/Layout';
import {USERTYPE} from "../helpers/Users";
import { storeData, FILTERDATE, FILTERTYPE, FILTERLEVEL, retrieveData } from '../services/localStorage';

class PromotionFilter extends Component{

    
    constructor(props){
        super(props);
        this.state= { time: retrieveData(FILTERDATE), 
                      type: retrieveData(FILTERTYPE), 
                      level: retrieveData(FILTERLEVEL)};
    }

    componentDidMount(){
        this.refresh();
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextState.time!== this.state.time || nextState.type!== this.state.type || nextState.level!== this.state.level){
            this.applyFilter(nextState);
        }
        return true;
    }

    refresh(){
        getRessources("promotion").then(result=>{
            if(result.status==200){
                this.props.setPromotions(result.response);
                this.props.setPromotionsFiltered(result.response);
                this.applyFilter(this.state);
            }
        });
    }
    

    filterbyTime(value){
        this.setState({time: value});
        storeData(FILTERDATE, value);
    }

    filterbyType(value){
        this.setState({type: value});
        storeData(FILTERTYPE, value);
    }


    filterbyLevel(value){
        this.setState({level: value});
        storeData(FILTERLEVEL, value);
    }

    applyFilter(state){
        //apply Time;
        let listFiltered= this.props.promotions;
        if(state.time=="now"){
            listFiltered= this.props.promotions.filter(data=>{
                let tempD= new Date(data.dateValEnd);
                return tempD >= new Date();
            });
        }else if(state.time=="old"){
            listFiltered= this.props.promotions.filter(data=>{
                let tempD= new Date(data.dateValEnd);
                return tempD < new Date();
            });
        }
        //apply Type;
        if(state.type==="info"){
            listFiltered= listFiltered.filter(data=>{
                return data.type==="Informatique";
            });
        }else if(state.type==="compta"){
            listFiltered= listFiltered.filter(data=>{
                return data.type==="Comptabilité";
            });
        }
        //apply Level;
        if(state.level==="5"){
            listFiltered= listFiltered.filter(data=>{
                return data.level===3;
            });
        }else  if(state.level==="6"){
            listFiltered= listFiltered.filter(data=>{
                return data.level===2;
            });
        }else  if(state.level==="7"){
            listFiltered= listFiltered.filter(data=>{
                return data.level===1;
            });
        }     
        this.props.setPromotionsFiltered(listFiltered);
    }

    render(){
        return <View style={styles.container}>
            <Text>Filtrer Promotions:</Text>
            <View style={styles.radiogroup}>
                <TouchableOpacity style={this.state.time=="all"? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyTime("all")}><Text>Toutes</Text></TouchableOpacity>
                <TouchableOpacity style={this.state.time=="now"? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyTime("now")}><Text>En cours</Text></TouchableOpacity>
                <TouchableOpacity style={this.state.time=="old"? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyTime("old")}><Text>Anciennes</Text></TouchableOpacity>
            </View>
            <View style={styles.radiogroup}>
                <TouchableOpacity style={this.state.type=="all" ? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyType("all")}><Text>Toutes</Text></TouchableOpacity>
                <TouchableOpacity style={this.state.type=="info" ? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyType("info")}><Text>Info</Text></TouchableOpacity>
                <TouchableOpacity style={this.state.type=="compta" ? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyType("compta")}><Text>Compta</Text></TouchableOpacity>
            </View>
            <Text>Niveau:</Text>
            <View style={styles.radiogroup}>
                <TouchableOpacity style={this.state.level=="all" ? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyLevel("all")}><Text>Tous</Text></TouchableOpacity>
                <TouchableOpacity style={this.state.level=="5" ? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyLevel("5")}><Text>BTS</Text></TouchableOpacity>
                <TouchableOpacity style={this.state.level=="6" ? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyLevel("6")}><Text>BAC+3</Text></TouchableOpacity>
                <TouchableOpacity style={this.state.level=="7" ? styles.radiobuttonSelect: styles.radiobutton}
                    onPress={()=>this.filterbyLevel("7")}><Text>BAC+4/5</Text></TouchableOpacity>
            </View>
            {(this.props.userInfo.type===USERTYPE.SUPERADMIN || this.props.userInfo.type===USERTYPE.PROFESSORADMIN)&&
                <TouchableOpacity style={styles.radiobutton}
                        onPress={()=>{
                        this.props.setSelectedPromotion(null, null);
                        this.props.setModalVisibleRename(true);
                        //
                }}>
                        <Text>Nouvelle promotion</Text>
                </TouchableOpacity>
            }
        </View>
    }
}

const styles = StyleSheet.create({
    container:{
      margin: 5,
      padding: 5,
      backgroundColor: '#FFF',
    },
    radiogroup:{
        flexDirection: "row"
    },
    radiobutton:{
        flex:1,
        margin: 5,
        padding: 5,
        alignItems:"center",
        backgroundColor: Colors.tintColor,
    },
    radiobuttonSelect:{
        flex:1,
        margin: 5,
        padding: 5,
        alignItems:"center",
        borderColor: Colors.tintColor,
        borderWidth:1
    }
});


const mapStateToProps = state => {
    return {
        promotions: state.admin.promotions,
        promotionsFiltered: state.admin.promotionsFiltered,
        userInfo: state.user.data
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setPromotions: data => {dispatch(setPromotions(data));},
        setPromotionsFiltered:  data => {dispatch(setPromotionsFiltered(data));},
        setSelectedPromotion: (promotionId, data) => {dispatch(setSelectedPromotion(promotionId, data));},
        setModalVisibleRename: data => {dispatch(setModalVisibleRename(data));}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionFilter);