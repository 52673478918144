import { postGoogleConnection } from "../../services/server_api";
import { storeData, removeData, TOKENID, USERAVATAR, USERDATA } from "../../services/localStorage";

export const onGoogleConnect = (googleData) => {
    return dispatch => {
        let id_token = googleData.getAuthResponse().id_token;
        let profil = googleData.getBasicProfile();
        let email = profil.getEmail();
        let imageUrl = profil.getImageUrl();
        postGoogleConnection(id_token, email).then(result=>{
            if(result.status==200){
                storeData(TOKENID, result.response.token);
                storeData(USERAVATAR, imageUrl);
                dispatch({
                    type: "SET_USERDATA",
                    value: result.response.user
                });
                dispatch({
                    type: "SET_USERAVATAR",
                    value: imageUrl
                });
            }else{
                alert("Error lors de l'authentification");
            }
        });
    }
};

export const onConnect = (user, token) => {
    return dispatch => {
        storeData(TOKENID, token);
        storeData(USERDATA, JSON.stringify(user));
        storeData(USERAVATAR, avatar);
        let avatar= user.avatar ? user.avatar : null;
        dispatch({
            type: "SET_USERDATA",
            value: user
        });
        dispatch({
            type: "SET_USERAVATAR",
            value: avatar
        });
    }
};

export const disconnect = () => {
    removeData(TOKENID);
    removeData(USERAVATAR);
    return dispatch => {
        dispatch({
            type: "SET_USERDATA",
            value: null
        });
        dispatch({
            type: "SET_USERAVATAR",
            value: null
        });
    }
};