import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet} from 'react-native';
import Layout from '../../../constants/Layout';
import {getRessourceChilds} from "../../../services/server_api";
import Colors from '../../../constants/Colors';
import TabPlanningProfessor from './TabPlanningProfessor';

export default class ModalPlanningProfessor extends Component {
    constructor(props){
        super(props);
        this.state={
            modules:[], 
            createEvent:false,
        };
    }

    componentDidMount(){
        this.refresh(this.props.professor);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.professor!=this.props.professor && nextProps.professor){
            this.refresh(nextProps.professor);
        }
        return true;
    }

    refresh(professor){
        getRessourceChilds('professor', professor.id, 'modules').then(result=>{
                let modules= result.response;
                this.setState({modules: modules});
        });
    }

    render() {
        return (
                <View style={styles.container}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>Planning de {this.props.professor.firstname} {this.props.professor.lastname}</Text>
                        <TabPlanningProfessor 
                            professor={this.props.professor}
                            editable={false}
                        />
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>this.props.onHide()}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'fixed',
        width: Layout.window.width,
        height: Layout.window.height,
        top: 0,
        left: 0,
        zIndex: 300,
        alignItems:"center"
    },
    filter:{
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height,
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.5,
        zIndex: 310
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        position: 'absolute',
        zIndex: 320,
        paddingLeft:10,
        paddingRight:10,
        width: Layout.window.width,
        height: Layout.window.height,
    },
    listModules:{
        flexDirection: "row",
        backgroundColor: 'white',
        flexWrap:'wrap',
        alignContent:'stretch',
        justifyContent:'center',
        alignItems:'center',
    },
    itemModule:{
        flexDirection: "row",
        width: 200,
        margin:3
    },
    moduleName:{
        paddingLeft:5
    },
    buttonCreate:{
        position: "absolute",
        zIndex: 330,
        top:  10,
        left:10,
        backgroundColor: Colors.tintColor,
        padding:10,
    },
    buttonClose:{
        position: "absolute",
        zIndex: 330,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});