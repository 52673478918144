const studentRepositorie = require("../repositories/student");
const defaultRepositorie = require("../repositories/default");

const formatDateFR = require("../repositories/helper").formatDateFR;
const generateFile= require("../services/generateFile");
const generatePDF= require("../services/generatePdf");

exports.getAll=  async function(req, res) {
    res.setHeader('Content-Type', 'application/json');
    try{
        let results = await studentRepositorie.getAll(res.locals.connection);
        res.send(JSON.stringify({"status": 200, "error": null, "response": results}));
    }catch(e){
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}

exports.getAllRepresentants=  async function(req, res) {
    res.setHeader('Content-Type', 'application/json');
    try{
        let results = await studentRepositorie.getAllRepresentants(res.locals.connection, req.params.id);
        res.send(JSON.stringify({"status": 200, "error": null, "response": results}));
    }catch(e){
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}

exports.getAllBirthdates=  async function(req, res) {
    res.setHeader('Content-Type', 'application/json');
    try{
        let results = await studentRepositorie.getAllBirthdates(res.locals.connection);
        res.send(JSON.stringify({"status": 200, "error": null, "response": results}));
    }catch(e){
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}



exports.getContrats= async function(req, res) {
    res.setHeader('Content-Type', 'application/json');
    try{
        let results = await studentRepositorie.getContrats(res.locals.connection, req.params.id);
        res.send(JSON.stringify({"status": 200, "error": null, "response": results}));
    }catch(e){
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
};

exports.getReports= async function(req, res) {
    res.setHeader('Content-Type', 'application/json');
    try{
        let results = await studentRepositorie.getReports(res.locals.connection, req.params.id);
        res.send(JSON.stringify({"status": 200, "error": null, "response": results}));
    }catch(e){
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
};

exports.getReportNotes= async function(req, res) {
    res.setHeader('Content-Type', 'application/json');
    try{
        let notes = await studentRepositorie.getReportNotes(res.locals.connection, req.params.id, req.params.idReport);
        let avisGlobal= await studentRepositorie.getReportAvisGlobal(res.locals.connection, req.params.id, req.params.idReport);
        res.send(JSON.stringify({"status": 200, "error": null, "response": {notes: notes, avisGlobal: avisGlobal}}));
    }catch(e){
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
};


exports.getModules= async function(req, res) {
    res.setHeader('Content-Type', 'application/json');
    try{
        let modules = await studentRepositorie.getModules(res.locals.connection, req.params.id);
        res.send(JSON.stringify({"status": 200, "error": null, "response": modules}));
    }catch(e){
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
};

exports.getReportFile= async function(req, res) {
    try{
        let data = req.body;
        console.log(data);
        console.log(getReportType(data.name))
        let fileName= __dirname+"/../outputs/#"+data.lastname+"_"+data.firstname
                         +"_"+data.name.replace(/ /g,"").replace(/\//g,"")+".docx";
        let inputFile= "REPORT_BTS.docx";
        if(getReportType(data.name)==="default"){
            inputFile=  "REPORT_DEFAULT.docx";
        }
        if(generateFile(__dirname+"/../inputs/"+inputFile, fileName, data)){
            res.download(fileName);
        }
    }catch(e){
        console.log(e);
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}

exports.getContratFile= async function(req, res) {
    try{
        let data = req.body;
        let student = await defaultRepositorie.getById(res.locals.connection, "person", data.student);
        let promotion = await defaultRepositorie.getById(res.locals.connection, "promotion", data.promotion);
        let diplome = await defaultRepositorie.getById(res.locals.connection, "diploma", promotion.diplome);
        data= Object.assign(data, {student: replaceNull(student)});
        data= Object.assign(data, {promotion: replaceNull(promotion)});
        data= Object.assign(data, {diplome: replaceNull(diplome)});
        if(data.type!=3 && data.type!="3"){
            let society = await defaultRepositorie.getById(res.locals.connection, "society", data.society);
            let tutor = await defaultRepositorie.getById(res.locals.connection, "person", data.tutor);
            data= Object.assign(data, {society: replaceNull(society)});
            data= Object.assign(data, {tutor: replaceNull(tutor)});
        }
        data = replaceNull(data);
        let fileName= __dirname+"/../outputs/#"+student.lastname+"_"+student.firstname
                         +"_ContratPromo"+data.promotion+".docx";
        let inputFile= "contratAPP.docx";
        if(data.type==1 || data.type=="1"){
            inputFile= "contratProfes.docx";
        } else if(data.type==3 || data.type=="3"){
            inputFile= "contratInit.docx";
        }
        if(generateFile(__dirname+"/../inputs/"+inputFile, fileName, data)){
            console.log(data);
            res.download(fileName);
        }
    }catch(e){
        console.log(e);
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}

exports.getAttestationFile= async function(req, res) {
    try{
        let data = req.body;
        let student = await defaultRepositorie.getById(res.locals.connection, "person", req.params.id);
        let promotion = await defaultRepositorie.getById(res.locals.connection, "promotion", data.promotion);
        let diplome = await defaultRepositorie.getById(res.locals.connection, "diploma", promotion.diplome);
        data= Object.assign(data, {student: replaceNull(student)});
        data= Object.assign(data, {promotion: replaceNull(promotion)});
        data= Object.assign(data, {diplome: replaceNull(diplome)});
        let fileName= __dirname+"/../outputs/#"+student.lastname+"_"+student.firstname
                         +"_Attestation"+data.promotion+".docx";
        let inputFile= "AttestationResiliation.docx";
        if(generateFile(__dirname+"/../inputs/"+inputFile, fileName, data)){
            console.log(data);
            res.download(fileName);
        }
    }catch(e){
        console.log(e);
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}




exports.getConventionFile= async function(req, res) {
    try{
        let data = req.body;
        let student = await defaultRepositorie.getById(res.locals.connection, "person", data.student);
        let promotion = await defaultRepositorie.getById(res.locals.connection, "promotion", data.promotion);
        let diplome = await defaultRepositorie.getById(res.locals.connection, "diploma", promotion.diplome);
        data= Object.assign(data, {student: replaceNull(student)});
        data= Object.assign(data, {promotion: replaceNull(promotion)});
        data= Object.assign(data, {diplome: replaceNull(diplome)});
        if(data.type!=3 && data.type!="3"){
            let society = await defaultRepositorie.getById(res.locals.connection, "society", data.society);
            let tutor = await defaultRepositorie.getById(res.locals.connection, "person", data.tutor);
            let represAdmin = await defaultRepositorie.getById(res.locals.connection, "person", data.responsableAdministratif);
            represAdmin["gender"]= represAdmin["gender"] == 1 ? "Monsieur" : "Madame";
            data= Object.assign(data, {society: replaceNull(society)});
            data= Object.assign(data, {represAdmin: replaceNull(represAdmin)});
            data= Object.assign(data, {tutor: replaceNull(tutor)});
        }
        data = replaceNull(data);
        let fileName= __dirname+"/../outputs/#"+student.lastname+"_"+student.firstname
                         +"_ConventionPromo"+data.promotion+".docx";
        //fichier input
        let inputFile= "conventions/contratpro.docx";
        if(data.type==2 || data.type=="2"){
            inputFile= "conventions/diplome_"+data.diplome.diplomaCode;
            if(data.dateAnneeDebut2!=""){
                inputFile+="_2ans.docx";
            }else{
                inputFile+="_1an.docx";
            }
        }
        console.log(inputFile);
        if(generateFile(__dirname+"/../inputs/"+inputFile, fileName, data)){
            res.download(fileName);
        }
    }catch(e){
        console.log(e);
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}

function replaceNull(data){
    for(var i in data){
        data[i] = data[i] ? data[i]: "";
        if((i.substr(0,4)=="date" || i=="birthdate")&& data[i]!=""){
            data[i]= formatDateFR(data[i]);
        }else if(i=="address"){
            if(data[i]!=""){
                let temp=  data[i].split(" ");
                if(!isNaN(temp[0])){
                    data["numVoie"]= temp[0];
                    data[i]= data[i].substr(temp[0].length, data[i].length);
                }else{
                    data["numVoie"]=""
                }
            }else{
                data["numVoie"]=""
            }
        }
    }
    return data;
}

exports.postAbsencesFile= async function(req, res) {
    try{
        let data = req.body;
        let fileName= __dirname+"/../outputs/#"+data.student_lastname+"_"+data.student_firstname
                         +"_absences.docx";
        let inputFile= "Recap_Absences_Retards.docx";
        if(generateFile(__dirname+"/../inputs/"+inputFile, fileName, data)){
            res.download(fileName);
        }
    }catch(e){
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}

exports.postPresencesFile= async function(req, res) {
    try{
        let data = req.body;
        let totalHoures= await studentRepositorie.getPromotionHours(res.locals.connection, data.promotion, data.date_start, data.date_end);
        totalHoures= totalHoures - data.houresJustified - data.houresNoJustified;
        data= Object.assign(data, {totalHoures: totalHoures});
        let promotion = await defaultRepositorie.getById(res.locals.connection, "promotion", data.promotion);
        let diplome = await defaultRepositorie.getById(res.locals.connection, "diploma", promotion.diplome);
        data= Object.assign(data, {diplome: diplome.diplomaName});
        let fileName= __dirname+"/../outputs/#"+data.student_lastname+"_"+data.student_firstname
                         +"_presences.docx";
        let inputFile= "Attestation_Presence.docx";
        if(generateFile(__dirname+"/../inputs/"+inputFile, fileName, data)){
            res.download(fileName);
        }
    }catch(e){
        console.log(e);
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}

exports.postCertificatFile= async function(req, res) {
    try{
        let data = req.body;
        let promotion = await defaultRepositorie.getById(res.locals.connection, "promotion", data.contrat.promotion);
        let current_scolaryear = (new Date()).getMonth()<7?
                            ((new Date()).getFullYear()-1)+"/"+(new Date()).getFullYear() :
                            (new Date()).getFullYear()+"/"+((new Date()).getFullYear()+1) ;
        data= Object.assign(data, {current_scolaryear: current_scolaryear});
        promotion.date_start= require("../repositories/helper").formatDateFR(promotion.date_start);
        promotion.date_end= require("../repositories/helper").formatDateFR(promotion.date_end);
        let diplome = await defaultRepositorie.getById(res.locals.connection, "diploma", promotion.diplome);
        data= Object.assign(data, {diplome: diplome});
        data= Object.assign(data, {promotion: promotion});
        data= Object.assign(data, {promotion: promotion});
        let fileName= __dirname+"/../outputs/#"+data.student_lastname+"_"+data.student_firstname
                         +"_certificat.docx";
        let inputFile= "Certif_sco_INIT.docx";
        if(data.contrat.type==1){
            inputFile= "Certif_sco_PRO.docx";
        }else if(data.contrat.type==2){
            inputFile= "Certif_sco_APPR.docx";
        }
        if(generateFile(__dirname+"/../inputs/"+inputFile, fileName, data)){
            res.download(fileName);
        }
    }catch(e){
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
}

exports.getAbsences= async function(req, res) {
    res.setHeader('Content-Type', 'application/json');
    try{
        let absences = await studentRepositorie.getAbsences(res.locals.connection, req.params.id, req.params.startdate, req.params.enddate);
        let houresNoJustified = await studentRepositorie.getAbsencesHours(res.locals.connection, req.params.id, 0, req.params.startdate, req.params.enddate);
        let houresJustified = await studentRepositorie.getAbsencesHours(res.locals.connection, req.params.id, 1, req.params.startdate, req.params.enddate);
        res.send(JSON.stringify({"status": 200, "error": null, "response": {absences: absences,
                                                                            houresNoJustified: houresNoJustified,
                                                                            houresJustified: houresJustified
                                                                        }}));
    }catch(e){
        //throw e;
        res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
    }
};


exports.updatePromotion= async function(req, res) {
  res.setHeader('Content-Type', 'application/json');
  try{
      let modules = await studentRepositorie.updatePromotion(res.locals.connection, req.params.id, req.body);
      res.send(JSON.stringify({"status": 200, "error": null, "response": modules}));
  }catch(e){
      res.send(JSON.stringify({"status": 502, "error": "Internal Error", "response": []}));
  }
}

function getReportType(reportName){
    if(reportName.indexOf("BTS SIO")!==-1)return "SIO";
    else if (reportName.indexOf("BTS CG")!==-1)return "CG";
    else return "default";
}
