import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet} from 'react-native';
import Layout from '../../../constants/Layout';
import Colors from '../../../constants/Colors';
import TabReportNotes from './TabReportNotes';

export default class ModalTabReportNotes extends Component {
    
    render() {
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible &&
                <View style={{flex:1}}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>{this.props.report.name}</Text>
                        <TabReportNotes 
                            report={this.props.report}
                        />
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={this.props.onHide}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'fixed',
        width: "100%",
        height: "90%",
        zIndex: 100,
        left:0,
        top:60
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    title:{
        backgroundColor:"white",
        width: Layout.window.width,
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        zIndex: 140,
        left:0,
        alignSelf: 'center',
        flex:1,
        backgroundColor:"white",
        overflow:"hidden"
    },
    listEvents:{
        
    },
    moduleName:{
        paddingLeft:5
    },
    buttonClose:{
        position: "absolute",
        zIndex: 140,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    },
    buttonClassement:{
        position: "absolute",
        zIndex: 140,
        top:  0,
        left:10,
        margin:10,
        padding:10,
        backgroundColor: Colors.tintColor,
    }
});