import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../constants/Layout';
import {getRessourceChilds, getRessource} from "../../services/server_api";
import Colors from '../../constants/Colors';
import Loader from '../../components/Loader';
import FormContrat from '../contrat/FormContrat';
import TabShareUser from '../promotion/Share/TabShareUser';

export default class ModalFiles extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible&&
                <View style={styles.container}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <TabShareUser
                            user={this.props.user}
                        />
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>this.props.onHide()}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        top: "60",
        left: 0,
        zIndex: 200
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.5,
        zIndex: 210
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        zIndex: 220,
        alignSelf: 'center',
        justifyContent:"center",
        width:  Layout.window.width > 450 ? Layout.window.width/1.5 : Layout.window.width,
        padding:10,
        margin:10,
        backgroundColor:'white'
    },
    buttonClose:{
        zIndex: 190,
        position: "absolute",
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});