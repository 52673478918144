import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../constants/Colors';
import { getRessources } from '../services/server_api';
import ModalProfessor from '../containers/professor/ModalProfessor';

export default class ManageProfessorsScreen extends Component{

    constructor(props){
        super(props);
        this.state={professors:[], filtered:[], value:"", selectedProfessor:null, showModal:false}
    }

    componentDidMount(){
       this.refresh(null);
    }

    refresh(prof){
        getRessources('professor').then(result=>{
            let professors= result.response;
            this.setState({professors: professors, filtered: professors, selectedProfessor: prof});
            this.filtered(this.state.value);
        });
    }

    filtered(value){
        let result= this.state.professors.filter(item=>{
            let name= (item.firstname+" "+item.lastname).toUpperCase();
            return name.indexOf(value.toUpperCase())!==-1;
        });
        this.setState({filtered: result, value: value});
    }

    render(){
        return <View style={styles.container}>
                <View style={styles.searchBar}>
                    <TextInput style={styles.searchInput}
                        value={this.state.value}
                        onChangeText={this.filtered.bind(this)}
                    />
                    <TouchableOpacity style={styles.button}
                        onPress={()=>this.setState({showModal:true, selectedProfessor:null})}>
                        <Text style={styles.textTitleButton}>Nouveau professeur</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView contentContainerStyle={{flexDirection:'row', flexWrap:"wrap", alignContent:"stretch"}}>
                    {this.state.filtered.map((item, index)=>{
                        return <TouchableOpacity key={index} style={styles.itemButton}
                                    onPress={()=>this.setState({showModal:true, selectedProfessor:item})}>
                                <Text style={styles.textTitleButton}>{item.lastname} {item.firstname}</Text>
                            </TouchableOpacity>
                    })}
                </ScrollView>

                {this.state.showModal &&
                    <ModalProfessor data={this.state.selectedProfessor}
                                    isVisible={this.state.showModal}
                                    refresh={(p)=>this.refresh(p)}
                                    onHide={()=>this.setState({showModal:false, selectedProfessor:null})}/>
                }
            </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    searchBar:{
        flexDirection:"row"
    },
    searchInput:{
        flex:8,
        margin:5,
        padding:5,
        borderBottomWidth:1,
        borderBottomColor:Colors.tintColor
    },
    itemButton:{
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    button:{
        flex:1,
        padding:10,
        margin:2,
        backgroundColor:Colors.tintColor,
    },
    textButton:{
        color:"white",
        fontSize:"12"
    },
    textTitleButton:{
        color:"white",
        fontWeight:"bold",
        fontSize:"18"
    }
});