import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet, TextInput, Picker, CheckBox} from "react-native";
import Colors from '../constants/Colors';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";
import { createRessourceChild, deleteRessourceChild, updateRessourceChild, updateRessource, createRessource } from '../services/server_api';
import { parseDataForm } from '../helpers/Planning';
import SelectProfessor from '../containers/professor/SelectProfessor';
import SelectRoom from './SelectRoom';
import SelectDays from './SelectDays';
import { formatDate } from '../helpers/Formats';

export default class FormPlanning extends Component{

    constructor(props){
        super(props);
        if(this.props.selectedEvent){
            this.state={module:this.props.selectedEvent.module,
                        module_date: new Date(this.props.selectedEvent.module_date),
                        time_start: this.props.selectedEvent.time_start,
                        time_end: this.props.selectedEvent.time_end,
                        name: this.props.selectedEvent.name,
                        promotion:  this.props.promotion,
                        professor: this.props.selectedEvent.professor,
                        classroom: this.props.selectedEvent.classroom,
                        id: this.props.selectedEvent.id,
                        recursiveChange:false,
                        frequences:["tous les jours", "hebdomadaire", "mensuel"],
                        frequenceSelected: "",
                        selectedDays:[],
                        recursiveEnd:new Date(this.props.selectedEvent.module_date),
                    };
        }else{
            this.state={module:"",
                module_date: new Date(),
                time_start: "09:00",
                time_end: "12:30",
                name:"",
                promotion:  this.props.promotion,
                professor: null,
                classroom: null,
                id: null,
                recursiveChange:false,
                frequences:["tous les jours", "hebdomadaire", "mensuel"],
                frequenceSelected: "",
                selectedDays:[],
                recursiveEnd:new Date(),
            };
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.selectedEvent && nextProps.selectedEvent!=this.props.selectedEvent){
            nextState.module= nextProps.selectedEvent.module;
            nextState.module_date= nextProps.selectedEvent.module_date;
            nextState.time_start= nextProps.selectedEvent.time_start;
            nextState.time_end= nextProps.selectedEvent.time_end;
            nextState.name= nextProps.selectedEvent.name;
            nextState.classroom= nextProps.selectedEvent.classroom;
            nextState.promotion= nextProps.promotion;
            nextState.id= nextProps.selectedEvent.id;
            nextState.professor= nextProps.selectedEvent.professor;
            nextState.recursiveChange= false;
        }else if(nextProps.promotion && nextProps.promotion!=this.props.promotion){
            nextState.promotion= nextProps.promotion;
        }
        return true;
    }

    save(){
        if(this.state.id && !this.state.recursiveChange){
            updateRessource('planning', this.state.id, parseDataForm(this.state)).then(result=>{
                if(result.status==200){        
                    this.props.refresh();
                    this.props.closeForm();
                }else{
                    alert("Verifier les champs saisies");
                }
            });
        }else if(this.state.id && this.state.recursiveChange){
            let data = parseDataForm(this.state);
            data = Object.assign(data, {oldData: this.props.selectedEvent})
            updateRessource('planning', this.state.id, data).then(result=>{
                if(result.status==200){        
                    this.props.refresh();
                    this.props.closeForm();
                }else{
                    alert("Verifier les champs saisies");
                }
            });
        }else if(!this.state.recursiveChange){
            createRessource('planning', parseDataForm(this.state)).then(result=>{
                if(result.status==200){        
                    this.props.refresh();
                    this.props.closeForm();
                }else{
                    alert("Verifier les champs saisies");
                }
            });
        }else{
            let data = parseDataForm(this.state);
            data = Object.assign(data, {recursive:{
                frequence:this.state.frequenceSelected,
                selectedDays: this.state.selectedDays,
                recursiveEnd: formatDate(this.state.recursiveEnd),
            }})
            createRessource('planning', data).then(result=>{
                if(result.status==200){        
                    this.props.refresh();
                    this.props.closeForm();
                }else{
                    alert("Verifier les champs saisie");
                }
            });
        }
    }

    render() {    
        return (
            <View>
                {this.props.isVisible&&
                <View style={styles.formPlanning}>
                    <View style={{flexDirection:"row", margin:10, flexWrap:"wrap"}}>
                        <Picker selectedValue={this.state.module}
                                style={{flex:1, margin:10, padding:3}}
                                onValueChange={(value, index)=>{this.setState({module:value, name: this.props.modules[index].name})}}>
                             {this.props.modules.map((item, index)=>{
                                return <Picker.Item key={index} label={item.name} value={item.id}/>
                            })}
                             <Picker.Item label={"choisissez un module"} value={""}/>
                        </Picker>
                        <TextInput 
                            onChangeText={(value)=>this.setState({name:value})}
                            style={{flex:1, margin:10, padding:10, 
                                    borderBottomWidth:1, borderColor:Colors.tintColor}}
                            placeholder="Nom du module" 
                            value={this.state.name}/>
                    </View>
                    <View style={{flexDirection:"row", zIndex:1000, margin:10, flexWrap:"wrap"}}>
                        <Text style={{ margin:10, width:60, alignSelf:"center"}}>Date:</Text>
                        <DatePicker
                            className="datepicker-content"
                            selected={new Date(this.state.module_date)}
                            onChange={(date)=>{
                                this.setState({module_date: date});
                            }}
                            dateFormat="dd/MM/yyyy"/>
                            <SelectProfessor selectedId={this.state.professor}
                                     onSelect={(item)=>this.setState({professor: item.id})}/>
                    </View>
                    <View style={{flexDirection:"row", margin:10, height:30}}>
                        <Text style={{margin:10, width:60, alignSelf:"center"}}>Debut:</Text>   
                        <TimePicker
                            value={this.state.time_start}
                            onChange={(value)=>{
                                this.setState({time_start:value})
                            }}
                            disableClock={true}
                            />
                        <Text style={{margin:10, width:60, alignSelf:"center"}}>Fin:</Text> 
                        <TimePicker
                            value={this.state.time_end}
                            onChange={(value)=>{
                                this.setState({time_end:value})
                            }}
                            disableClock={true}
                            />
                    </View>
                    <SelectRoom selectedId={this.state.classroom}
                                onSelect={(item)=>this.setState({classroom: item})}/>
                    
                    {this.props.selectedEvent&&
                    <View style={{flexDirection:"row", margin:10}}>
                        <Text>Appliquer ces changement à toutes les plannifications similaire? </Text>
                        <CheckBox value={this.state.recursiveChange}
                                  onValueChange={(val=>this.setState({recursiveChange:val}))}/>
                    </View>
                    }
                    {!this.props.selectedEvent&&
                    <View style={{flexDirection:"row", margin:10}}>
                        <Text>Ajouter cette évenement recursivement? </Text>
                        <CheckBox value={this.state.recursiveChange}
                                  onValueChange={(val=>this.setState({recursiveChange:val}))}/>
                    </View>
                    }
                    {!this.props.selectedEvent&&this.state.recursiveChange&&
                        <Picker selectedValue={this.state.frequenceSelected}
                            style={{flex:1, margin:10, padding:3}}
                            onValueChange={(value, index)=>{this.setState({frequenceSelected:value})}}>
                            {this.state.frequences.map((item, index)=>{
                                return <Picker.Item key={index} label={item} value={index}/>
                            })}
                            <Picker.Item label={"choisissez une fréquence de répétition"} value={""}/>
                        </Picker>
                    }
                    {!this.props.selectedEvent&&this.state.recursiveChange&& 
                    <View style={{flexDirection:"row", flex:1, justifyContent:"center", margin:10, zIndex:5000}}>
                        {!this.props.selectedEvent&&this.state.recursiveChange&&this.state.frequenceSelected==1&&
                            <SelectDays onSelect={(days)=>this.setState({selectedDays:days})}/>
                        }
                        <Text style={{ margin:10, width:80, alignSelf:"center"}}>Date de fin:</Text>
                        <DatePicker
                            className="datepicker-content"
                            selected={new Date(this.state.recursiveEnd)}
                            onChange={(date)=>{
                                this.setState({recursiveEnd: date});
                            }}
                            dateFormat="dd/MM/yyyy"/>
                    </View>
                    }
                    <View style={{flexDirection:"row", flex:1}}>
                        <TouchableOpacity style={styles.button}
                                            onPress={()=>{this.save()}}>
                            <Text style={styles.buttonText}>Enregistrer</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button}
                                            onPress={()=>{this.props.closeForm()}}>
                            <Text style={styles.buttonText}>Annuler</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                }
            </View>
        );
    }   
}

const styles = StyleSheet.create({
    formPlanning:{
        marginTop:3,
        backgroundColor: 'white',
        flex: 1,
        padding:10,
    },
    checkbox:{
        margin:10,
    },
    button:{
        padding:10,
        backgroundColor: Colors.tintColor,
        marginRight:3,
        flex:1,
    },
    buttonText:{
        flex:1,
        textAlign:"center",
        color: 'white'
    }
});