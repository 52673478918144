import * as React from 'react';
import { Provider} from 'react-redux';
import store from './store/configureStore';
import { NavigationContainer } from '@react-navigation/native';
import DrawerNavigator from './navigation/DrawerNavigator';

export default function App() {
    return (
      <Provider store={store}>
        <NavigationContainer
            documentTitle={{
              formatter: (options, route) =>
                options?
                `${options?.title ?? route?.name}`:
                "Schoolmanager - Login"
            }}>
          <DrawerNavigator />
        </NavigationContainer>
      </Provider>
    );
}