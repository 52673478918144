import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../../constants/Layout';
import { parseStudentsNotes, studentAddAverages, getReportType, studentAddAvis, parseDataFoFile, parseNotesByModule, parseModuleByCode } from '../../../helpers/Reports';
import StudentReport from './StudentReport';
import ModalReportsStudent from '../../student/ModalReportStudent';
import { getRessourceChilds, postToDownLoadFile } from '../../../services/server_api';
import Colors from '../../../constants/Colors';
import EditReportModule from './EditReportModule';
import FormNote from '../../../components/FormNote';
import Loader from '../../../components/Loader';

export default class ModalReportStructure extends Component {
    
    constructor(props){
        super(props);
        this.state={modules:[], notes:[]}
    }

    componentDidMount(){
        getRessourceChilds('promotion', this.props.promotion, 'modules').then(async result=>{
            let module= result.response;
            if(getReportType(this.props.report.name)=="SIO"){
                module.push({id:5, code:'BTSALGO', name:'Algorithmique'});
                module.push({id:7, code:'BTSSIOCMSI', name:'C.M.S.I (E4)'});
                module.push({id:8, code:'BTSSIOPP', name:'P.P (E6)'});
            }
            let notes= (await getRessourceChilds('report', this.props.report.id, 'structure')).response;
            this.setState({ modules: module, notes: notes}); 
        });
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.isVisible&& this.props.isVisible!=nextProps.isVisible ){
            getRessourceChilds('promotion', nextProps.promotion, 'modules').then(async result=>{
                let module= result.response;
                if(getReportType(this.props.report.name)=="SIO"){
                    module.push({id:5, code:'BTSALGO', name:'Algorithmique'});
                    module.push({id:7, code:'BTSSIOCMSI', name:'C.M.S.I (E4)'});
                    module.push({id:8, code:'BTSSIOPP', name:'P.P (E6)'});
                }
                let notes= (await getRessourceChilds('report', nextProps.report.id, 'structure')).response;
                this.setState({ modules: module, notes: notes}); 
            });
        }
        return true;
    }

    refresh(){
        this.setState({loader:true, notes:[]})
        this.props.refresh()
        getRessourceChilds('report', this.props.report.id, 'structure').then(result=>{
            this.setState({notes: result.response, loader:false});
        });
    }

    render() {
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible &&
                <View>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Loader isVisible={this.state.loader}></Loader>
                        <Text style={{backgroundColor:"white", padding:10}}>Nouvelle note:</Text>
                        <FormNote
                            createdForm={true}
                            data={{ isexam: 0,
                                    code: "",
                                    report: this.props.report.id,
                                    promotion: this.props.promotion,
                                    module: this.state.modules.length>0? this.state.modules[0].id : null}} 
                            modules={this.state.modules} 
                            promotion={this.props.promotion}
                            refresh={()=>this.refresh()}
                        ></FormNote>
                        <ScrollView style={{height:Layout.window.height/2}}>
                        {this.state.notes.map((item,key)=>{
                            return <FormNote key={key}
                                    createdForm={false}
                                    data={{ isexam: item.isexam,
                                            code: item.code,
                                            report: this.props.report.id,
                                            promotion: this.props.promotion,
                                            module: item.module}} 
                                    modules={this.state.modules} 
                                    promotion={this.props.promotion}
                                    refresh={()=>this.refresh()} />
                        })}
                        </ScrollView>
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>this.props.onHide()}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height,
        top: 65,
        left: 0,
        zIndex: 140,
    },
    filter:{
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height,
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.5,
        zIndex: 140
    },
    content:{
        zIndex: 145,
        marginTop: 40,
        width: Layout.window.width>450?Layout.window.width/1.5:Layout.window.width,
        alignSelf:"center",
        borderTopWidth:1,
    },
    listEvents:{
        
    },
    moduleName:{
        paddingLeft:5
    },
    buttonClose:{
        position: "absolute",
        zIndex: 150,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    },
    buttonClassement:{
        position: "absolute",
        zIndex: 150,
        top:  0,
        left:10,
        margin:10,
        padding:10,
        backgroundColor: Colors.tintColor,
    }
});