import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';
import Layout from '../constants/Layout';
import {setPromotion} from "../store/actions/studentActions";
import { connect } from 'react-redux';
import {getRessourceChilds} from "../services/server_api";
import TabPlanning from '../containers/promotion/Plannings/TabPlanning';

class HomeScreenStudent extends Component{
    
  componentDidMount(){
    getRessourceChilds('student', this.props.student.id, "contrats").then(result=>{
      if(result.status==200 && result.response.length>0){
        console.log(result.response);
        let currentPromotion= result.response[result.response.length-1].promotion;
        this.props.setPromotion(currentPromotion);
      }
    })
  }

  render(){
    return (
      <View style={styles.container}>
          <TabPlanning
              promotion={this.props.promotion}
              editable={false}
            />
      </View>
    );
  }
}


const mapStateToProps = state => {
  return {
      student: state.user.data,
      promotion: state.student.promotion
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setPromotion: data => {dispatch(setPromotion(data));}
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreenStudent);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  title:{
    width: Layout.window.width,
    backgroundColor:"white",
    fontSize: 24,
    textAlign:"center"
  },
  listModules:{
    flexDirection: "row",
    backgroundColor: 'white',
    flexWrap:'wrap',
    alignContent:'stretch',
    justifyContent:'center',
    alignItems:'center',
  },
  itemModule:{
      flexDirection: "row",
      width: 200,
      margin:3
  },
  moduleName:{
      paddingLeft:5
  },
});
