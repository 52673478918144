import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet} from "react-native";
import Colors from '../../constants/Colors';
import { noteValue } from '../../helpers/Reports';

export default class StudentModuleNotes extends Component{
  
    render() {
        return (
        <View style={styles.container}>
            <Text style={styles.textName}>{this.props.data.name}</Text>
            <View style={styles.Modulenotes}>
                <View style={styles.notesCC}>
                    {Object.entries(this.props.data.notes).map((item,key)=>{
                        let temp=item[1];
                        return <Text key={key} style={styles.notesCCText}>{noteValue(temp.value)}</Text>
                    })}
                </View>
                <View style={styles.moyenneCC}>
                {this.props.data.average>=0 && this.props.data.notes.length>0 &&
                    <Text style={styles.noteMoyenneText}>{this.props.data.average}</Text>
                }
                </View>
                <View style={styles.exam}>
                {Object.entries(this.props.data.notesExams).map((item,key)=>{
                    let temp=item[1];
                    let stylenote= key==0? styles.noteExamFirstText : styles.noteExamText
                    return <Text  key={key} style={stylenote}>{noteValue(temp.value)}</Text>
                })}
                </View>
            </View>
        </View>
        )
    }
}


const styles = StyleSheet.create({
    container:{
      backgroundColor:'white',
      flexDirection:"row",
      flex:2,
      borderBottomColor:Colors.tintColor,
      borderBottomWidth:1,
    },
    textName:{
        flex:1.5,
        justifyContent:"center",
        alignContent:"center",
        padding:10,
        flexWrap:"wrap",
        overflow:"hidden",
        borderRightColor:Colors.tintColor,
        borderRightWidth:1
    },
    Modulenotes:{
        flexDirection: 'row',
        alignItems: 'center',
        flex:4,
    },
    notesCC:{
        flexDirection: 'row',
        flex:6,
        overflow:"auto",
        height:"100%",
        alignItems:"baseline",
        borderRightColor:Colors.tintColor,
        borderRightWidth:1,
    },
    moyenneCC:{
        flex:2,
        borderRightColor:Colors.tintColor,
        borderRightWidth:1,
        height:"100%",
        justifyContent:"center",
        alignItems:"center",
    },
    exam:{
        flexDirection: 'row',
        flex:2,
        height:"100%",
        alignItems:"center",
        justifyContent:"center"
    },
    notesCCText:{
        color: Colors.tintColor,
        borderRightColor:Colors.tintColor,
        borderRightWidth:1,
        padding:10,
    },
    noteMoyenneText:{
        fontWeight:"bold",
    },
    noteExamFirstText:{
        fontWeight:"bold",
        color:"blue",
        padding:3,
        flex:1,
        textAlign:"center"
    },
    noteExamText:{
        fontWeight:"bold",
        color:"blue",
        borderLeftColor:Colors.tintColor,
        borderLeftWidth:1,
        padding:3,
        flex:1,
        textAlign:"center"
    }

});