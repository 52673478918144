import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet, TextInput} from "react-native";
import FormNote from '../../../components/FormNote';


export default class EditReportModule extends Component{

    render() {
        return (
        <View style={styles.container}>
            <Text>{this.props.data.name}</Text>
            {Object.entries(this.props.data.notes).map((item,key)=>{
                let temp=item[1];
                return <FormNote key={key} 
                                data={temp} 
                                modules={this.props.modules} 
                                promotion={this.props.promotion}
                                refresh={this.props.refresh}/>
            })}
        </View>
        )
    }
}


const styles = StyleSheet.create({
    container:{
      margin: 5,
      backgroundColor:'white'
    }
});