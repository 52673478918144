import React, {Component} from 'react';
import { connect } from 'react-redux';
import { getRessourceChilds } from '../services/server_api';
import { Text, TouchableOpacity, View, StyleSheet  } from 'react-native';
import Colors from '../constants/Colors';
import TabReportNotes from '../containers/promotion/Reports/TabReportNotes';

class ProfessorReportsScreen extends Component{
    
    constructor(props){
        super(props);
        this.state= {promotions:[], reports:[], selectedPromotion:null,selectedReport:null}
    }

    componentDidMount(){
        getRessourceChilds('professor', this.props.professor.id, 'promotions').then(result=>{
            let promotions= result.response;
            if(promotions.length>0){
                this.setState({promotions: promotions, selectedPromotion: promotions[promotions.length-1]})
            }
        });
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextState.selectedPromotion&& nextState.selectedPromotion!==this.state.selectedPromotion){
            getRessourceChilds('promotion', nextState.selectedPromotion.id, 'reports').then(result=>{
                let reports= result.response;
                if(reports.length>0){
                    this.setState({reports: reports, selectedReport: reports[reports.length-1]})
                }
            });
        }
        return true;
    }
    
    render(){
        return (
          <View style={styles.container}>
              <View style={styles.reportsTabs}>
                {this.state.promotions.map((item,index)=>{
                    return <TouchableOpacity key={index} style={styles.buttonReport}
                                onPress={()=>this.setState({selectedPromotion:item, selectedReport:null})}>
                                <Text style={this.state.selectedPromotion&&item.id==this.state.selectedPromotion.id ?
                                        styles.buttonTextSelected: styles.buttonText}>
                                        Promotion #{item.id}
                                </Text>
                            </TouchableOpacity>
                })}
              </View>
              <View style={styles.reportsTabs}>
                {this.state.reports.map((item,index)=>{
                    return <TouchableOpacity key={index} style={styles.buttonReport}
                                onPress={()=>{this.setState({selectedReport:null});
                                            this.setState({selectedReport:item});}}>
                                <Text style={this.state.selectedReport&&item.id==this.state.selectedReport.id ?
                                        styles.buttonTextSelected: styles.buttonText}>
                                        {item.name}
                                </Text>
                            </TouchableOpacity>
                })}
              </View>
              <View style={styles.detailReport}>
                  {this.state.selectedReport&&
                    <TabReportNotes 
                        report={this.state.selectedReport}
                        professor={this.props.professor}
                    />
                  }
              </View>
        </View>);
    } 
}

const mapStateToProps = state => {
    return {
        professor: state.user.data
    }
};

export default connect(mapStateToProps)(ProfessorReportsScreen);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#ccc',
    },
    reportsTabs:{
        flexDirection:"row"
    },
    buttonReport:{
        flex:1,
        backgroundColor:Colors.tintColor,
        margin:5,
        padding:10,
    },
    buttonText:{
        color:"white",
        textAlign:"center"
    },
    buttonTextSelected:{
        color:"yellow",
        textAlign:"center"
    },
    detailReport:{
        flex:1,
        backgroundColor: 'white',
        margin:5
    }
});