import * as React from 'react';
import { ScrollView, TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import Colors from "../constants/Colors";

export default function SelectableList(props) {
  return (
   <ScrollView style={{flex:1}}>
       {
        props.data.map((item,index)=>{
            return <TouchableOpacity
                        key={index}
                        onPress={()=>props.onSelect(item)} 
                            style={isSelected(item, props.selectedItems)? styles.selected: styles.item}>
                        <Text
                            style={isSelected(item, props.selectedItems)? styles.selectedText: styles.itemText}
                        >{item.lastname} {item.firstname}
                        </Text>
                    </TouchableOpacity>
           })
       }
   </ScrollView>
  );
}

function isSelected(item, tables){
    for(var i in tables){
        if(tables[i]==item.id){
            return true;
        }
    }
    return false;
}

const styles = StyleSheet.create({
    selected: {
        backgroundColor: Colors.tintColor,
        padding:5
    },
    item: {
        padding:5
    },
    selectedText:{
        color:"white"
    },
    itemText:{

    }
  })