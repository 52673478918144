import React, {Component} from 'react';
import { View, StyleSheet, Text, TextInput, ScrollView, TouchableOpacity } from 'react-native';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/CustomDatePicker.css";
import { getRessources, updateRessource, deleteRessource, postToDownLoadFile } from '../../services/server_api';
import ArrayPicker from '../../components/ArrayPicker';
import Colors from '../../constants/Colors';
import FormContratSociety from './FormContratSociety';
import FormContratStudent from './FormContratStudent';
import FormContratTutor from './FormContratTutor';
import FormContratRepresentant from './FormContratRepresentant';
import FormContratResponsableAdministratif from './FormContratResponsableAdministratif';
import { formatDate, formatStringDate } from '../../helpers/Formats';
import Layout from '../../constants/Layout';
const inscrStatus= [{id:1, name:"candidat"}, {id:2, name:"admis"}, {id:3, name:"refus"},
                    {id:4, name:"en attente"}, {id:5, name:"abandon"}, {id:6, name:"inscrit"},
                    {id:7, name:"en maintien"}]


export default class FormContrat extends Component{

    constructor(props){
        super(props);
        if(props.data!==null){
            this.state= Object.assign({}, props.data)
            this.state.dateinscr= new Date(props.data.dateinscr);
            Object.assign(this.state, {contratType:[]});
        }else{
            this.state={contratType:[], 
                dateinscr: new Date(),
                type: 3,
                status: 1
            }
        }
    }


    componentDidMount(){
        getRessources("type_inscription").then(result=>{
            this.setState({contratType:result.response});
        })
    }

    save(){
        let postData= Object.assign({}, this.state);
        postData.dateinscr      = postData.dateinscr!=""? formatStringDate(postData.dateinscr):formatDate(new Date());
        postData.dateEmbauche   = postData.dateEmbauche!=""? formatStringDate(postData.dateEmbauche):formatDate(new Date());
        postData.dateExecution  = postData.dateExecution!=""? formatStringDate(postData.dateExecution):formatDate(new Date());
        postData.dateAvenant    = postData.dateAvenant!=""? formatStringDate(postData.dateAvenant):null;
        postData.dateFinContrat = postData.dateFinContrat!=""? formatStringDate(postData.dateFinContrat):formatDate(new Date());
        postData.dateAnneeDebut1= postData.dateAnneeDebut1!=""? formatStringDate(postData.dateAnneeDebut1):formatDate(new Date());
        postData.dateAnneeFin1  = postData.dateAnneeFin1!=""? formatStringDate(postData.dateAnneeFin1):formatDate(new Date());
        postData.dateAnneeDebut2= postData.dateAnneeDebut2!=""? formatStringDate(postData.dateAnneeDebut2):null;
        postData.dateAnneeFin2  = postData.dateAnneeFin2!=""? formatStringDate(postData.dateAnneeFin2):null;
        postData.dateSignatureContrat= postData.dateSignatureContrat!=""? formatStringDate(postData.dateSignatureContrat):formatDate(new Date());
        postData.dateRuptureContrat= postData.dateRuptureContrat!=""? formatStringDate(postData.dateRuptureContrat):null;

        delete postData.contratType;
        delete postData.id;
        updateRessource("inscription", this.state.id, postData).then(result=>{
            console.log("UpdateInscription", result);
            if(result.status==200){
                this.props.refresh(this.state);
            }else{
                alert("Erreur lors de l'enregistrement.");
            }
        });
    }

    print(){
        let postData= Object.assign({}, this.state);
        delete postData.contratType;
        let url= `student/${postData.student}/contrat/${postData.id}/file`;
        let fileName= "#"+this.props.student.lastname+"_"+this.props.student.firstname
                         +"_ContratPromo"+postData.promotion+".docx";
        postToDownLoadFile(url, fileName, postData);
    }

    printConvention(){
        let postData= Object.assign({}, this.state);
        delete postData.contratType;
        let url= `student/${postData.student}/convention/${postData.id}/file`;
        let fileName= "#"+this.props.student.lastname+"_"+this.props.student.firstname
                         +"_ConventionPromo"+postData.promotion+".docx";
        postToDownLoadFile(url, fileName, postData);
    }
    
    delete(){
        if(confirm("Etes-vous sure de désinscrire cette étudiant de cette promotion?")){
            deleteRessource('inscription',this.state.id).then(result=>{
                if(result.status==200){
                    this.props.refresh(null);
                    this.props.onHide();
                }
            });
        }
    }

    render(){
        let currentInscrDate= this.state.dateinscr? new Date(this.state.dateinscr): new Date();
        return <View style={styles.container}>
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Date d'inscription:</Text>
                        <DatePicker
                             className="datepicker-content"
                             selected={currentInscrDate}
                             showYearDropdown={true}
                             onChange={(date)=>{
                                 this.setState({dateinscr: date});
                             }}
                             dateFormat="dd/MM/yyyy"
                        />
                        {Layout.window.width > 450 &&
                            <Text style={styles.fieldText}>Type:</Text>
                        }
                        <ArrayPicker 
                            selectedValue={this.state.type}
                            data={this.state.contratType}
                            onValueChange={(value, index)=>{
                                this.setState({type: value});
                            }}
                        />
                        {Layout.window.width > 450 &&
                            <Text style={styles.fieldText}>Status:</Text>
                        }
                        <ArrayPicker 
                            selectedValue={this.state.status}
                            data={inscrStatus}
                            onValueChange={(value, index)=>{
                                this.setState({status: value});
                            }}
                        />
                    </View>
                    <ScrollView style={{height:Layout.window.height/1.5, marginTop:10}}> 
                            {this.state.type!=3&&this.props.data&&
                                <View>
                                    <Text style={styles.title}>L'Employeur</Text>
                                    <FormContratSociety inscription={this.state}
                                                        data={this.props.societyData} 
                                                        refresh={this.props.refresh}/>
                                    
                                    {this.props.societyData &&
                                        <Text style={styles.title}>Le Tuteur</Text>
                                    }
                                    {this.props.societyData &&
                                        <FormContratTutor inscription={this.state}
                                                          society={this.props.societyData}
                                                          data={this.props.tutorData}
                                                          refresh={this.props.refresh}/>
                                    }
                                    {this.props.societyData &&
                                        <Text style={styles.title}>Le Responsable administratif</Text>
                                    }
                                    {this.props.societyData &&
                                        <FormContratResponsableAdministratif
                                            inscription={this.state}
                                            society={this.props.societyData}
                                            data={this.props.responsableAdministratifData}
                                            refresh={this.props.refresh}
                                        />
                                    }
                                    {Layout.window.width > 450 &&
                                        <Text style={styles.title}>Le Représentant ou Signataire</Text>
                                    }
                                    {Layout.window.width > 450 &&
                                        <FormContratRepresentant
                                            inscription={this.state}
                                            society={this.props.societyData}
                                            data={this.props.representantData}
                                            refresh={this.props.refresh} />
                                    }
                                    <Text style={styles.title}>Le Salarié</Text>
                                    <FormContratStudent data={this.props.data} 
                                        onChangeData={(data)=>{this.setState(Object.assign(this.state, data))}}/>
                                </View>
                            }
                    </ScrollView>
                    <View style={styles.rowPart}>
                        <TouchableOpacity style={styles.button}
                            onPress={()=>this.save()}>
                            <Text style={styles.buttonText}>Enregistrer</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button}
                            onPress={()=>this.print()}>
                            <Text style={styles.buttonText}>Imprimer</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button}
                            onPress={()=>this.printConvention()}>
                            <Text style={styles.buttonText}>Convention</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button}
                            onPress={()=>this.delete()}>
                            <Text style={styles.buttonText}>Supprimer</Text>
                        </TouchableOpacity>
                    </View>
                </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
        width: "auto"
    },
    rowPart:{
        flexDirection: Layout.window.width > 450 ? 'row': 'column',
        zIndex:10,
        marginTop:15,
    },
    title:{
        backgroundColor:Colors.tintColor,
        color:"white",
        padding:10,
        marginTop:10,
        textAlign:"center",
        fontWeight:"bold"
    },
    fieldText:{
        color: Colors.tintColor,
        padding:5,
        marginLeft:30,
    },
    textInput:{
        flex:1,
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        color:Colors.tintColor,
        padding:5,
        marginLeft:5
    },
    textInputLittle:{
        width: 50,
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        color:Colors.tintColor,
        padding:5,
        marginLeft:5
    },
    button:{
        flex:1,
        alignItems: Layout.window.width > 450 ? "center": "stretch",
        padding:10,
        backgroundColor: Colors.tintColor,
        margin:2,
    },
    buttonText:{
        color:"white"
    }
});