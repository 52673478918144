import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Picker } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import { getRessources, getRessource, getRessourceChilds, deleteRessource } from '../services/server_api';
import Building from '../containers/planning/Building';
import { formatDate } from '../helpers/Formats';
import PromotionEvents from '../containers/planning/PromotionEvents';
import FormPlanning from '../components/FormPlanning';
import ModalPromotionAbsences from '../containers/promotion/Plannings/ModalPromotionAbsences';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../components/CustomDatePicker.css";
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';

export default class ManagePlanningScreen extends Component{

    constructor(props){
        super(props);
        this.state={events:[], dateSelected: new Date(), promosEvents:[],
                    showModalAbsences: false,
                    dataAbsences: [],
                    modules:[],
                    selectedEvent: null,
                    selectedPromotion: "",
                    editEvent:false,
                    listStudents: [],
                    promotions:[]
                };
    }

    componentDidMount(){
        getRessources("promotion").then(result=>{
            this.setState({promotions:result.response});
        });
        this.refresh(this.state.dateSelected);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextState.selectedPromotion!="" && nextState.selectedPromotion!=this.state.selectedPromotion){
            getRessourceChilds("promotion", nextState.selectedPromotion, "modules").then(result=>{
                this.setState({modules:result.response});
            });
        }
        return true;
    }

    refresh(dateSelected){
        getRessource("plannings", formatDate(dateSelected)).then(result=>{
            let events= result.response;
            let promosEvents={};
            events.map(item=>{
                if(!promosEvents[item.promotion]){
                    promosEvents[item.promotion]={events:[]};
                }
                promosEvents[item.promotion].events.push(item);
            });
            this.setState({events:events, promosEvents: promosEvents, editEvent:false});
        })
    }

    getAbsences(selectedEvent){
        getRessourceChilds('planning', selectedEvent.id, 'absences').then(async result=>{
            if(result.status==200){
                let students= (await getRessourceChilds("promotion", selectedEvent.promotion, "module/"+selectedEvent.module+"/students")).response;
                if(students.length>0){
                    this.setState({showModalAbsences: true, 
                                dataAbsences: result.response,
                                selectedEvent: selectedEvent,
                                selectedPromotion: selectedEvent.promotion,
                                listStudents: students});
                }else{
                    students= (await getRessourceChilds("promotion", selectedEvent.promotion, "students")).response;
                    this.setState({showModalAbsences: true, 
                               dataAbsences: result.response,
                               selectedEvent: selectedEvent,
                               selectedPromotion: selectedEvent.promotion,
                               listStudents: students});
                }
            }
        });
    }

    hideModale(){
        this.setState({showModalAbsences: false, dataAbsences: []});
    }

    editEvent(item){
        getRessourceChilds("promotion", item.promotion, "modules").then(result=>{
            this.setState({editEvent:true, selectedEvent:item,  selectedPromotion: item.promotion, modules:result.response});
        });
    }

    openCreate(){
        this.setState({editEvent:true, selectedEvent:null,  selectedPromotion: "", modules:[]});
    }

    deleteEvent(item){
        if(confirm("Etes-vous sur de vouloir supprimer la plannification de ce module?")){
            deleteRessource("planning", item.id).then(result=>{
                this.refresh(this.state.dateSelected);
            });
        }
    }

    render(){
        return <View style={styles.container}>
                {Layout.window.width>450&&
                    <Building events={this.state.events}/>
                }
                 <View style={styles.content}>
                    <View style={{position:"fixed", flexDirection:"row", zIndex:300, top: 65, left:25}}>
                        <DatePicker
                            className="datepicker-content"
                            selected={this.state.dateSelected}
                            showYearDropdown={true}
                            onChange={(date)=>{
                                this.setState({dateSelected: date});
                                this.refresh(date);
                            }}
                            dateFormat="dd/MM/yyyy"/>
                        <TouchableOpacity style={{backgroundColor:Colors.tintColor, marginHorizontal:10}}
                            onPress={()=>this.openCreate()}>
                            <Text style={{color:"orange", padding:10, fontWeight:"bold"}}>Plannifier Module</Text>
                        </TouchableOpacity>
                    </View> 
                 {Object.entries(this.state.promosEvents).map((item,key)=>{
                    let events=item[1].events;
                    let promotion= item[0];
                    return <PromotionEvents key={key} 
                                promotion={promotion}
                                events={events}
                                onSelectModule={()=>{}}
                                editEvent={this.editEvent.bind(this)}
                                deleteEvent={this.deleteEvent.bind(this)}
                                showModalAbsences={this.getAbsences.bind(this)}
                                editable={true}
                            />
                 })}
                 </View>
                 {this.state.editEvent&&
                    <View style={{position:"absolute", alignItems:"center", width:"100%"}}>
                        <View style={{borderWidth:1, backgroundColor:"white", width:Layout.window.width>800?"50%":"98%"}}>
                            {this.state.selectedEvent&&
                                <Text style={styles.title}>Promotion #{this.state.selectedEvent.promotion}</Text>
                            }
                            {!this.state.selectedEvent&&
                                 <Picker selectedValue={this.state.selectedPromotion}
                                        style={{flex:1, margin:10, padding:3}}
                                        onValueChange={(value, index)=>{this.setState({selectedPromotion:value})}}>
                                    <Picker.Item label={"choisissez une promotion"} value={""}/>
                                    {this.state.promotions.map((item, index)=>{
                                        return <Picker.Item key={item.id} label={"#"+item.id+" - "+item.name} value={item.id}/>
                                    })}
                                </Picker>
                            }
                            <FormPlanning
                                isVisible= {this.state.editEvent}
                                selectedEvent= {this.state.selectedEvent}
                                promotion={this.state.selectedPromotion}
                                modules={this.state.modules}
                                refresh={()=>this.refresh(this.state.dateSelected)}
                                closeForm={()=>{
                                    this.setState({editEvent: false, selectedEvent:null, selectedPromotion:""});
                                }}
                            />
                        </View>
                    </View>
                }
                {this.state.showModalAbsences&&
                    <ModalPromotionAbsences isVisible={this.state.showModalAbsences}
                        selectedEvent= {this.state.selectedEvent}
                        students={this.state.listStudents}
                        dataAbsences={this.state.dataAbsences}
                        promotion={this.state.selectedEvent.promotion}
                        selectedDate={this.state.selectedDate}
                        onHide={this.hideModale.bind(this)}
                    />
                }

               </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
        flexDirection:"row"
    },
    content:{
        flex:1,
        flexDirection:"row",
        flexWrap:"wrap",
        overflow:"auto",
        marginTop: Layout.window.width>450? 0: 50
    },
    title:{
        textAlign:"center",
        fontSize:24,
        color:Colors.tintColor,
    }

});