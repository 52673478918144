import React, {Component} from 'react';
import {Text, TouchableOpacity, View, StyleSheet, TextInput, Picker, Image } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import Layout from '../../constants/Layout';
import Colors from '../../constants/Colors';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import '../../assets/css/froala_style.css';
import FroalaEditor from 'react-froala-wysiwyg';
import { updateRessource, createRessource } from '../../services/server_api';
import SelectColors from '../../components/SelectColors';


export default class ModalDiplome extends Component {

    constructor(props){
        super(props);
        if(this.props.selected){
            this.state= Object.assign({}, this.props.selected);
            if(this.state.image){
                var buffer = Buffer.from(this.state.image, "utf8");
                this.state.image= buffer.toString();
            }
        }else{
            this.state={diplomaName:"", type:0, typeDiplome:"informatique", description:""};
        }
    }

    openImagePickerAsync = async () => {
        let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync();
        if (permissionResult.granted === false) {
            alert("Permission to access camera roll is required!");
            return;
        }

        let pickerResult = await ImagePicker.launchImageLibraryAsync();
        if(!pickerResult.cancelled){
            this.setState({image:pickerResult.uri});
        }
    }

    save(){
        if(this.state.id){
            updateRessource("diploma", this.state.id, this.state).then(result=>{
                if(result.status===200){
                    alert("Enregistrement éffectué");
                    this.props.onHide();
                    this.props.refresh();
                }
            })
        }else{
            createRessource("diploma", this.state).then(result=>{
                if(result.status===200){
                    alert("Enregistrement éffectué");
                    this.props.onHide();
                    this.props.refresh();
                }
            });
        }

    }
  
    render() {
        return (
        <View style={this.props.isVisible ? styles.container : {display:"none"}}>
        {
            this.props.isVisible&&
            <View style={{flex:1, flexDirection:"row", justifyContent:"center"}}>
                <View style={styles.filter}>
                </View>
                <View style={styles.content}>
                    <Text style={styles.title}>Diplome</Text>
                    <View style={{flexDirection:"row"}}>
                        <View>
                            <Image 
                                style={{width: 200, height: 200}}
                                source={{uri: this.state.image ? this.state.image : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"}} />
                        </View>
                        <View style={{flex:1}}>
                            <TextInput  placeholder="Name"
                                        style={styles.inputText}
                                        value={this.state.diplomaName}
                                        onChangeText={(text)=>this.setState({diplomaName:text})}
                                />
                            <Picker
                                style={styles.select}
                                selectedValue={this.state.typeDiplome}
                                onValueChange={(itemValue, itemIndex) =>
                                    this.setState({typeDiplome: itemValue})
                                }>
                                <Picker.Item label="Informatique" value="informatique" />
                                <Picker.Item label="Comptabilité" value="comptabilite" />
                            </Picker>
                            <Picker
                                style={styles.select}
                                selectedValue={this.state.type}
                                onValueChange={(itemValue, itemIndex) =>
                                    this.setState({type: itemValue})
                                }>
                                {tabTypes.map((item,index)=>{
                                    return <Picker.Item key={index} label={item} value={index} />
                                })}
                            </Picker>
                            <TouchableOpacity onPress={this.openImagePickerAsync.bind(this)} 
                                style={styles.button}>
                                <Text style={styles.buttonText}>Choisir une photo</Text>
                            </TouchableOpacity>
                            <SelectColors 
                                value={this.state.color}
                                onChange={(itemValue, itemIndex) =>
                                    this.setState({color: itemValue})
                                }/>
                        </View>
                    </View>
                    <FroalaEditor
                        model={this.state.description}
                        onModelChange={(model)=>{this.setState({description:model})}}
                    />
                    <View style={{flexDirection:"row"}}>
                        <TouchableOpacity onPress={()=>{this.save()}} 
                            style={[styles.button, {flex:1}]}>
                            <Text style={styles.buttonText}>Valider</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={()=>{this.props.onHide();}} 
                             style={[styles.button, {flex:1}]}>
                            <Text style={styles.buttonText}>Annuler</Text>
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity 
                        style={styles.buttonClose}
                        onPress={()=>{this.props.onHide();}}>
                        <Text>X</Text>
                    </TouchableOpacity>
                </View>
            </View>
        }
        </View>
        );
    }
}

const tabTypes=["Selectionner un type", "Initiale/Alternance", "Alternance", "Formation continue"];

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    content:{
        zIndex: 120,
        margin:10,
        backgroundColor: 'white',
        flex: Layout.window.width < 800 ? 0.9: 0.5,
    },
    title:{
        textAlign: "center",
        fontWeight:"bold",
        padding:10,
        color:Colors.tintColor,
        fontSize:24,
    },
    inputText:{
        margin: "5px",
        padding: "5px",
        borderBottomColor: "blue",
        borderBottomWidth:1
    },
    select:{
        margin: "5px",
        padding: "5px",
    },
    button:{
        margin: "5px",
        padding: "5px",
        backgroundColor:"blue",
        textAlign: "center"
    },
    buttonText:{
        color:"white"
    },
    buttonClose:{
        position: "absolute",
        zIndex: 180,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});