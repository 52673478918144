import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../../constants/Colors';
import FormRepresentant from '../../components/FormRepresentant';
import SelectRepresentant from './SelectRepresentant';
import { updateRessource } from '../../services/server_api';

export default class FormContratRepresentant extends Component{

    constructor(props){
        super(props);
        this.state={showSelectForm:false, showCreateForm:false}
    }

    onSelectRepresentant(representant){
        if(confirm("Etes-vous sur de lier ce representant à ce contrat ?")){
            updateRessource("inscription", this.props.inscription.id, {representant:representant.id}).then(result=>{
                let inscription= Object.assign({}, this.props.inscription);
                inscription.representant= representant.id;
                this.props.refresh(inscription);
            });
        }
    }

    unlinkRepresentant(){
        if(confirm("Etes-vous sur de délier ce representant à ce contrat ?")){
            updateRessource("inscription", this.props.inscription.id, {representant:null}).then(result=>{
                let inscription= Object.assign({}, this.props.inscription);
                inscription.representant= null;
                this.props.refresh(inscription);
            });
        }
    }

    onCreateRepresentant(representantId){
        updateRessource("inscription", this.props.inscription.id, {representant:representantId}).then(result=>{
            let inscription= Object.assign({}, this.props.inscription);
            inscription.representant= representantId;
            this.props.refresh(inscription);
        });
    }

    render(){
        return <View style={styles.container}>
                    {this.props.data&&
                        <View style={styles.rowPart}>
                            <FormRepresentant data={this.props.data}/>
                            <TouchableOpacity style={styles.button}
                                                onPress={()=>this.unlinkRepresentant()}>
                                <Text style={styles.text}>Délier ce représentant</Text>
                            </TouchableOpacity>
                        </View>
                    }

                    {!this.props.data && this.state.showSelectForm &&
                        <SelectRepresentant society={this.props.society}
                                     onHide={()=>this.setState({showSelectForm:false})}
                                     onSelect={this.onSelectRepresentant.bind(this)}/>
                    }

                    {!this.props.data && this.state.showCreateForm &&
                        <FormRepresentant data={null}
                                    society={this.props.society}
                                    onHide={()=>this.setState({showCreateForm:false})}
                                    onCreate={this.onCreateRepresentant.bind(this)}
                        />
                    }

                    {!this.props.data && !this.state.showSelectForm &&  !this.state.showCreateForm &&
                        <View style={styles.rowPart}>
                            <TouchableOpacity style={styles.button}
                                                onPress={()=>this.setState({showSelectForm:true})}>
                                <Text style={styles.text}>Lier à un représentant existant</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button}
                                                onPress={()=>this.setState({showCreateForm:true})}>
                                <Text style={styles.text}>Créer un nouveau représentant</Text>
                            </TouchableOpacity>
                        </View>
                    }
                </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    title:{
        flex:1,
        backgroundColor:Colors.tintColor,
        color:"white",
        padding:10,
        marginTop:10,
        textAlign:"center",
        fontWeight:"bold"
    },
    rowPart:{
        flexDirection:'row',
        flex:1,
        justifyContent:"space-evenly",
        marginTop:10,
    },
    button:{
        padding:10,
        backgroundColor:Colors.tintColor,
        margin:5,
        flex:1,
        justifyContent:"center",
        alignItems:"center",
        alignSelf:"stretch"
    },
    text:{
        color:"white",
    }
});