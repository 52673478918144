import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../../constants/Colors';
import FormResponsableAdministratif from '../../components/FormResponsableAdministratif';
import SelectResponsableAdministratif from '../society/SelectResponsableAdministratif';
import { updateRessource } from '../../services/server_api';
import Layout from '../../constants/Layout';

export default class FormContratResponsableAdministratif extends Component{

    constructor(props){
        super(props);
        this.state={showSelectForm:false, showCreateForm:false}
    }

    onSelectResponsableAdministratif(responsableAdministratif){
        if(confirm("Etes-vous sur de lier ce responsable administratif à ce contrat ?")){
            updateRessource("inscription", this.props.inscription.id, {responsableAdministratif:responsableAdministratif.id}).then(result=>{
                let inscription= Object.assign({}, this.props.inscription);
                inscription.responsableAdministratif= responsableAdministratif.id;
                this.props.refresh(inscription);
            });
        }
    }

    unlinkResponsableAdministratif(){
        if(confirm("Etes-vous sur de délier ce responsable administratif à ce contrat ?")){
            updateRessource("inscription", this.props.inscription.id, {responsableAdministratif:null}).then(result=>{
                let inscription= Object.assign({}, this.props.inscription);
                inscription.responsableAdministratif= null;
                this.props.refresh(inscription);
            });
        }
    }

    onCreateResponsableAdministratif(responsableAdministratifId){
        updateRessource("inscription", this.props.inscription.id, {responsableAdministratif:responsableAdministratifId}).then(result=>{
            let inscription= Object.assign({}, this.props.inscription);
            inscription.responsableAdministratif= responsableAdministratifId;
            this.props.refresh(inscription);
        });
    }

    render(){
        
        return <View style={styles.container}>
                    {this.props.data&&
                        <View style={styles.rowPart}>
                            <FormResponsableAdministratif data={this.props.data}/>
                            {Layout.window.width > 450 &&
                                <TouchableOpacity style={styles.button}
                                                    onPress={()=>this.unlinkResponsableAdministratif()}>
                                    <Text style={styles.text}>Délier ce responsable administratif</Text>
                                </TouchableOpacity>
                            }
                        </View>
                    }

                    {!this.props.data && this.state.showSelectForm && Layout.window.width > 450 &&
                        <SelectResponsableAdministratif society={this.props.society}
                                     onHide={()=>this.setState({showSelectForm:false})}
                                     onSelect={this.onSelectResponsableAdministratif.bind(this)}/>
                    }

                    {!this.props.data && this.state.showCreateForm && Layout.window.width > 450 &&
                        <FormResponsableAdministratif data={null}
                                    society={this.props.society}
                                    onHide={()=>this.setState({showCreateForm:false})}
                                    onCreate={this.onCreateResponsableAdministratif.bind(this)}
                        />
                    }

                    {!this.props.data && !this.state.showSelectForm &&  !this.state.showCreateForm && Layout.window.width > 450 &&
                        <View style={styles.rowPart}>
                            <TouchableOpacity style={styles.button}
                                                onPress={()=>this.setState({showSelectForm:true})}>
                                <Text style={styles.text}>Lier à un responsable administratif existant</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button}
                                                onPress={()=>this.setState({showCreateForm:true})}>
                                <Text style={styles.text}>Créer un nouvel responsable administratif</Text>
                            </TouchableOpacity>
                        </View>
                    }
                </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    title:{
        flex:1,
        backgroundColor:Colors.tintColor,
        color:"white",
        padding:10,
        marginTop:10,
        textAlign:"center",
        fontWeight:"bold"
    },
    rowPart:{
        flexDirection:'row',
        flex:1,
        justifyContent:"space-evenly",
        marginTop:10,
    },
    button:{
        padding:10,
        backgroundColor:Colors.tintColor,
        margin:5,
        flex:1,
        justifyContent:"center",
        alignItems:"center",
        alignSelf:"stretch"
    },
    text:{
        color:"white",
    }
});