export function parseData(data){
    let copy= Object.assign({}, data);
    copy.cp             = isNumber(data.cp)?data.cp: 0;
    copy.employeurPrive = isNumber(data.employeurPrive)?data.employeurPrive: 0;
    copy.effectif       = isNumber(data.effectif)?data.effectif: 0;
    copy.codeIDCC       = isNumber(data.codeIDCC)?data.codeIDCC: 0;
    copy.typeEmployeur  = isNumber(data.typeEmployeur)?data.typeEmployeur: 0;
    copy.employeurSpecifique = isNumber(data.employeurSpecifique)?data.employeurSpecifique: 0;
    copy.adhesionChomage     = isNumber(data.adhesionChomage)?data.adhesionChomage: 0;
    copy.employeurParticulier= isNumber(data.employeurParticulier)?data.employeurParticulier: 0;
    
    copy.address    = isNotNull(data.address)? data.address: "";
    copy.siret      = isNotNull(data.siret)? data.siret: "";
    copy.codeNAF    = isNotNull(data.codeNAF)? data.codeNAF: "";
    copy.conventionCollective= isNotNull(data.conventionCollective)? data.conventionCollective: "";
    copy.email      = isNotNull(data.email)? data.email: "";
    copy.prevoyance = isNotNull(data.prevoyance)? data.prevoyance: "";
    copy.tel        = isNotNull(data.tel)? data.tel: "";
    copy.urssaf     = isNotNull(data.urssaf)? data.urssaf: "";
    return copy;
}

function isNotNull(value){
    return value!=null && value!="null";
}

function isNumber(value){
    return !isNaN(value) && value!=null && value!="";
}


/*
address: "28 rue Jacques Ibert"
adhesionChomage: 0
city: "LEVALLOIS Perret"
codeIDCC: null
codeNAF: null
conventionCollective: null
cp: 92309
effectif: null
email: null
employeurParticulier: 0
employeurPrive: 1
employeurSpecifique: null
id: 263
prevoyance: null
raison: "OPEN"
siret: null
tel: null
typeEmployeur: null
urssaf: null
*/