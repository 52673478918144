import React, {Component} from 'react';
import {Text, TouchableOpacity,TouchableHighlight, View, StyleSheet, TextInput, Picker,Image } from 'react-native';
import Layout from '../../constants/Layout';
import {setModalVisibleStudent, setSelectedPromotion, setSelectedStudent, setPromotions} from "../../store/actions/adminActions";
import { connect } from 'react-redux';
import Colors from '../../constants/Colors';
import { getRessources, updateRessourceChild } from '../../services/server_api';

class ChangePromotion extends Component {

  constructor(props){
    super(props);
    this.state = { promotion : [],
                   selectedPromotion : 193,
                   promotionName : null}
    console.log("props",this.props);
    console.log("state",this.state);
  }

  save(){
    updateRessourceChild("student", this.props.selectedStudent.id, "inscription",
                         { oldPromotion: this.props.studentPromotion, newPromotion: this.state.selectedPromotion}
                         ).then(result => {
      if (result.status == 200){
        alert("Enregistrement effectué");
      } else {
        alert("Enregistrement non effectué");
      }
    });
    this.props.onHide()
  }

  componentDidMount(){
    getRessources("promotion").then(result => {
      this.setState({ promotion: result.response });
    });
    this.state.promotion.map((item, index) => {
      console.log(this.props.selectedPromotion);
      console.log(item.id);
      if ( this.props.selectedPromotion == item.id) this.setState({selectedPromotion: item.id, promotionName: item.name});
    });
  }

  render() {
    return(
      <View style={this.props.isVisible ? styles.container : {display:"none"}}>
      { this.props.isVisible && this.props.selectedStudent &&
        <View style={{flex:1, flexDirection:"row", justifyContent:"center"}}>
          <View style={styles.filter}></View>
          <View style={styles.content}>
            <Text>Choisir une nouvelle promotion</Text>
            <Picker
                style={styles.select}
                value={this.state.selectedPromotion}
                label={this.state.selectedPromotion+" - "+this.state.promotionName}
                onValueChange={(itemValue, itemIndex) =>
                    this.setState({selectedPromotion: itemValue, promotionName: itemIndex})
                }>
                {this.state.promotion.map((item,index)=>{
                    return <Picker.Item key={index} label={item.id+" - "+item.name} value={item.id} />
                })}
            </Picker>
            <TouchableHighlight
                style={styles.buttonDownload}
                onPress={this.save.bind(this)}>
                <Text style={{color:"white", textAlign:"center"}}>valider</Text>
            </TouchableHighlight>
            <TouchableHighlight
                style={styles.buttonClose}
                onPress={()=>this.props.onHide()}>
                <Text>X</Text>
            </TouchableHighlight>
          </View>
        </View>
      }
      </View>
    )
  }

}

const mapStateToProps = state => {
  return {
      studentPromotion: state.admin.selectedPromotion,
  }
};

export default connect(mapStateToProps)(ChangePromotion);


const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height-70,
        top: 0,
        left: 0,
        zIndex: 230,
    },
    filter:{
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height,
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.5,
        zIndex: 220
    },
    content:{
        zIndex: 240,
        alignSelf: 'center',
        padding:20,
        left:'-25%',
        width: Layout.window.width>450?500 : Layout.window.width,
        backgroundColor:'white'
    },
    select:{
        margin: "5px",
        padding: "5px",
    },
    buttonDownload:{
        padding:5,
        backgroundColor: Colors.tintColor,
        marginTop: 5
    },
    buttonClose:{
        zIndex: 190,
        position: "absolute",
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});
