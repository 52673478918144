const helper= require("./helper");

exports.getAll = function(connection, ressourceName){
    try {
       return new Promise((resolve,reject) => {
           let request = "select * from "+ressourceName+";";
           connection.query(request, function (err, result) {
               if (err) reject(err);
               resolve(result);
           });
       });
    } catch (e) {
       throw e;
    }
};

exports.getById = function(connection, ressourceName, id){
    try {
       return new Promise((resolve,reject) => {
           if(id==null||id=="null")reject("bad id "+ressourceName);
           let request = "select * from "+ressourceName+" where id="+id+";";
           connection.query(request, function (err, result) {
                if (err) reject(err);
                if(result && result.length==1){
                    resolve(result[0]);
                }else{
                    reject("Error no or multiple id!")
                }
           });
       });
    } catch (e) {
        throw e;
    }
};

exports.add = function(connection, ressourceName, data){
    try {
        //console.log(data);
       return new Promise((resolve, reject) => {
           let request = "insert into  "+ressourceName+" "+helper.fieldsToInstert(data);
           //console.log(request);
           connection.query(request, function (err, result) {
               if (err) reject(err);
               resolve(result);
           });
       });
    } catch (e) {
        throw e;
    }
};

exports.update = function(connection, ressourceName, id, data){
    try {
       return new Promise((resolve, reject) => {
           let request = "update "+ressourceName+" set "+helper.fieldsToUpdate(data)+" where id="+id+";";
           connection.query(request, function (err, result) {
               console.log(err)
               if (err) reject(err);
               resolve(result);
           });
       });
    } catch (e) {
        throw e;
    }
};

exports.delete = function(connection, ressourceName, id){
    try {
       return new Promise((resolve, reject) => {
           let request = "delete from "+ressourceName+" where id="+id+";";
           connection.query(request, function (err, result) {
               if (err) reject(err);
               resolve(result);
           });
       });
    } catch (e) {
        throw e;
    }
};