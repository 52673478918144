let INITIAL_STATE = {
    promotions:[],
    promotionsFiltered:[],
    showModalPromoStudents: false,
    selectedPromotion: null,
    selectedPromotionData: null,
    listStudents: [],
    listStudentsWaiting: [],
    listStudentsRefusal: [],
    listStudentsAdmited: [],
    listStudentsDropOut: [],
    listStudentsCandidates: [],
    listStudentsMaintained: [],
    showModalStudent: false,
    showModalInscription: false,
    showModalStudent: false,
    selectedStudent: null,
    showModalPromotionPlaning: false,
    showModalPromotionReports: false,
    showModalInscriptionModule: false,
    showModalCandidate:false,
    dataNotes: [],
    showModalRename: false,
};

let adminReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_PROMOTIONS":
            return {...state, promotions: action.value};
        case "SET_PROMOTIONS_FILTERED":
            return {...state, promotionsFiltered: action.value};
        case "SET_MODAL_PROMOTIONSTUDENTS":
            return {...state, showModalPromoStudents: action.value};
        case "SET_SELECTED_PROMOTION":
            return {...state, selectedPromotion: action.value};
        case "SET_SELECTED_PROMOTIONDATA":
            return {...state, selectedPromotionData: action.value};
        case "SET_LIST_STUDENTS":
            return {...state, 
                listStudentsMaintained: action.value.filter(data=> data.status===7),
                listStudents: action.value.filter(data=> data.status===6),
                listStudentsDropOut: action.value.filter(data=> data.status===5),
                listStudentsWaiting: action.value.filter(data=> data.status===4),
                listStudentsRefusal: action.value.filter(data=> data.status===3),
                listStudentsAdmited: action.value.filter(data=> data.status===2),
                listStudentsCandidates: action.value.filter(data=> data.status===1),
            };
        case "SET_MODAL_STUDENT":
            return {...state, showModalStudent: action.value};
        case "SET_SELECTED_STUDENT":
            return {...state, selectedStudent: action.value};
        case "SET_MODAL_PROMOTIONPLANING":
            return {...state, showModalPromotionPlaning: action.value};
        case "SET_MODAL_PROMOTIONREPORTS":
            return {...state, showModalPromotionReports: action.value};
        case "SET_MODAL_INSCRIPTION":
                return {...state, showModalInscription: action.value};
        case "SET_MODAL_INSCRIPTIONMODULE":
                return {...state, showModalInscriptionModule: action.value};
        case "SET_MODAL_CANDIDATE":
            return {...state, showModalCandidate: action.value};
        case "SET_DATANOTES":
            return {...state, dataNotes: action.value};
        case "SET_RENAME":
            return {...state, showModalRename: action.value};
        case "SET_OLD_NAME":
            return {...state, promotionName: action.value};
        default:
            return state;
    }
};

export default adminReducer;
