import React, {Component} from 'react';
import {Text, TouchableOpacity, View, StyleSheet, TextInput } from 'react-native';
import Layout from '../../constants/Layout';
import Colors from '../../constants/Colors';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import '../../assets/css/froala_style.css';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { getRessource } from '../../services/server_api';

export default class ModalModule extends Component {
    constructor(props){
        super(props);
        this.state={code:"", name:"", description:""}
    }

    componentDidMount(){
        getRessource("module", this.props.module).then(result=>{
            this.setState(result.response);
        });
    }
   
    render() {
        return (
        <View style={this.props.isVisible ? styles.container : {display:"none"}}>
        {
            this.props.isVisible&&
            <View style={{flex:1, flexDirection:"row", justifyContent:"center", top:0}}>
                <View style={styles.filter}>
                </View>
                <View style={styles.content}>
                    <Text style={styles.title}>{this.state.name}</Text>
                    <View style={{overflow:"auto", flex:1, padding:10}}>
                        {this.state.description && this.state.description!=="" ?
                            <FroalaEditorView
                                model={this.state.description}
                            />
                            :
                            <Text style={{flex:1, textAlign:"center"}}>Fiche pédagogique à venir ... </Text>
                        }
                    </View>
                    <TouchableOpacity  style={styles.button}>
                        <Text style={styles.text} onPress={()=>{this.props.onHide();}}>Fermer</Text>
                    </TouchableOpacity>
                </View>
            </View>
        }
        </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "auto",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    content:{
        zIndex: 320,
        margin:10,
        backgroundColor: 'white',
        flex: Layout.window.width < 800 ? 0.9: 0.5,
    },
    title:{
        textAlign: "center",
        fontWeight:"bold",
        padding:10,
        color:Colors.tintColor,
        fontSize:24,
    },
    headerInputs:{
        flexDirection:"row",
    },
    codeInput:{
        flex:1,
        backgroundColor:"white",
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        padding:10,
        margin:5
    },
    nameInput:{
        flex:9,
        backgroundColor:"white",
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        padding:10,
        margin:5
    },
    button:{
        backgroundColor: Colors.tintColor,
        padding:3,
        margin:5,
        justifyContent:"center"
    },
    text:{
        textAlign: "center",
        fontWeight:"bold",
        color:"white",
        padding:10,
    },
    buttonClose:{
        position: "absolute",
        zIndex: 380,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});