import React, {Component} from 'react';
import {Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import Layout from '../../constants/Layout';
import Colors from '../../constants/Colors';
import FormTutor from '../../components/FormTutor';
import ModalPlanningProfessor from '../promotion/Plannings/ModalPlanningProfessor';
import FormPlanningProfessor from "../../components/FormPlanningProfessor";

export default class ModalProfessor extends Component {
    constructor(props){
        super(props);
        this.state={showPlanning:false, showFormPlanning:false};
    }

    render() {
        return (
        <View style={this.props.isVisible ? styles.container : {display:"none"}}>
        {
            this.props.isVisible&&
            <View style={{flex:1, flexDirection:"row", justifyContent:"center"}}>
                <View style={styles.filter}>
                </View>
                <View style={styles.content}>
                    {this.props.data&&
                        <Text style={styles.title}>{this.props.data.lastname} {this.props.data.firstname}</Text>
                    }
                    <FormTutor data={this.props.data}
                            onHide={()=>this.props.onHide()}  
                            refresh={this.props.refresh}
                            type={3}
                            onCreate={(data)=>{this.props.onHide();this.props.refresh(data)}}
                    />
                    {this.state.showFormPlanning&&
                        <FormPlanningProfessor isVisible={this.state.showFormPlanning}
                                professor={this.props.data.id}
                                closeForm={()=>this.setState({showFormPlanning:false})}/>
                    }
                    {this.state.showPlanning&&
                        <ModalPlanningProfessor 
                            onHide={()=>this.setState({showPlanning:false})}
                            professor={this.props.data}/>
                    }
                    {!this.state.showFormPlanning&&
                        <TouchableOpacity  style={styles.button}>
                            <Text style={styles.text} onPress={()=>this.setState({showFormPlanning:true})}>Attribuer module</Text>
                        </TouchableOpacity>
                    }
                    <TouchableOpacity  style={styles.button}>
                        <Text style={styles.text} onPress={()=>this.setState({showPlanning:true})}>Planning</Text>
                    </TouchableOpacity>
                    <TouchableOpacity  style={styles.button}>
                        <Text style={styles.text} onPress={()=>{this.props.onHide();}}>Fermer</Text>
                    </TouchableOpacity>
                </View>
            </View>
        }
        </View>
        );
    }
}


const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    content:{
        zIndex: 120,
        margin:10,
        backgroundColor: 'white',
        flex: Layout.window.width < 800 ? 0.9: 0.5,
    },
    title:{
        textAlign: "center",
        fontWeight:"bold",
        padding:10,
        color:Colors.tintColor,
        fontSize:24,
    },
    headerInputs:{
        flexDirection:"row",
    },
    
    button:{
        backgroundColor: Colors.tintColor,
        padding:3,
        margin:5,
        justifyContent:"center"
    },
    text:{
        textAlign: "center",
        fontWeight:"bold",
        color:"white",
        padding:10,
    },
    buttonClose:{
        position: "absolute",
        zIndex: 180,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    },
    buttonEmploye:{
        fontWeight:"bold",
        color:Colors.tintColor,
        padding:3
    },
    buttonStudent:{
        fontWeight:"bold",
        padding:3
    }
});