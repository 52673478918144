import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView, TouchableOpacity} from 'react-native';
import Layout from '../../../constants/Layout';
import {setModalVisiblePromotionReports, setDataNotes} from "../../../store/actions/adminActions";
import { connect } from 'react-redux';
import {getRessourceChilds, getRessource, createRessource, updateRessource} from "../../../services/server_api";
import Colors from '../../../constants/Colors';
import Icon from "react-native-vector-icons/FontAwesome";
import ModalReportsNotes from './ModalReportNotes';
import Loader from '../../../components/Loader';
import ModalTabReportNotes from './ModalTabReportNotes';

class ModalReports extends Component {
    constructor(props){
        super(props);
        this.state={
            reports:[],
            loader:false,
            selectedReport: null,
            showModalNotes: false,
            showModalTabNotes: false,
            dataNotes:[],
        };
    }
    
    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.selectedPromotion!=this.props.selectedPromotion && nextProps.selectedPromotion){
            this.setState({loader:true});
            getRessourceChilds('promotion', nextProps.selectedPromotion, 'reports').then(result=>{
                this.setState({reports: result.response, loader:false});
            });
        }
        return true;
    }
    
    openModalNotes(report){
        this.setState({showModalNotes:true, selectedReport:report})
    }

    hideModalNotes(){
        this.setState({showModalNotes: false, selectedReport:null})
    }

    refreshReport(){
        this.setState({loader:true});
        getRessourceChilds('promotion', this.props.selectedPromotion, 'reports').then(result=>{
            let report = this.state.selectedReport ? result.response.find(item=>{
                return item.id==this.state.selectedReport.id;
            }) : null;
            this.setState({reports: result.response, loader:false, selectedReport: report});
        });
    }

    addReport(){
        let periode= this.state.reports.length+1;
        let year= (new Date()).getFullYear();
        if((new Date()).getMonth()<8){
            year= (year-1)+"/"+ (year)
        }else{
            year= (year)+"/"+ (year+1)
        }
        let name = prompt('Entrez un nom pour le bulletin :');
        if(name!=null&&name!=""){
            let data={
                name:name,
                year:year,
                periode: periode,
                promotion: this.props.selectedPromotion
            }
            createRessource("report", data).then(result=>{
                if(result.status==200){
                    this.refreshReport();
                }
            });
        }
    }

    editReport(item){
        let name = prompt('Entrez un nom pour le bulletin :', item.name);
        if(name!=null&&name!=""){
            let data = Object.assign({}, item);
            data.name= name;
            delete data.formationName;
            delete data.council;
            updateRessource("report", data.id, data).then(result=>{
                if(result.status==200){
                    this.refreshReport();
                }
            });
        }
    }

    render() {
        return (
            <View style={this.props.showModalPromotionReports ? styles.container : {display:"none"}}>
            {
                this.props.showModalPromotionReports &&
                <View style={styles.container}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>Bulletins de notes promotion #{this.props.selectedPromotion}</Text>
                        <TouchableOpacity style={{flexDirection:"row", padding:10}}
                            onPress={()=>this.addReport()}>
                            <Icon name="plus" size={12} />
                            <Text>Nouveau bulletin</Text>
                        </TouchableOpacity>
                        
                        <ScrollView style={{backgroundColor:'white', flex: 1}}
                            contentContainerStyle={{
                                alignContent:'stretch',
                            }}>
                            { this.state.reports.map((item, index) => (
                                <View  key={index} style={{flexDirection:"row"}}>
                                    <TouchableHighlight style={styles.itemReport}
                                        onPress={()=>{this.openModalNotes(item)}}
                                        >
                                        <Text style={{fontSize:18, color:"white"}}>{item.name}</Text>
                                    </TouchableHighlight>
                                    <TouchableHighlight style={styles.buttonReport}
                                        onPress={()=>{this.setState({showModalTabNotes:true, selectedReport:item})}}
                                        >
                                        <Text style={{fontSize:18, color:"white"}}>Mode Prof</Text>
                                    </TouchableHighlight>
                                    <TouchableHighlight style={styles.buttonReport}
                                        onPress={()=>this.editReport(item)}>
                                        <Icon name="edit" size={24} style={{color:"white"}}/>
                                    </TouchableHighlight>
                                </View>
                            ))}
                        </ScrollView>
                        {this.state.selectedReport && this.state.showModalNotes &&
                            <ModalReportsNotes isVisible={this.state.showModalNotes} 
                                promotion={this.props.selectedPromotion}
                                report={this.state.selectedReport}
                                students={this.props.listStudents}
                                onHide={this.hideModalNotes.bind(this)}
                                refresh={()=>this.refreshReport()}
                            />
                        }

                    {this.state.selectedReport && this.state.showModalTabNotes &&
                            <ModalTabReportNotes isVisible={this.state.showModalTabNotes} 
                                promotion={this.props.selectedPromotion}
                                report={this.state.selectedReport}
                                onHide={()=>this.setState({showModalTabNotes:false})}
                            />
                        }
                        <Loader isVisible={this.state.loader}></Loader>
                        
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>this.props.setModalVisiblePromotionReports(false)}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalPromotionReports: state.admin.showModalPromotionReports,
        selectedPromotion: state.admin.selectedPromotion,
        listStudents: state.admin.listStudents,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setModalVisiblePromotionReports: data => {dispatch(setModalVisiblePromotionReports(data));},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalReports);

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        zIndex: 120,
        alignSelf: 'center',
        justifyContent:"center",
        padding:20,
        backgroundColor:'white'
    },
    itemReport:{
        padding:5,
        flex:1,
        backgroundColor: Colors.tintColor,
        margin: 5
    },
    buttonReport:{
        padding:5,
        backgroundColor: Colors.tintColor,
        margin: 5
    },
    buttonClose:{
        zIndex: -1,
        position: "absolute",
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});