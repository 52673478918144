import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet} from 'react-native';
import Layout from '../../../constants/Layout';
import {setModalVisiblePromotionPlanning} from "../../../store/actions/adminActions";
import { connect } from 'react-redux';
import {getRessourceChilds} from "../../../services/server_api";
import Colors from '../../../constants/Colors';
import FormPlanning from '../../../components/FormPlanning';
import TabPlanning from './TabPlanning';

class ModalPlanning extends Component {
    constructor(props){
        super(props);
        this.state={
            modules:[], 
            createEvent:false,
        };
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.selectedPromotion!=this.props.selectedPromotion && !isNaN(nextProps.selectedPromotion)){
            this.refresh(nextProps.selectedPromotion);
        }
        return true;
    }

    refresh(promotion){
        if(!promotion)return;
        getRessourceChilds('promotion', promotion, 'modules').then(result2=>{
                let modules= result2.response;
                this.setState({modules: modules});
        });
    }

    render() {
        return (
            <View style={this.props.showModalPromotionPlaning ? styles.container : {display:"none"}}>
            {
                this.props.showModalPromotionPlaning && this.props.selectedPromotion &&
                <View style={styles.container}>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                         {this.props.selectedPromotion&&
                            <TabPlanning
                                promotion={this.props.selectedPromotion}
                                editable={true}
                                listStudents={this.props.listStudents}
                            />
                         }
                        {Layout.window.width>450&&
                            <TouchableHighlight 
                                style={styles.buttonCreate}
                                onPress={()=>{this.setState({createEvent:true})}}>
                                <Text>Plannifier module</Text>
                            </TouchableHighlight>
                        }
                        {this.state.createEvent&&
                            <View style={{position:"absolute", alignItems:"center", width:"100%"}}>
                                <View style={{borderWidth:1, backgroundColor:"white", width:Layout.window.width>800?"50%":"98%"}}>
                                    <Text style={{fontSize:24, textAlign:"center"}}>Promotion #{this.props.selectedPromotion}</Text>
                                    <FormPlanning
                                        selectedEvent= {null}
                                        isVisible={this.state.createEvent}
                                        promotion={this.props.selectedPromotion}
                                        modules={this.state.modules}
                                        refresh={()=>this.refresh(this.props.selectedPromotion)}
                                        closeForm={()=>{
                                            this.setState({createEvent:false});
                                        }}
                                    />
                                </View>
                            </View>
                        }   
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>this.props.setModalVisiblePromotionPlanning(false)}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalPromotionPlaning: state.admin.showModalPromotionPlaning,
        selectedPromotion: state.admin.selectedPromotion,
        listStudents: state.admin.listStudents
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setModalVisiblePromotionPlanning: data => {dispatch(setModalVisiblePromotionPlanning(data));},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPlanning);

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    title:{
        width: Layout.window.width,
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        zIndex: 120,
        paddingLeft:10,
        paddingRight:10,
        flex:1
    },
    listModules:{
        flexDirection: "row",
        backgroundColor: 'white',
        flexWrap:'wrap',
        alignContent:'stretch',
        justifyContent:'center',
        alignItems:'center',
    },
    itemModule:{
        flexDirection: "row",
        width: 200,
        margin:3
    },
    moduleName:{
        paddingLeft:5
    },
    buttonCreate:{
        position: "absolute",
        zIndex: 130,
        top:  10,
        left:10,
        backgroundColor: Colors.tintColor,
        padding:10,
    },
    buttonClose:{
        position: "absolute",
        zIndex: 130,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});