import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet, TextInput, Picker, CheckBox} from "react-native";
import Colors from '../constants/Colors';
import { createRessourceChild, deleteRessourceChild, updateRessourceChild } from '../services/server_api';
import Layout from '../constants/Layout';
export default class FormNote extends Component{

    constructor(props){
        super(props);
        this.state={isexam: this.props.data.isexam,
                    code: this.props.data.code,
                    report: this.props.data.report,
                    promotion: this.props.promotion,
                    module: this.props.data.module
                };
       
    }   

    save(){
        let postData= Object.assign({}, {oldData: this.props.data});
        postData= Object.assign(postData, {data: this.state});
        postData.data.module= postData.data.module ? postData.data.module : this.props.data.module;
        if(!this.props.createdForm){
            updateRessourceChild("report", this.state.report, "note", postData).then(result=>{
                this.props.refresh();
            })
        }else{
            createRessourceChild("report", this.state.report, "note", postData).then(result=>{
                this.props.refresh();
            })
        }
    }

    delete(){
        let postData= Object.assign({}, {oldData: this.props.data});
        postData= Object.assign(postData, {data: this.state});
        if(confirm("Etes-vous sûre de supprimer cette note?")){
            deleteRessourceChild("report", this.state.report, "note", postData).then(result=>{
                this.props.refresh();
            });
        }
    }

    render() {    
        return (
            <View style={styles.formNote}>
                <TextInput value={this.state.code} style={{color:"black", marginRight:5}} placeholder="CODENOTE"
                            onChangeText={(text)=>this.setState({code:text})}/>
                <Picker selectedValue={this.state.module}
                        onValueChange={(value)=>{this.setState({module:value})}}>
                    {this.props.modules.map((item, index)=>{
                        return <Picker.Item key={index} label={item.name} value={item.id}/>
                    })}
                </Picker>
                <Text style={{marginTop:8, marginLeft:10}}>Examen:</Text>
                <CheckBox style={styles.checkbox}
                    onValueChange={(value)=>this.setState({isexam: value?1:0})}
                    value={this.state.isexam ? true : false}/>
                <TouchableOpacity style={styles.button}
                                    onPress={()=>this.save()}>
                    <Text style={styles.buttonText}>Enregistrer</Text>
                </TouchableOpacity>
                {!this.props.createdForm&&
                    <TouchableOpacity style={styles.button}
                                        onPress={()=>this.delete()}>
                        <Text style={styles.buttonText}>Supprimer</Text>
                    </TouchableOpacity>
                }
            </View>
        );
    }   
}

const styles = StyleSheet.create({
    formNote:{
        flexDirection: Layout.window.width>450? "row":"column",
        marginTop:3,
        backgroundColor: 'white',
        flex: 1,
        padding:10,
        zIndex:100,
    },
    checkbox:{
        margin:10,
    },
    button:{
        padding:5,
        backgroundColor: Colors.tintColor,
        margin:5,
    },
    buttonText:{
        color: 'white'
    }
});