import React, {Component} from 'react';
import { connect } from 'react-redux';
import { updateRessource } from '../services/server_api';
import { Text, View, StyleSheet,TextInput, TouchableOpacity  } from 'react-native';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import Icon from "react-native-vector-icons/MaterialIcons";
const crypto = require("crypto");

class ProfilScreen extends Component{
    constructor(props){
        super(props);
        this.state= {login:"", password:"",confirmation:""};
    }

    save(){
        if(this.state.password.length<6){
            alert("Le mot de pass doit contenir minimum 6 caractères.")
        }else if(this.state.password!==this.state.confirmation){
            alert("Erreur dans la confirmation du mot de pass.")
        }else{
            updateRessource("user", this.props.user.user, {password:this.encodedPassword()}).then(result=>{
                if(result.status==200){
                    alert("Changement de mot de pass éffectué.");
                }else{
                    alert("Erreur lors du changement de mot de pass.");
                }
            });
        }
    }

    encodedPassword(){
        return crypto.createHash("sha512").update(this.state.password.trim()).digest("base64");
    }

    getUserLogin(){
        let user= this.props.user;
        return (user.email_int &&  user.email_int!="" && user.email_ext && user.email_ext!="") ?
                (user.email_int+" / "+user.email_ext) :  (user.email_int &&  user.email_int!="") ?
                user.email_int : user.email_ext;
    }

    render(){
        return (
        <View style={styles.container}>
            <Text style={styles.title}>{this.props.user.firstname} {this.props.user.lastname}</Text>
            <View style={styles.fieldInput}>
                <Icon name="account-circle" size={30} style={styles.iconInput}/>
                <Text style={styles.text}>{this.getUserLogin()}</Text>
            </View>
            <View style={styles.fieldInput}>
                <Icon name="lock" size={30} style={styles.iconInput}/>
                <TextInput style={styles.textInput}
                           secureTextEntry={true}
                           placeholder="Mot de passe"
                           onChangeText={(txt)=>this.setState({password:txt})}/>
            </View>
            <View style={styles.fieldInput}>
                <Icon name="lock" size={30} style={styles.iconInput}/>
                <TextInput style={styles.textInput}
                           secureTextEntry={true}
                           placeholder="Confirmation"
                           onChangeText={(txt)=>this.setState({confirmation:txt})}/>
            </View>
            <TouchableOpacity style={styles.buttonConnect}
                            onPress={()=>this.save()}>
                <Text style={styles.textConnect}>Changer de mot de passe</Text>
            </TouchableOpacity>
        </View>);
    } 
}

const mapStateToProps = state => {
    return {
        user: state.user.data,
    }
};

export default connect(mapStateToProps)(ProfilScreen);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#bbb',
        margin:10,
        width: Layout.window.width < 450 ? "auto" : Layout.window.width < 800 ? "80%": "50%",
        alignSelf:"center"
    },
    title:{
        textAlign:"center",
        color:Colors.tintColor,
        backgroundColor:"white",
        padding:10,
    },
    fieldInput:{
        flexDirection:"row",
        paddingLeft:30,
        paddingRight:30,
        margin:10,
        backgroundColor:"white"
    },
    iconInput:{
        flex:1,
        color: Colors.tintColor,
        alignSelf:"center"
    },
    text:{
        flex:7,
        margin:10,
    },
    textInput:{
        flex:7,
        margin:10,
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
    },
    buttonConnect:{
        backgroundColor: Colors.tintColor,
        margin:10,
        marginLeft:30,
        marginRight:30,
    },
    textConnect:{
        padding:10,
        color:"white",
        textAlign: "center",
    }
});