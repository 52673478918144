import React, {Component} from 'react';
import {Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import Layout from '../../../constants/Layout';
import {setModalVisibleInscription, setPromotions} from "../../../store/actions/adminActions";
import { connect } from 'react-redux';
import Colors from '../../../constants/Colors';
import FormStudent from '../../../components/FormStudent';
import { getRessourceChilds, createRessource, getRessources } from '../../../services/server_api';
import ModalContrat from '../../student/ModalContrat';
import SelectStudent from '../SelectStudent';
  
class ModalInscription extends Component {
    constructor(props){
        super(props);
        this.state={showSelectForm:false, 
                    showCreateForm:false,
                };
    }

    onSave(postData){
        createRessource("person", postData).then(result=>{
            if(result.status==200){
                let inscrData={student:result.response.insertId,
                               promotion:this.props.selectedPromotion,
                               type:3,
                               status:6
                            }
                createRessource("inscription", inscrData).then(resultInscr=>{
                    if(resultInscr.status==200){
                        alert("Enregistrement éffectué.");
                        this.props.setModalVisibleInscription(false);
                        this.refreshPromotions();
                    }else{
                        alert("Etudiant crée mais une erreur est survenu lors de l'inscription.");
                    }
                });
            }else{
                alert("Erreur lors de l'enregistrement de l'utilisateur, vérifier les données entrer.");
            }
        })
    }

    onSelect(student){
        if(confirm("Etes vous sûre d'inscrire cette étudiant dans cette promotion?")){
            let inscrData={student:student.id,
                            promotion:this.props.selectedPromotion,
                            type:3,
                            status:6
                        };
            createRessource("inscription", inscrData).then(resultInscr=>{
                if(resultInscr.status==200){
                    alert("Enregistrement éffectué.");
                    this.props.setModalVisibleInscription(false);
                    this.refreshPromotions();
                }else{
                    alert("Une erreur est survenu lors de l'inscription.");
                }
            });
        }
    }

    refreshPromotions(){
        getRessources("promotion").then(result=>{
            if(result.status==200){
                this.props.setPromotions(result.response);
            }
        });
    }

    render() {
        return (
        <View style={this.props.showModalInscription ? styles.container : {display:"none"}}>
        {
            this.props.showModalInscription&&
            <View style={styles.container}>
                <View style={styles.filter}>
                </View>
                <View style={styles.content}>
                    <Text style={styles.title}>Inscription promo #{this.props.selectedPromotion}</Text>
                    {!this.state.showSelectForm &&  !this.state.showCreateForm &&
                        <View style={styles.rowPart}>
                            <TouchableOpacity style={styles.button}
                                                onPress={()=>this.setState({showSelectForm:true})}>
                                <Text style={styles.text}>Choisir un(e) étudiant(e) existant(e)</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button}
                                                 onPress={()=>this.setState({showCreateForm:true})}>
                                <Text style={styles.text}>Créer un(e) étudiant(e)</Text>
                            </TouchableOpacity>
                        </View>
                    }
                    {this.state.showCreateForm &&
                        <FormStudent onSave={this.onSave.bind(this)}/>
                    }
                    {this.state.showSelectForm &&
                        <SelectStudent 
                            promotionStudents={[]}
                            onSelect={this.onSelect.bind(this)}/>
                    }
                    <TouchableOpacity 
                        style={styles.buttonClose}
                        onPress={()=>{this.props.setModalVisibleInscription(false);
                                      this.setState({showCreateForm:false, showSelectForm:false})}}>
                        <Text>X</Text>
                    </TouchableOpacity>
                </View>
            </View>
        }
        </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalInscription: state.admin.showModalInscription,
        selectedPromotion: state.admin.selectedPromotion,
        listStudents: state.admin.listStudents,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setModalVisibleInscription: data => {dispatch(setModalVisibleInscription(data));},
        setPromotions:  data => {dispatch(setPromotions(data));},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalInscription);

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    content:{
        zIndex: 120,
        margin:10,
        backgroundColor: 'white',
        alignSelf:"center"
    },
    title:{
        textAlign: "center",
        fontWeight:"bold",
        padding:10,
    },
    rowPart:{
        justifyContent:"center"
    },
    button:{
        backgroundColor: Colors.tintColor,
        alignSelf:"center",
        padding:3,
        margin:5,
        justifyContent:"center"
    },
    text:{
        textAlign: "center",
        fontWeight:"bold",
        color:"white",
        padding:10,
    },
    buttonClose:{
        position: "absolute",
        zIndex: 180,
        top:  0,
        right:-10,
        backgroundColor:  'white',
        padding:3,
    }
});