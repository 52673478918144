import React from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { connect } from 'react-redux';
import Promotion from '../../components/Promotion';

class ListPromotions extends React.Component {
    
    constructor(props){
        super(props);
        this.state= { showForm: false, selected:null}
    }

    render(){
        return <View style={styles.container}>
            { this.props.promotions.map((item, index) => (
                <Promotion key={index} data={item} onSelect={()=>this.setState({showForm:true, selected:item})}/>
            ))}
        </View>
    }
}

const mapStateToProps = state => {
    return {
        promotions: state.admin.promotionsFiltered 
    }
};

export default connect(mapStateToProps)(ListPromotions);

const styles = StyleSheet.create({
    container:{
        flexDirection: 'column',
        justifyContent:'center',
        alignItems:"stretch",
        alignContent:"stretch"
    }
});