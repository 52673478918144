import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../constants/Layout';
import {getRessourceChilds} from "../../services/server_api";
import Colors from '../../constants/Colors';
import Loader from '../../components/Loader';
import ModalReportStudent from './ModalReportStudent';

export default class ModalReports extends Component {
    constructor(props){
        super(props);
        this.state={
            reports:[],
            loader:false,
            selectedReport: null,
            showModalNotes: false
        };
    }

    componentDidMount(){
        this.setState({loader:true});
        getRessourceChilds('student', this.props.selectedStudent.id, 'reports').then(result=>{
            this.setState({reports: result.response, loader:false});
        });
    }
    
    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.selectedStudent && nextProps.selectedStudent!=this.props.selectedStudent){
            this.setState({loader:true});
            getRessourceChilds('student', nextProps.selectedStudent.id, 'reports').then(result=>{
                this.setState({reports: result.response, loader:false});
            });
        }
        return true;
    }

    render() {
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible && this.props.selectedStudent &&
                <View>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>Bulletins de notes {this.props.selectedStudent.lastname} {this.props.selectedStudent.firstname}</Text>
                        <ScrollView style={{backgroundColor:'white', flex: 1}}
                            contentContainerStyle={{
                                alignContent:'stretch',
                                justifyContent:'center',
                                alignItems:'center',
                            }}>
                            { this.state.reports.map((item, index) => (
                               <TouchableHighlight key={index} style={styles.itemReports}
                                    onPress={()=>{this.setState({selectedReport:item, showModalNotes:true})}}
                                >
                                   <Text style={{fontSize:18, color:"white"}}>{item.name}</Text>
                               </TouchableHighlight>
                            ))}
                        </ScrollView>
                        <Loader isVisible={this.state.loader}></Loader>
                        {this.state.selectedReport&&
                            <ModalReportStudent
                                isEditable={false}
                                isVisible={this.state.showModalNotes}
                                report={this.state.selectedReport}
                                student={this.props.selectedStudent}
                                onHide={()=>{this.setState({showModalNotes:false})}}
                            />
                        }
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={()=>this.props.onHide()}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'fixed',
        width: Layout.window.width,
        height: Layout.window.height,
        top: 0,
        left: 0,
        zIndex: 200
    },
    filter:{
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height,
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.5,
        zIndex: 210
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        zIndex: 220,
        alignSelf: 'center',
        justifyContent:"center",
        marginTop:200,
        padding:20,
        backgroundColor:'white'
    },
    itemReports:{
        padding:5,
        backgroundColor: Colors.tintColor,
        marginTop: 5
    },
    buttonClose:{
        zIndex: 190,
        position: "absolute",
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});