import React, { Component } from 'react'
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import Layout from '../constants/Layout'

export default class Loader extends Component {
  render() {
    return (
          this.props.isVisible?
            <View style={[styles.container, styles.horizontal]}>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>:<View></View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    position:"fixed",
    backgroundColor:"grey",
    width: Layout.window.width,
    height: Layout.window.height,
    top: 0,
    left: 0,
    zIndex: 1000,
    opacity:0.8
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10
  }
})