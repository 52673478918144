import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet} from "react-native";
import {formatTime, formatDate} from "../helpers/Formats";
import Colors from '../constants/Colors';
import {downLoadFile} from '../services/server_api';
import Layout from '../constants/Layout';
import Icon from "react-native-vector-icons/MaterialIcons";

export default class PlanningEvent extends Component{

    downloadSheetOfPresence(){
        let url= `promotion/${this.props.data.promotion}/planning/${this.props.data.id}/sheetOfPresence`;
        let fileName= "#"+this.props.data.promotion
                         +"_SheetOfPresence_"+formatDate(this.props.data.module_date).replace(/-/g,"")
                         +"_"+this.props.data.time_start.replace(/:/g,"")+".docx";
        downLoadFile(url,fileName);
    }

    render() {
        let item= this.props.data;
        return (
            <View style={styles.itemEvent}>
                {!this.props.editable?
                <View style={{flexDirection:"row", flex:1}}>
                    <Text style={styles.moduleTime}>{formatTime(item.time_start)}</Text>
                    <Text style={styles.moduleTime}>{formatTime(item.time_end)}</Text>
                    <TouchableOpacity onPress={()=>this.props.onSelectModule(item.module)}
                        style={{flex:1}}>
                        <Text style={styles.moduleName}>{item.name}  {this.props.displayPromo ? "#"+item.promotion:""}</Text>
                        <Text style={styles.moduleTime}>salle: {item.classroom}</Text>
                    </TouchableOpacity>
                </View>:
                <View style={{flex:1}}>
                    <View style={{flexDirection:"row"}}>
                        <Text style={styles.moduleTime}>{formatTime(item.time_start)}</Text>
                        <Text style={styles.moduleTime}>{formatTime(item.time_end)}</Text>
                    </View>
                    <TouchableOpacity onPress={()=>this.props.onSelectModule(item.module)}
                        style={{flex:1}}>
                        <Text style={styles.moduleName}>{item.name}  {this.props.displayPromo ? "#"+item.promotion:""}</Text>
                    </TouchableOpacity>
                    <Text style={styles.moduleTime}>salle: {item.classroom}</Text>
                </View>
                }
                {this.props.editable &&
                    <View style={{flexDirection:"row", justifyContent:"center", flex:1}}>
                        <View>
                            <TouchableOpacity style={styles.button} onPress={this.downloadSheetOfPresence.bind(this)}>
                                <Text style={styles.buttonText}>Feuille de presence</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button} onPress={()=>{
                                    this.props.showModalAbsences(item);
                                }}>
                                <Text style={styles.buttonText}>Saisie des absences</Text>
                            </TouchableOpacity>
                        </View>
                        <View>
                            <TouchableOpacity style={styles.button}
                                    onPress={()=> this.props.editEvent(item)}>
                                <Icon name="edit" size={20} style={{color:"white"}}/>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button}
                                    onPress={()=> this.props.deleteEvent(item)}>
                                
                                <Icon name="delete" size={20} style={{color:"white"}}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                }
            </View>
        );
    }   
}

const styles = StyleSheet.create({
    itemEvent:{
        flexDirection: "row",
        marginTop:3,
        backgroundColor: 'white',
        paddingTop:3,
        paddingBottom:3,
        zIndex:100,
    },
    moduleTime:{
        flex:0.2,
        padding:3,
        width: 210
    },
    moduleName:{
        flex:1,
        paddingLeft:5,
        padding:3,
        fontWeight:"bold"
    },
    button:{
        padding:5,
        backgroundColor: Colors.tintColor,
        margin:3,
        flex:1,
    },
    buttonText:{
        color: 'white'
    }
});