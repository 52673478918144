import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import { getRessources, getRessource, getRessourceChilds, deleteRessource } from '../services/server_api';
import Building from '../containers/planning/Building';
import { formatDate } from '../helpers/Formats';
import PromotionEvents from '../containers/planning/PromotionEvents';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../components/CustomDatePicker.css";
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import DigitalClock from '../components/DigitalClock';
import MessageBar from '../components/MessageBar';

export default class ManagePlanningScreen extends Component{

    constructor(props){
        super(props);
        this.state={events:[], dateSelected: new Date(), promosEvents:[],
                    showModalAbsences: false,
                    dataAbsences: [],
                    modules:[],
                    selectedEvent: null,
                    selectedPromotion: "",
                    editEvent:false,
                    listStudents: [],
                    promotions:[]
                };
    }

    componentDidMount(){
        getRessources("promotion").then(result=>{
            this.setState({promotions:result.response});
        });
        this.refresh(this.state.dateSelected);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextState.selectedPromotion!="" && nextState.selectedPromotion!=this.state.selectedPromotion){
            getRessourceChilds("promotion", nextState.selectedPromotion, "modules").then(result=>{
                this.setState({modules:result.response});
            });
        }
        return true;
    }

    refresh(dateSelected){
        getRessource("plannings", formatDate(dateSelected)).then(result=>{
            let events= result.response;
            let promosEvents={};
            events.map(item=>{
                if(!promosEvents[item.promotion]){
                    promosEvents[item.promotion]={events:[]};
                }
                promosEvents[item.promotion].events.push(item);
            });
            this.setState({events:events, promosEvents: promosEvents, editEvent:false});
        })
    }

    findPromotionName(idPromotion){
        let promo = this.state.promotions.find(element=> element.id==idPromotion)
        if(promo)
            return promo.name;
        else return null
    }

    

    render(){
        return <View style={styles.container}>
                {Layout.window.width>450&&
                    <Building events={this.state.events}/>
                }
                 <View style={styles.content}>
                    <View style={{position:"fixed", flexDirection:"row", zIndex:300, top: 20, left:20}}>
                        <DatePicker
                            className="datepicker-content"
                            selected={this.state.dateSelected}
                            showYearDropdown={true}
                            onChange={(date)=>{
                                this.setState({dateSelected: date});
                                this.refresh(date);
                            }}
                            dateFormat="dd/MM/yyyy"/>
                    </View>
                    <DigitalClock />
                    <MessageBar />
                 {Object.entries(this.state.promosEvents).map((item,key)=>{
                    let events=item[1].events;
                    let promotion= item[0];
                    return <PromotionEvents key={key}
                                titlePromo={this.findPromotionName(promotion)}
                                promotion={promotion}
                                events={events}
                                editable={false}
                            />
                 })}
                 </View>
               </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
        flexDirection:"row",
        alignContent: "baseline",
        overflow:"none",
    },
    content:{
        flex:1,
        flexDirection:"row",
        flexWrap:"wrap",
        overflow:"none",
        alignContent: "baseline"
    },
    title:{
        textAlign:"center",
        fontSize:24,
        color:Colors.tintColor,
    }

});