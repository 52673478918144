import React, {Component} from 'react';
import {Text, TouchableHighlight, TouchableOpacity, View, StyleSheet, TextInput, Picker,Image } from 'react-native';
import {updateRessource, createRessource} from '../services/server_api';
import Colors from '../constants/Colors';
import {formatStringDate} from '../helpers/Formats';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../components/CustomDatePicker.css";

export default class FormTutor extends Component {
    constructor(props){
        super(props);
        this.state={firstname:"", lastname:"", address: "", poste:"",
                    birthdate: new Date("1900-01-01"), birthplace: "",
                    email_ext: "", gender: "1", mobile: "", tel_pro: "", nationalite: "", society: ""};
    }

    componentDidMount(){
        let nextState={};
        if(!this.props.data)return;
        nextState= Object.assign(nextState, this.props.data);
        nextState.birthdate = this.props.data.birthdate ? new Date(this.props.data.birthdate) : "";
        this.setState(nextState);
    }

    shouldComponentUpdate(nextProps, nextState){
        //nextState= nextProps.selectedStudent;
        if(nextProps.data&& (this.props.data!=nextProps.data)){
            nextState= Object.assign(nextState, nextProps.data);
            nextState.birthdate = nextProps.data.birthdate ? new Date(nextProps.data.birthdate) : "";
        }
        return true;
    }

    save(){
        let postData= Object.assign({}, this.state);
        postData.society= this.props.society?  this.props.society.id : null;
        postData.birthdate= postData.birthdate!=""? formatStringDate(postData.birthdate):"1900-01-01";
        postData.regimSocial= postData.regimSocial ? postData.regimSocial: "0";
        postData.cp= postData.cp ? postData.cp: "0";
        //console.log("posData",postData);
        if(this.props.data && this.props.data.id){
            //helper check date a faire
            updateRessource('person',this.props.data.id, postData).then(result=>{
                if(result.status==200){
                    alert("Enregistrement Effectué");
                }else{
                    alert("Erreur lors de l'enregistrement. Verrifier que la personne n'est pas existante.");
                }
                if(this.props.refresh){
                    this.props.refresh(postData);
                }
            });
        }else{
            if(this.props.data && this.props.data.society){
                postData.society= this.props.society.id;
                postData.type= 4;
            }else if(this.props.type){
                postData.type= this.props.type;
            }else{
                postData.type= 4;
            }
            createRessource("person", postData).then(result=>{
                if(result.status==200){
                    this.props.onCreate(result.response.insertId);
                    alert("Enregistrement Effectué");
                }else{
                    alert("Erreur lors de l'enregistrement. Verrifier que la personne n'est pas existante.");
                }
                if(this.props.refresh){
                    this.props.refresh(postData);
                }
            });
        }
    }

    render() {
        return (
            <View style={styles.content}>
                <View style={styles.formContent}>
                    <View style={styles.formRow}>
                        <Picker
                            selectedValue={this.state.gender}
                            style={styles.textInput}
                            onValueChange={(itemValue, itemIndex) =>
                                this.setState({gender: itemValue})
                            }>
                            <Picker.Item label="Monsieur" value="1" />
                            <Picker.Item label="Madame" value="2" />
                        </Picker>
                        <TextInput onChangeText={text => this.setState({lastname:text})}
                                placeholder="Nom"
                                style={styles.textInput}
                                value={this.state.lastname}></TextInput>
                        <TextInput onChangeText={text => this.setState({firstname:text})}
                                placeholder="Prénom"
                                style={styles.textInput}
                                value={this.state.firstname}></TextInput>
                    </View>
                    <View style={styles.formRow}>
                        <TextInput onChangeText={text => this.setState({poste:text})}
                                placeholder="Poste"
                                style={styles.textInput}
                                value={this.state.poste}></TextInput>
                    </View>
                    <View style={styles.formRow}>
                        <TextInput onChangeText={text => this.setState({email_ext:text})}
                                placeholder="Email"
                                style={styles.textInput}
                                value={this.state.email_ext}></TextInput>
                    </View>
                    <View style={styles.formRow}>
                        <TextInput onChangeText={text => this.setState({tel_home:text})}
                                placeholder="Téléphone"
                                style={styles.textInput}
                                value={this.state.tel_home}></TextInput>
                        <TextInput onChangeText={text => this.setState({mobile:text})}
                                placeholder="Mobile"
                                style={styles.textInput}
                                value={this.state.mobile}></TextInput>
                    </View>
                    <View style={[styles.formRow]}>
                        <DatePicker
                            className="datepicker-content"
                            selected={this.state.birthdate}
                            placeholderText="date de naissance"
                            showYearDropdown={true}
                            onChange={(date)=>{
                                this.setState({birthdate: date});
                            }}
                            dateFormat="dd/MM/yyyy"/>
                        <TextInput onChangeText={text => this.setState({birthplace:text})}
                                placeholder="Lieu de naissance"
                                style={styles.textInput}
                                value={this.state.birthplace}></TextInput>
                        <TextInput onChangeText={text => this.setState({nationalite:text})}
                                placeholder="nationalite"
                                style={styles.textInput}
                                value={this.state.nationalite}></TextInput>
                    </View>
                    <View style={styles.formRow}>
                        <TouchableHighlight
                            style={styles.buttonSave}
                            onPress={this.save.bind(this)}>
                            <Text style={{color:"white", padding:10, textAlign:"center"}}>Enregistrer</Text>
                        </TouchableHighlight>
                        {this.props.onHide &&
                            <TouchableHighlight
                                style={styles.buttonSave}
                                onPress={()=>this.props.onHide()}>
                                <Text style={{color:"white", padding:10}}>Annuler</Text>
                            </TouchableHighlight>
                        }
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content:{
        flex: 4,
    },
    formContent:{
        flex: 1,
    },
    formRow:{
        flexDirection:'row',
        alignContent:'stretch',
        alignItems:'flex-end',
        marginBottom:10,
    },
    textInput:{
        flex:1,
        borderBottomWidth: 1,
        borderBottomColor: Colors.tintColor,
        color: Colors.tintColor,
        padding:2,
        margin:2
    },
    buttonSave:{
        flex:0.5,
        backgroundColor: Colors.tintColor,
        padding:1,
        margin:3,
    }
});
