const tintColor = '#2f95dc';

const comptaLevel1= "#191970";//'#0C3F42';//#B0C4DE
const comptaLevel2= "#4169E1";//'#1DC0CC';//#4169E1
const comptaLevel3= "#B0C4DE";//'#71E2EB';//#191970
const infoLevel1= '#C46A02';
const infoLevel2= '#F58E18';
const infoLevel3= '#F59D36';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',

  comptaLevel1: comptaLevel1,
  comptaLevel2: comptaLevel2,
  comptaLevel3: comptaLevel3,
  infoLevel1: infoLevel1,
  infoLevel2: infoLevel2,
  infoLevel3: infoLevel3,

  getPromoColor: (data)=>{
    if(data.type==="Informatique"){
      if(data.level==2){
        return infoLevel2;
      }else if(data.level==3){
        return infoLevel3;    
      }else{
        return infoLevel1;
      }
    }else{
      if(data.level==1){
        return comptaLevel1;
      }else if(data.level==2){
        return comptaLevel2;
      }else{
        return comptaLevel3;
      }
    }
  },

  getPromoColorFont: (data)=>{
    if(data.level==3){
      return "#000";    
    }else{
      return "#FFF";  
    }
  }
};
