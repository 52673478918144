import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../constants/Layout';
import { getRessourceChilds, postToDownLoadFile } from '../../services/server_api';
import { parseStudentsNotes, studentAddAverages, getReportType, parseDataFoFile } from '../../helpers/Reports';
import StudentModuleNotes from './StudentModuleNotes';
import EditableComment from '../../components/EditableComment';

export default class ModalReportsStudent extends Component {
    
    constructor(props){
        super(props);
        this.state={data:null, dataAvisGlobal:null}
    }

    componentDidMount(){
        this.refresh(this.props.student);
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.isVisible!=this.props.isVisible && nextProps.isVisible && nextProps.student){
            this.refresh(nextProps.student);
        }
        return true;
    }

    refresh(student){
        if(!student)return;
        getRessourceChilds("student", student.id, `report/${this.props.report.id}/notes`).then(async result=>{
            let studentModules= (await getRessourceChilds("student", student.id, "modules")).response;
            let dataNote= result.response.notes.filter(item=>{
                if (studentModules.length==0)return true;
                else return studentModules.indexOf(item.module) >=0;
            });
            let temp= parseStudentsNotes([student], dataNote);
            let reportType= getReportType(this.props.report.name);
            let parsedReportNotes= studentAddAverages(temp[0], reportType);
            this.setState({data:parsedReportNotes, dataAvisGlobal: result.response.avisGlobal});
        });
    }

    render() {
        console.log("data",this.state.data, this.state.dataAvisGlobal);
        return (
            <View style={this.props.isVisible ? styles.container : {display:"none"}}>
            {
                this.props.isVisible &&
                <View>
                    <View style={styles.filter}>
                    </View>
                    <View style={styles.content}>
                        <Text style={styles.title}>{this.props.report.name}</Text>
                        <Text style={styles.title}>{this.props.student.firstname} {this.props.student.lastname}</Text>
                        <View style={{flex:1, flexDirection:"row"}}>
                                <Text style={{flex:3, padding:10}}>
                                </Text>
                                <View style={{flex:10, flexDirection:"row"}}>
                                    <Text style={{flex:8, padding:5, textAlign:"center"}}>
                                        Controle continue
                                    </Text>
                                    <Text style={{flex:2, padding:5, textAlign:"center"}}>
                                        Moyenne
                                    </Text>
                                    <Text style={{flex:2, padding:5, textAlign:"center"}}>
                                        Examen
                                    </Text>
                                </View>
                                {Layout.window.width>450&&
                                    <Text style={{flex:7, padding:5, textAlign:"center"}}>
                                        Commentaires
                                    </Text>
                                }
                        </View>
                        {this.state.data&&
                        <ScrollView style={{height:400}}>
                            {Object.entries(this.state.data.notes).map((item,index)=>{
                                    let data= item[1];
                                    return <View key={index} style={{flexDirection:"row"}}>
                                            <StudentModuleNotes data={data} />
                                            {Layout.window.width>450&&
                                                <EditableComment
                                                    data={data.avis} 
                                                    module={data.id}
                                                    student={this.props.student.id} 
                                                    report={this.props.report.id}/>
                                            }
                                            </View>
                            })}
                        </ScrollView>
                        }
                        {this.state.data&&
                            <View style={{flex:1, flexDirection:"row"}}>
                                <Text style={{flex:1, padding:10}}>
                                    Moyenne générale: {this.state.data.globalAverage}
                                </Text>
                                {this.state.data.examAverage&&
                                    <Text style={{flex:1, padding:10}}>
                                        Moyenne examen: {this.state.data.examAverage}
                                    </Text>
                                }
                            </View>
                        }
                        {this.state.data&&
                            <View style={{height:"130px"}}>
                                <EditableComment data={this.state.data.avisGlobale} 
                                    data={this.state.dataAvisGlobal}
                                    module={null}
                                    student={this.props.student.id} 
                                    report={this.props.report.id}/>
                            </View>
                        }
                        <TouchableHighlight 
                            style={styles.buttonClose}
                            onPress={this.props.onHide}>
                            <Text>X</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        top: "60",
        left: 0,
        zIndex: 200
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.5,
        zIndex: 210
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center"
    },
    content:{
        zIndex: 345,
        width: Layout.window.width>450? Layout.window.width/1.5 : Layout.window.width,
        alignSelf: 'center',
        flex:1,
        padding:10,
        backgroundColor:'white',
    },
    listEvents:{
        
    },
    moduleName:{
        paddingLeft:5
    },
    buttonClose:{
        position: "absolute",
        zIndex: 350,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});