import React from 'react';
import colors from '../constants/ThemeColors';
import { View, Text, Picker} from 'react-native';

export default function SelectColors(props){
   
   return (
        <View style={style.select}>
            <Text>Color: </Text>
            <View style={{backgroundColor:props.value, width:"25px", height:"25px", marginHorizontal:"10px"}}/>
            <Picker
                style={style.select}
                selectedValue={props.value}
                onValueChange={props.onChange}>
                {Object.keys(colors).map(function(key, index) {
                    return  <Picker.Item  key={index} label={key} value={colors[key]} />
                })}
            </Picker>
        </View> 
    );
}

let style={
    select:{
        display: "flex",
        flexDirection: "row",
        padding: "10px",
        alignItems: "center"
    },
}
