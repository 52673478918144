import {getRessource, getRessourceChilds} from "../../services/server_api";


export const setPromotion = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_STUDENTPROMOTION",
            value: data
        });
    };
};

export const setReports = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_STUDENTREPORTS",
            value: data
        });
    };
};