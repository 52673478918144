export const setSelectedStudent = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_TUTORSELECTEDSTUDENT",
            value: data
        });
    };
};

export const setStudents = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_TUTORSTUDENTS",
            value: data
        });
    };
};