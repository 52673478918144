import React, {Component} from 'react';
import { View, Text, StyleSheet} from "react-native";
import Colors from '../constants/Colors';

export default class DigitalClock extends Component{

    constructor(props){
        super(props);
        this.state={hours: 0,
                    minutes: 0,
                    seconds: 0}
    }

    componentDidMount(){
        this.clock();
    }

    clock(){
        let hours= this.addZero(new Date().getHours())
        let minutes= this.addZero(new Date().getMinutes())
        let seconds= this.addZero(new Date().getSeconds())
        this.setState({hours: hours, minutes:minutes, seconds: seconds});
        setTimeout(this.clock.bind(this), 1000)
    }

    addZero(value){
        if(value<10){
            return "0"+value;
        }else{
            return value;
        }
    }
    render(){
        return  <View style={styles.content}>
                    <View style={styles.number}><Text style={styles.numberText}>{this.state.hours}</Text></View>
                    <Text style={{alignSelf:"center"}}>:</Text>
                    <View style={styles.number}><Text style={styles.numberText}>{this.state.minutes}</Text></View>
                    <Text style={{alignSelf:"center"}}>:</Text>
                    <View style={styles.number}><Text style={styles.numberText}>{this.state.seconds}</Text></View>
                </View>
    }
}

const styles = StyleSheet.create({
    content:{
        width:"98%",
        margin:5,
        justifyContent:"center",
        flexDirection:"row"
    },
    number:{
        margin:5,
        padding:15,
        backgroundColor: Colors.tintColor,
        borderRadius:15
    },
    numberText:{
        fontSize:32,
        color: "white"
    }
});