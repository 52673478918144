import * as React from 'react';
import { TextInput } from 'react-native';

export default function CustomTextInputDate(props) {
   let value= props.value ? props.value : "";
   if( value instanceof Date){
        value= value.toLocaleDateString("fr");
   }
   return <TextInput {...props}
            value={value}
        />;
}
