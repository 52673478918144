import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet, TextInput} from "react-native";
import Colors from '../constants/Colors';
import { noteValue } from '../helpers/Reports';
import { updateRessource, getRessourceChilds} from '../services/server_api';
import Loader from './Loader';

export default class EditableNote extends Component{
    
    constructor(props){
        super(props);
        this.state={modeEdit:false, value:this.props.data.value, loader:false}
    }

    onEndChange(e){
        let newVal= parseFloat(e.target.value);
        if(isNaN(newVal)||newVal<-2||newVal>20){
            alert("la valeur doit être comprise en -2 et 20.");
            this.setState({modeEdit:false, value:this.props.data.value});
        }else{
            this.setState({modeEdit:false});
            if(newVal!==this.props.data.value){
                this.setState({loader:true});
                updateRessource("note", this.props.data.id, {value: newVal}).then(result=>{
                    this.props.refresh();
                    this.setState({loader:false});
                });
            }
        }
    }


    render() {
        return (
        <View style={styles.container}>
            {!this.state.modeEdit &&
                <TouchableOpacity style={this.props.data.isexam? styles.buttonNoteExam : styles.buttonNote}
                    onPress={()=>this.setState({modeEdit:true})}
                >
                    <Text style={styles.textNote}>{noteValue(this.state.value)}</Text>
                </TouchableOpacity>
            }

            {this.state.modeEdit&&
                <View>
                    <TextInput style={styles.textNoteInput} 
                               value={this.state.value.toString()} 
                               onBlur={this.onEndChange.bind(this)}
                               onChangeText={(txt)=>this.setState({value:txt})}/>
                </View>
            }
            <Loader isVisible={this.state.loader}></Loader>
        </View>
        )
    }
}


const styles = StyleSheet.create({
    container:{
      margin: 5,
    },
    buttonNote:{
        backgroundColor:Colors.tintColor
    },
    buttonNoteExam:{
        backgroundColor:'#CD5C5C'
    },
    textNote:{
        color:"white",
        padding:5,
        width:47.5,
        textAlign:"center"
    },
    textNoteInput:{
        padding:5,
        width:47.5,
        backgroundColor: "gray",
        color:'white',
        textAlign:"center"
    }
});