import {getRessource, getRessourceChilds} from "../../services/server_api";

export const setPromotions = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_PROMOTIONS",
            value: data
        });
    };
};

export const setPromotionsFiltered = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_PROMOTIONS_FILTERED",
            value: data
        });
    };
};

export const setModalVisiblePromoStudents = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_MODAL_PROMOTIONSTUDENTS",
            value: data
        });
    };
};

export const setSelectedPromotion = (promotionId, data) => {
    return dispatch => {
        dispatch({
            type: "SET_SELECTED_PROMOTION",
            value: promotionId
        });
        dispatch({
            type: "SET_SELECTED_PROMOTIONDATA",
            value: data
        });
        if(promotionId!=null){
            getRessourceChilds('promotion', promotionId, 'students').then(result=>{
                dispatch({
                    type: "SET_LIST_STUDENTS",
                    value: result.response
                });
            });
        }
    };
};

export const setModalVisibleStudent = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_MODAL_STUDENT",
            value: data
        });
    };
};

export const setSelectedStudent = (data) => {
    return dispatch => {
        if(data!==null){
            getRessource('person', data).then(result=>{
                dispatch({
                    type: "SET_SELECTED_STUDENT",
                    value: result.response
                });
            });
        }else{
            dispatch({
                type: "SET_SELECTED_STUDENT",
                value: null
            });
        }
    };
};

export const setModalVisiblePromotionPlanning = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_MODAL_PROMOTIONPLANING",
            value: data
        });
    };
};

export const setModalVisiblePromotionReports = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_MODAL_PROMOTIONREPORTS",
            value: data
        });
    };
};

export const setModalVisibleInscription = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_MODAL_INSCRIPTION",
            value: data
        });
    };
};

export const setModalVisibleInscriptionModule = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_MODAL_INSCRIPTIONMODULE",
            value: data
        });
    };
};

export const setModalVisibleCandidate = (data) => {
    return dispatch => {
        dispatch({
          type: "SET_MODAL_CANDIDATE",
          value: data
        });
    };
};

export const setDataNotes = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_DATANOTES",
            value: data
        });
    };
};

export const setModalVisibleRename = (data) => {
  return dispatch => {
      dispatch({
        type: "SET_RENAME",
        value: data
      });
   };
};

export const setSelectedPromotionName = (data) => {
  return dispatch => {
      dispatch({
        type: "SET_OLD_NAME",
        value: data
      });
   };
};


