import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet} from "react-native";
import EditableNote from '../../../components/EditableNote';

export default class StudentModuleNotes extends Component{
    render() {
        return (
        <View style={styles.container}>
            <Text>{this.props.data.name}</Text>
            <View style={styles.notes}>
                {Object.entries(this.props.data.notes).map((item,key)=>{
                    let temp=item[1];
                    return <EditableNote key={key} data={temp} refresh={this.props.refresh}/>
                })}
                {this.props.data.average>=0 && this.props.data.notes.length>1 &&
                    <Text style={{width:45}}>|{this.props.data.average}|</Text>
                }
                {Object.entries(this.props.data.notesExams).map((item,key)=>{
                    let temp=item[1];
                    return <EditableNote key={key} data={temp} refresh={this.props.refresh}/>
                })}
            </View>
        </View>
        )
    }
}


const styles = StyleSheet.create({
    container:{
      backgroundColor:'white',
      padding:"10px",
      marginLeft:2,
      marginBottom:2,
      zIndex:-1,
    },
    notes:{
        flexDirection: 'row',
        alignItems: 'center'
    },
    itemNote:{
    }
});