import * as React from 'react';
import { GoogleLogin } from 'react-google-login-component';
import { View, Image, StyleSheet,Text, TouchableHighlight } from 'react-native';
import "./Login.css";
import FormLogin from './FormLogin';
import { onGoogleConnect } from '../../store/actions/userActions';
import { connect } from 'react-redux';

class LoginPage extends React.Component {

    render(){
        return  <View style={styles.container}>
                <View  style={styles.containerCenter}>
                    <Image   style={styles.brandLogo}
                              source={require('../../assets/images/cfa_insta.jpg')}/>
                    <View  style={styles.formLogin}>
                        <FormLogin />
                    </View>
                </View>
                <TouchableHighlight style={{position:"fixed", bottom:0, left:0, margin:20}}
                    onPress={()=>this.props.navigation.navigate("Planning")}>
                    <Text style={{color:"white"}}>Planning</Text>
                </TouchableHighlight>
            </View>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGoogleConnect: data => {dispatch(onGoogleConnect(data));}
    };
};

export default connect(null, mapDispatchToProps)(LoginPage);

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      justifyContent:"center",
      alignItems:"center"
    },
    containerCenter:{
        width: 300,
        borderColor: "black",
        borderWidth:1,
        overflow:"hidden"
    },
    brandLogo:{
        width: 300,
        height:300,
    },
    googleButton:{
        flexDirection:"row"
    },
    googleImage:{
        width: 64,
        height:64,
        marginTop:10,
        marginBottom:10,
    }
  });
  