import * as React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import PlanningEvent from "../../components/PlanningEvent";
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';

export default function PromotionEvents(props){
    return <View style={styles.content}>
            <Text style={styles.titlePromo}>#{props.promotion}</Text>
            {props.titlePromo &&
                <Text style={styles.titlePromo}>{props.titlePromo}</Text>
            }
            {props.events.map((item,key)=>{
                return <PlanningEvent key={key}
                            data={item}
                            displayPromo={false}
                            onSelectModule={(obj)=>props.onSelectModule(obj)}
                            editable={props.editable}
                            editEvent={props.editEvent}
                            deleteEvent={props.deleteEvent}
                            showModalAbsences={props.showModalAbsences}
                    />
            })}
    </View>;
}

const styles = StyleSheet.create({
    content:{
        width:Layout.window.width>800? "32%":"100%",
        margin:5,
        borderColor:"black",
        borderWidth:1,
        alignSelf:"baseline"
    },
    titlePromo:{
        textAlign:"center",
        padding:10,
        color:"white",
        fontWeight:"bolder",
        backgroundColor:Colors.tintColor
    }
});