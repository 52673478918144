import React, {Component, useState} from 'react';
import { View, StyleSheet, Text, TextInput, CheckBox, TouchableHighlight } from 'react-native';
import TextInputDate from '../../components/TextInputDate';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';


export default class FormContratStudent extends Component{

    constructor(props){
        super(props);
        this.state= Object.assign({}, props.data);
        delete this.state.dateinscr;
        delete this.state.type;
        delete this.state.status;
        this.state.dateEmbauche= this.state.dateEmbauche? new Date(this.state.dateEmbauche): new Date();
        this.state.dateExecution= this.state.dateExecution? new Date(this.state.dateExecution): new Date();
        this.state.dateAvenant= this.state.dateAvenant? new Date(this.state.dateAvenant): "";
        this.state.dateFinContrat= this.state.dateFinContrat? new Date(this.state.dateFinContrat): "";
        this.state.dateAnneeDebut1= this.state.dateAnneeDebut1? new Date(this.state.dateAnneeDebut1): new Date();
        this.state.dateAnneeFin1= this.state.dateAnneeFin1? new Date(this.state.dateAnneeFin1): new Date();
        this.state.dateAnneeDebut2= this.state.dateAnneeDebut2? new Date(this.state.dateAnneeDebut2): "";
        this.state.dateAnneeFin2= this.state.dateAnneeFin2? new Date(this.state.dateAnneeFin2): "";
        this.state.dateSignatureContrat= this.state.dateSignatureContrat? new Date(this.state.dateSignatureContrat): "";
        this.state.dateRuptureContrat = this.state.dateRuptureContrat? new Date(this.state.dateRuptureContrat): "";
        if(this.state.validationOPCO){
            this.isSelected = true;
        }
        else{
            this.isSelected = false;
        }
    }
 
    shouldComponentUpdate(nextProps, nextState){
        if(this.state!=nextState && nextProps.onChangeData){
            for(const i in nextState){
                if(this.verifDates(nextState[i])){
                    let temp= nextState[i].split("/");
                    let tempDate= new Date(parseInt(temp[2]), parseInt(temp[1])-1, parseInt(temp[0]));
                    nextState[i]= tempDate;
                }
            }
            nextProps.onChangeData(nextState)
        }
        return true;
    }

    verifDates(obj){
        if(typeof obj==='string'){
            let values= obj.split("/")
            if(values.length==3 && values[0].length==2 && values[1].length==2 && values[2].length==4){
                return true;
            }
        }
        return false;
    }
    
    setSelection(){
        if (this.isSelected){
            this.isSelected = false;
            this.setState({validationOPCO : 0});
        }else{
            this.isSelected = true;
            this.setState({validationOPCO : 1});
        }
    }

    render(){
        return <View style={styles.container}>
                    <View style={styles.rowPart}>
                        <View  style={styles.checkboxContainer}>
                            <Text style={styles.fieldText}>validation de l'OPCO : </Text>
                            <CheckBox
                                value={this.isSelected}
                                onValueChange={this.setSelection.bind(this)}
                                style={styles.checkbox}
                            />
                        </View>
                        <Text style={styles.fieldText}>numéro de dossier :</Text>
                        <TextInput
                                style={styles.textInput}
                                value={this.state.numDossier}
                                onChangeText={(text)=>this.setState({numDossier:text})}
                        />
                    </View>
                    <View style={styles.rowPart}>
                        <View style={{flex:1}}>
                            <Text style={styles.fieldText}>Date de conclusion (signature):</Text>
                        </View>
                        <View style={{flex:1}}>
                            <Text style={styles.fieldText}>Date d'exécution du contrat:</Text>
                        </View>
                        <View style={{flex:1}}>
                            <Text style={styles.fieldText}>Si avenant, date effet:</Text>
                        </View>
                    </View>
                    <View style={styles.rowPart}>
                        <View style={{flex:1}}>
                            <TextInputDate
                                style={styles.textInputDate}
                                value={this.state.dateSignatureContrat}
                                onChangeText={(text)=>this.setState({dateSignatureContrat:text})}
                            />
                        </View>
                        <View style={{flex:1}}>
                            <TextInputDate
                                style={styles.textInputDate}
                                value={this.state.dateExecution}
                                onChangeText={(text)=>this.setState({dateExecution:text})}
                            />
                        </View>
                        <View style={{flex:1}}>
                            <TextInputDate
                                style={styles.textInputDate}
                                value={this.state.dateAvenant}
                                onChangeText={(text)=>this.setState({dateAvenant:text})}
                            />
                        </View>
                    </View>         
                    <View style={styles.rowPart}>
                        <View style={{flex:1}}>
                            <Text style={styles.fieldText}>Date de fin du contrat ou de la période d'apprentissage:</Text>
                        </View>
                        <View style={{flex:1}}>
                            <Text style={styles.fieldText}>Durée hebdomadaire du travail (heures):</Text>
                        </View>
                    </View>
                    <View style={styles.rowPart}>
                        <View style={{flex:1}}>
                            <TextInputDate
                                style={styles.textInputDate}
                                value={this.state.dateFinContrat}
                                onChangeText={(text)=>this.setState({dateFinContrat:text})}
                            />
                        </View>
                        <View style={{flex:1}}>
                            <TextInput
                                style={[styles.textInputLittle, {marginLeft:25}]}
                                value={this.state.dureTravail ? this.state.dureTravail: ""}
                                onChangeText={(text)=>this.setState({dureTravail:text})}
                            />
                        </View>
                    </View>
                    <View style={styles.rowPart}>
                        <Text style={{fontWeight:"bold", marginLeft:25, marginTop:20}}>Rémunération :</Text>
                    </View>
                    <View style={styles.rowPart}>   
                        <Text style={styles.fieldText}> 1ère année, du </Text>
                        <TextInputDate
                            style={styles.textInputDate}
                            value={this.state.dateAnneeDebut1}
                            onChangeText={(text)=>this.setState({dateAnneeDebut1:text})}
                        />
                        <Text style={styles.fieldText}> au </Text>
                        <TextInputDate
                            style={styles.textInputDate}
                            value={this.state.dateAnneeFin1}
                            onChangeText={(text)=>this.setState({dateAnneeFin1:text})}
                        />
                        <Text style={styles.fieldText}> : </Text>
                        <TextInput 
                                style={styles.textInputLittle}
                                value={this.state.pourcentSmic}
                                onChangeText={(text)=>this.setState({pourcentSmic:text})}
                        />
                        <Text style={styles.fieldText}> % du SMIC </Text>
                    </View>
                    <View style={styles.rowPart}>   
                        <Text style={styles.fieldText}>2ème année, du</Text>
                        <TextInputDate
                            style={styles.textInputDate}
                            value={this.state.dateAnneeDebut2}
                            onChangeText={(text)=>this.setState({dateAnneeDebut2:text})}
                        />
                        <Text style={styles.fieldText}> au </Text>
                        <TextInputDate
                            style={styles.textInputDate}
                            value={this.state.dateAnneeFin2}
                            onChangeText={(text)=>this.setState({dateAnneeFin2:text})}
                        />
                        <Text style={styles.fieldText}> : </Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.pourcentSmic2}
                                onChangeText={(text)=>this.setState({pourcentSmic2:text})}
                        />
                        <Text style={styles.fieldText}>% du SMIC </Text>
                        
                    </View>        
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Salaire:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.salaire}
                                onChangeText={(text)=>this.setState({salaire:text})}
                        />
                        <Text>€</Text>
                    </View>
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Avantages en nature :    Nourriture:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.nouriture}
                                onChangeText={(text)=>this.setState({nouriture:text})}
                        />
                        <Text style={styles.fieldText}>Logement:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.logement}
                                onChangeText={(text)=>this.setState({logement:text})}
                        />
                        <Text style={styles.fieldText}>Prime:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.prime}
                                onChangeText={(text)=>this.setState({prime:text})}
                        />
                        <Text style={styles.fieldText}>Retraite:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.retraite}
                                onChangeText={(text)=>this.setState({retraite:text})}
                        />
                    </View>
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Pôle emploi:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.poleEmploi+""}
                                onChangeText={(text)=>this.setState({poleEmploi:text})}
                        />
                        <Text style={styles.fieldText}>Si oui, numéro d'inscription pôle emploi:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.inscriptionPoleEmploi}
                                onChangeText={(text)=>this.setState({inscriptionPoleEmploi:text})}
                        />
                        <Text style={styles.fieldText}>Durée pôle emploi (en mois):</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.durePoleEmploi}
                                onChangeText={(text)=>this.setState({durePoleEmploi:text})}
                        />
                    </View>
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Type de minimum social, si bénéficiaire :</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.minSocial}
                                onChangeText={(text)=>this.setState({minSocial:text})}
                        />
                        <Text style={styles.fieldText}>Niveau:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.niveau}
                                onChangeText={(text)=>this.setState({niveau:text})}
                        />
                        <Text style={styles.fieldText}>Coefficient hiérarchique: </Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.coef}
                                onChangeText={(text)=>this.setState({coef:text})}
                        />
                    </View>
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Nature du contrat:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.natureContrat}
                                onChangeText={(text)=>this.setState({natureContrat:text})}
                        />
                        <Text style={styles.fieldText}>Situation avant contrat:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.situationAvantContrat}
                                onChangeText={(text)=>this.setState({situationAvantContrat:text})}
                        />
                    </View>
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Dérogation:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.derogation}
                                onChangeText={(text)=>this.setState({derogation:text})}
                        />
                        <Text style={styles.fieldText}>Nombre d'ancien contrat:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.nbAncienContrat}
                                onChangeText={(text)=>this.setState({nbAncienContrat:text})}
                        />
                        <Text style={styles.fieldText}>Période d'essai:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.periodeEssai}
                                onChangeText={(text)=>this.setState({periodeEssai:text})}
                        />
                    </View>
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Diplôme le plus élevé:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.diplomePlusEleve}
                                onChangeText={(text)=>this.setState({diplomePlusEleve:text})}
                        />
                        <Text style={styles.fieldText}>Dernier diplôme:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.dernierDiplome}
                                onChangeText={(text)=>this.setState({dernierDiplome:text})}
                        />
                        <Text style={styles.fieldText}>Dernière classe:</Text>
                        <TextInput
                                style={styles.textInputLittle}
                                value={this.state.derniereClasse}
                                onChangeText={(text)=>this.setState({derniereClasse:text})}
                        />
                    </View>
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Dernier diplôme intitulé:</Text>
                        <TextInput
                                style={styles.textInput}
                                value={this.state.dernierDiplomeName}
                                onChangeText={(text)=>this.setState({dernierDiplomeName:text})}
                        />
                    </View>
                    <View style={styles.rowPart}>
                        <Text style={styles.fieldText}>Date de rupture :</Text>
                        <TextInputDate
                            style={styles.textInputDate}
                            value={this.state.dateRuptureContrat}
                            onChangeText={(text)=>this.setState({dateRuptureContrat:text})}
                        />
                    </View>
                </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    rowPart:{
        flexDirection: Layout.window.width > 450 ? 'row' : "column",
        flexWrap: "wrap",
        alignItems:"baseline",
        zIndex:-1,
        flex:1,
        marginBottom:20,
        marginTop:10,
    },
    title:{
        flex:1,
        backgroundColor:Colors.tintColor,
        color:"white",
        padding:10,
        marginTop:10,
        height:30,
        textAlign:"center",
        fontWeight:"bold"
    },
    fieldText:{
        color: "black",
        padding:5,
        flexDirection:"row",
        flexWrap:"nowrap",
        marginLeft:20,
    },
    textInput:{
        flex:1,
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        color:Colors.tintColor,
        padding:5,
        marginLeft:5,
    },
    textInputDate:{
        width:100,
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        color:Colors.tintColor,
        padding:5,
        marginLeft:25,
    },
    textInputLittle:{
        width:60,
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        color:Colors.tintColor,
        padding:5,
        margin:5
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 20,
    },
    checkbox: {
        alignSelf: "center",
    },
    button:{
        backgroundColor: "dodgerblue",
        padding:5,
        margin:5,
        marginLeft:15,
    }
});