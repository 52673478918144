import React, {Component} from 'react';
import { View, StyleSheet, Text, Picker } from 'react-native';
import { getRessourceChilds, getRessource } from '../services/server_api';
import TabShareStudent from '../containers/promotion/Share/TabShareStudent';
import { connect } from 'react-redux';
import TabShareSpace from '../containers/promotion/Share/TabShareSpace';
import Layout from '../constants/Layout';

class ShareScreenStudent extends Component{

    constructor(props){
        super(props);
        this.state={promotions:[], selectedPromotion:null}
    }

    componentDidMount(){
        getRessourceChilds('student', this.props.student.id, "contrats").then(async result=>{
          if(result.status==200 && result.response.length>0){
            let promotions=[];
            for(var i in result.response){
                let item= result.response[i];
                promotions.push((await getRessource("promotion",item.promotion)).response);
            }
            this.setState({promotions:promotions, selectedPromotion:promotions[0].id})
          }
        })
    }


    render(){
        return <View style={styles.container}>
                    <View style={{flex:1}}>
                        <Text style={styles.title}>Mon Espace perso</Text>
                        <TabShareStudent />
                    </View>
                    <View style={{  flex:2,
                                    borderLeftWidth:Layout.window.width > 450 ? 1 :0,
                                    borderTopWidth:Layout.window.width > 450 ? 0 :1}}>
                        <Text style={styles.title}>Espace promotion</Text>
                        <Picker selectedValue={this.state.selectedPromotion}
                            style={{margin:10, padding:3}}
                            onValueChange={(value)=>{this.setState({selectedPromotion:value})}}>
                            {this.state.promotions.map((item)=>{
                                    return <Picker.Item key={item.id} label={"#"+item.id+" - "+item.name} value={item.id}/>
                                })}
                        </Picker>
                        <TabShareSpace
                            promotion={this.state.selectedPromotion}
                        />
                    </View>
               </View>
    }
}

const mapStateToProps = state => {
    return {
        student: state.user.data,
    }
};
  
export default connect(mapStateToProps)(ShareScreenStudent);

const styles = StyleSheet.create({
    container:{
        flex:1,
        flexDirection: Layout.window.width > 450 ? "row" : "column"
    },
    content:{
        
    },
    title:{
       fontSize:24,
       marginLeft:25,
       padding:5,
    }
});