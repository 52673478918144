import React, {Component} from 'react';
import {Text, TouchableOpacity, View, StyleSheet, TextInput } from 'react-native';
import Layout from '../../constants/Layout';
import Colors from '../../constants/Colors';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import '../../assets/css/froala_style.css';
import FroalaEditor from 'react-froala-wysiwyg';
import { updateRessource, createRessource } from '../../services/server_api';

export default class ModalFormModule extends Component {
    constructor(props){
        super(props);
        this.state={code:"", name:"", description:""}
        if(props.data){
            Object.assign(this.state, props.data);
        }
    }

    save(){
        if(this.props.data){
            updateRessource("module", this.props.data.id, this.state).then(result=>{
                if(result.status==200){
                    alert("Enregistrement éffectué.");
                    this.props.onHide();
                    this.props.refresh();
                }
            });
        }else{
            createRessource("module", this.state).then(result=>{
                if(result.status==200){
                    alert("Enregistrement éffectué.");
                    this.props.onHide();
                    this.props.refresh();
                }
            });
        }
    }
   
    render() {
        return (
        <View style={this.props.isVisible ? styles.container : {display:"none"}}>
        {
            this.props.isVisible&&
            <View style={{flex:1}}>
                <View style={styles.filter}>
                </View>
                <View style={styles.content}>
                    <View style={styles.headerInputs}>
                        <TextInput  style={styles.codeInput} placeholder="Code"
                            onChangeText={(text)=>this.setState({code:text})}
                            value = {this.state.code}
                        />
                        <TextInput  style={styles.nameInput} placeholder="Nom du module"
                            onChangeText={(text)=>this.setState({name:text})}
                            value = {this.state.name}
                        />
                    </View>
                    <FroalaEditor
                        model={this.state.description}
                        onModelChange={(model)=>{this.setState({description:model})}}
                    />
                    <View style={styles.headerInputs}>
                    <TouchableOpacity  style={styles.button}  
                        onPress={()=>this.save()}  
                        >
                        <Text style={styles.text}>Enregistrer</Text>
                    </TouchableOpacity>
                    <TouchableOpacity  style={styles.button}    
                        >
                        <Text style={styles.text} onPress={()=>{this.props.onHide();}}>Annuler</Text>
                    </TouchableOpacity>
                    </View>
                    <TouchableOpacity 
                        style={styles.buttonClose}
                        onPress={()=>{this.props.onHide();}}>
                        <Text>X</Text>
                    </TouchableOpacity>
                </View>
            </View>
        }
        </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        zIndex: 100
    },
    filter:{
        position: 'absolute',
        width: "100%",
        height: "100%",
        backgroundColor: 'grey',
        top: 0,
        left: 0,
        opacity: 0.85,
        zIndex: 110
    },
    content:{
        zIndex: 120,
        margin:10,
        backgroundColor: '#ccc',
        flex:0.9,
    },
    title:{
        textAlign: "center",
        fontWeight:"bold",
        padding:10,
    },
    headerInputs:{
        flexDirection:"row",
    },
    codeInput:{
        flex:1,
        backgroundColor:"white",
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        padding:10,
        margin:5
    },
    nameInput:{
        flex:9,
        backgroundColor:"white",
        borderBottomColor:Colors.tintColor,
        borderBottomWidth:1,
        padding:10,
        margin:5
    },
    button:{
        backgroundColor: Colors.tintColor,
        padding:3,
        margin:5,
        justifyContent:"center"
    },
    text:{
        textAlign: "center",
        fontWeight:"bold",
        color:"white",
        padding:10,
    },
    buttonClose:{
        position: "absolute",
        zIndex: 180,
        top:  0,
        right:10,
        backgroundColor:  'white',
        padding:3,
    }
});