export function formatStringDate(date){
    return formatDate(date);
};

export function formatDate(date){
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
};

export function formatToStringFR(date){
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
};


export function formatTime(time){
   let temp =time.split(":")
   let tempArray= [];
   tempArray.push(temp[0]);
   tempArray.push(temp[1]);
   return tempArray.join(":");
};