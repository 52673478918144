import React, {Component} from 'react';
import { View, Text, StyleSheet, TextInput} from "react-native";
import EditableNote from '../../../components/EditableNote';
import EditableComment from '../../../components/EditableComment';
import Colors from '../../../constants/Colors';
import { getRessourceChilds } from '../../../services/server_api';
import { getModuleCode } from '../../../helpers/NoteCode';

export default class TabStudentModuleNotes extends Component{

    constructor(props){
        super(props)
        this.state= {notes: props.notes.filter((item)=>{
            return item.module==props.module.id && props.codes.indexOf(item.code)>-1 && item.student==props.student.id
        }),  avis:null}
    }

    componentDidMount(){
        this.refresh();
    }

    refresh(){
        if(!this.props.module)return;
        getRessourceChilds("student", this.props.student.id, `report/${this.props.report.id}/notes`).then(result=>{

        });
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.student && nextProps.module && (
            nextProps.student!==this.props.student||
            nextProps.module!==this.props.module||
            nextProps.report.id!==this.props.report.id||
            nextProps.codes.length!==this.props.codes.length
            )){
            nextState.avis=null;
            nextState.notes=[];
            getRessourceChilds("student", nextProps.student.id, `report/${nextProps.report.id}/notes`).then(result=>{
                let notes= result.response.notes.filter((item)=>{
                    return item.module==nextProps.module.id && nextProps.codes.indexOf(item.code)>-1 && item.student==nextProps.student.id
                });
                this.setState({notes:notes});
            });
        }
        return true;
        
    }

    render() {
        let tmp= this.state.notes.find(item=> {return item.module==this.props.module.id && item.avis!=null});
        let avis= tmp ? {id: tmp.avisId, value: tmp.avis} : null;
        return (
            <View style={{flexWrap:"wrap", flexDirection:"row", alignItems:"center", borderTopColor:Colors.tintColor, borderTopWidth:1}}>
                <Text style={{ width:200, padding:10}}>{this.props.student.lastname} {this.props.student.firstname}</Text>
                {this.props.codes.map((code, key)=>{
                    let note= this.state.notes.find(item=> {return item.code===code});
                    return <View key={key} style={{padding:5, width:150}}>
                            {note&&
                                <EditableNote key={key} data={note} refresh={()=>{}}/>
                            }
                            </View>
                })}
                <View style={{minWidth:300, flex:1}}>
                    <EditableComment
                        data={avis}
                        module={this.props.module.id}
                        student={this.props.student.id} 
                        report={this.props.report.id}/>
                    </View>
                </View>
        )
    }
}


const styles = StyleSheet.create({
    container:{
      backgroundColor:'white',
      padding:"10px",
      marginLeft:2,
      marginBottom:2,
    },
    notes:{
        flexDirection: 'row',
        alignItems: 'center'
    },
    itemNote:{
    }
});