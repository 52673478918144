import React, {Component} from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import Colors from '../../constants/Colors';
import FormSociety from '../../components/FormSociety';
import { updateRessource } from '../../services/server_api';
import SelectSociety from '../society/SelectSociety';
import Layout from '../../constants/Layout';

export default class FormContratSociety extends Component{

    constructor(props){
        super(props);
        this.state={showSelectForm:false, showCreateForm:false}
    }

    unlinkSociety(){
        if(confirm("Etes-vous sur de délier cette entreprise à ce contrat ?")){
            updateRessource("inscription", this.props.inscription.id, {tutor:null, society:null}).then(result=>{
                let inscription= Object.assign({}, this.props.inscription);
                inscription.society= null;
                inscription.tutor= null;
                this.props.refresh(inscription);
            });
        }
    }

    onSelectSociety(society){
        if(confirm("Etes-vous sur de lier cette entreprise à ce contrat ?")){
            updateRessource("inscription", this.props.inscription.id, {tutor:null, society:society.id}).then(result=>{
                let inscription= Object.assign({}, this.props.inscription);
                inscription.society= society.id;
                this.props.refresh(inscription);
            });
        }
    }

    onCreateSociety(societyId){
        updateRessource("inscription", this.props.inscription.id, {tutor:null, society:societyId}).then(result=>{
            let inscription= Object.assign({}, this.props.inscription);
            inscription.society= societyId;
            this.props.refresh(inscription);
        });
    }

    render(){
        return <View style={styles.container}>
                    {this.props.data &&
                        <View style={styles.rowPart}>
                            <FormSociety data={this.props.data}/>
                            {Layout.window.width > 450 &&
                                <TouchableOpacity style={styles.button}
                                    onPress={()=>this.unlinkSociety()}>
                                    <Text style={styles.text}>Délier cette entreprise</Text>
                                </TouchableOpacity>
                            }
                        </View>
                    }

                    {!this.props.data && this.state.showSelectForm && Layout.window.width > 450 &&
                        <SelectSociety onHide={()=>this.setState({showSelectForm:false})}
                                       onSelect={this.onSelectSociety.bind(this)}/>
                    }

                    {!this.props.data && this.state.showCreateForm && Layout.window.width > 450 &&
                        <FormSociety data={null}
                                     onHide={()=>this.setState({showCreateForm:false})}
                                     onCreate={this.onCreateSociety.bind(this)}
                        />
                    }

                    {!this.props.data && !this.state.showSelectForm &&  !this.state.showCreateForm && Layout.window.width > 450 &&
                        <View style={styles.rowPart}>
                            <TouchableOpacity style={styles.button}
                                                onPress={()=>this.setState({showSelectForm:true})}>
                                <Text style={styles.text}>Lier à une entreprise existante</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button}
                                                 onPress={()=>this.setState({showCreateForm:true})}>
                                <Text style={styles.text}>Créer une nouvelle entreprise</Text>
                            </TouchableOpacity>
                        </View>
                    }
                </View>
    }
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    title:{
        flex:1,
        backgroundColor:Colors.tintColor,
        color:"white",
        padding:10,
        marginTop:10,
        textAlign:"center",
        fontWeight:"bold"
    },
    rowPart:{
        flexDirection:'row',
        flex:1,
        justifyContent:"space-around",
        marginTop:10,
    },
    button:{
        padding:10,
        backgroundColor:Colors.tintColor,
        margin:5,
        flex:1,
        justifyContent:"center",
        alignItems:"center",
        alignSelf:"stretch"
    },
    text:{
        color:"white",
    }
});