import React, {Component} from 'react';
import { getRessourceChilds } from '../../services/server_api';
import { Text, View, ScrollView, StyleSheet  } from 'react-native';
import Colors from '../../constants/Colors';
import { formatDate } from '../../helpers/Formats';
import Layout from '../../constants/Layout';

export default class TabStudentAbsences extends Component{
    
    constructor(props){
        super(props);
        this.state={
            absences:[],
            loader:false,
            startDate: new Date((new Date()).setMonth((new Date()).getMonth()-2)),
            houresNoJustified: 0,
            houresJustified: 0,
            endDate: new Date()
        };
    }

    componentDidMount(){
        this.refresh(this.props.student.id);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.student && nextProps.student.id!=this.props.student.id){
            this.refresh(nextProps.student.id);
        }
        return true;
    }

    refresh(studentId){
        let startDate = formatDate(this.state.startDate);
        let endDate=  formatDate(this.state.endDate);
        getRessourceChilds('student', studentId, 'absences/'+startDate+"/"+endDate).then(result=>{
            this.setState({ absences: result.response.absences, 
                            houresNoJustified: result.response.houresNoJustified, 
                            houresJustified: result.response.houresJustified, 
                            loader:false});
        });
    }

    render(){
        let noJustified= this.state.houresNoJustified!=="aucune"? this.state.houresNoJustified:0
        let total = (this.state.houresJustified!=="aucune"? this.state.houresJustified : 0) + noJustified;
        let pNoJustified= 0;
        if(total>0){
            pNoJustified= Math.round((noJustified/total)*100);
        }
        return (
        <View style={styles.container}>
            <Text style={styles.title}>Absences de {this.props.student.firstname} {this.props.student.lastname}</Text>
            <View style={{flexDirection:'row', padding:10, backgroundColor:"white"}}>
                <View style={{flex:1}}>
                    <Text>Total absences : {total} heure(s)</Text>
                    <Text>{pNoJustified}% non justifié(s)</Text>
                    </View>
                <View style={{flex:1}}>
                    <Text>{this.state.houresJustified} heure(s) justifié(s)</Text>
                    <Text>{this.state.houresNoJustified} heure(s) non justifié(s)</Text>
                </View>
            </View>
            <View style={styles.itemAbsence}>
                <Text style={{flex:1, color:"white"}}>Date</Text>
                <Text style={{flex:1, color:"white"}}>Debut</Text>
                <Text style={{flex:1, color:"white"}}>Fin</Text>
                <Text style={{flex:1, color:"white"}}>Description</Text>
            </View>
            <ScrollView style={{backgroundColor:'white', flex:1}}>
                { this.state.absences.map((item, index) => (
                    <View key={index} style={styles.itemAbsence}>
                        <Text style={{flex:1}}>{item.absence_date}</Text>
                        <Text style={{flex:1}}>{item.absence_start}</Text>
                        <Text style={{flex:1}}>{item.absence_end}</Text>
                        <Text style={{flex:1}}>{item.description}</Text>
                    </View>
                ))}
            </ScrollView>
            
        </View>);
    } 
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#bbb',
        margin:10,
        width: Layout.window.width < 450 ? "auto" : Layout.window.width < 800 ? "80%": "50%",
        alignSelf:"center"
    },
    title:{
        textAlign:"center",
        color:Colors.tintColor,
        backgroundColor:"white"
    },
    itemAbsence:{
        padding:5,
        flexDirection:"row",
    },
});