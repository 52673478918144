
import { retrieveData, USERAVATAR, TOKENID, USERDATA } from "../../services/localStorage";
const jwt = require('jsonwebtoken');
const accessTokenSecret = 'moucavNarvloWaLesShmitts93130tinkieteTonton';
let userData= null
if(retrieveData(TOKENID)!=null){
    userData=jwt.verify(retrieveData(TOKENID), accessTokenSecret)
}
let INITIAL_STATE = {
    data: retrieveData(USERDATA)? JSON.parse(retrieveData(USERDATA)): userData,
    avatar: retrieveData(USERAVATAR) ? retrieveData(USERAVATAR)  : null,
};

let userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_USERDATA":
            return {...state, data: action.value};
        case "SET_USERAVATAR":
            return {...state, avatar: action.value};
        default:
            return state;
    }
};

export default userReducer;