import * as React from 'react';
import {
    DrawerContentScrollView,
    DrawerItemList,
} from '@react-navigation/drawer';
import UserDrawerInfo from './UserDrawerInfo';
  
export default function CustomDrawerContent(props) {
    return (
      <DrawerContentScrollView {...props}>
        <UserDrawerInfo />
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    );
  }