import React, {Component} from 'react';
import {Text, TouchableHighlight, View, StyleSheet, ScrollView} from 'react-native';
import Layout from '../../constants/Layout';
import { getRessourceChilds } from '../../services/server_api';
import { parseStudentsNotes, studentAddAverages, getReportType} from '../../helpers/Reports';
import StudentModuleNotes from './StudentModuleNotes';
import EditableComment from '../../components/EditableComment';
import Colors from '../../constants/Colors';

export default class TabReportsStudent extends Component {
    
    constructor(props){
        super(props);
        this.state={data:null}
    }

    componentDidMount(){
        this.refresh(this.props.student, this.props.report);
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.report.id!=this.props.report.id){
            this.refresh(nextProps.student, nextProps.report);
        }
        return true;
    }

    refresh(student, report){
        if(!student)return;
        getRessourceChilds("student", student.id, `report/${report.id}/notes`).then(result=>{
            let dataNote= result.response.notes;
            let temp= parseStudentsNotes([student], dataNote);
            let reportType= getReportType(this.props.report.name);
            let parsedReportNotes= studentAddAverages(temp[0], reportType);
            this.setState({data:parsedReportNotes});
        });
    }

    render() {
        return (
            <View style={styles.content}>
                <Text style={styles.title}>{this.props.student.firstname} {this.props.student.lastname}</Text>
                <View style={{flex:1, flexDirection:"row", backgroundColor:"#bbb"}}>
                        <Text style={{flex:3, padding:10}}>
                        </Text>
                        <View style={{flex:8, flexDirection:"row"}}>
                            <Text style={{flex:Layout.window.width >= 450? 4: 5, padding:10, borderColor:"white", borderWidth:1}}>
                                Controle continue
                            </Text>
                            <Text style={{flex:1, padding:10, borderColor:"white", overflow:"hidden", borderWidth:1}}>
                                Moy
                            </Text>
                            <Text style={{flex:1, padding:10, borderColor:"white", overflow:"hidden", borderWidth:1}}>
                                Exam
                            </Text>
                        </View>
                        {Layout.window.width >= 450 &&
                        <Text style={{flex:6, padding:10, textAlign:"center"}}>
                            Commentaires
                        </Text>
                        }
                </View>
                {this.state.data && 
                <ScrollView style={{flex:8,  overflowY: 'scroll'}}>
                    {Object.entries(this.state.data.notes).map((item,index)=>{
                            let data= item[1];
                            return <View key={index} style={{flexDirection:"row"}}>
                                    <StudentModuleNotes data={data} />
                                    {Layout.window.width >= 450 &&
                                        <Text style={styles.avisContent}>{data.avis ? data.avis.value : ""}</Text>
                                    }
                                    </View>
                    })}
                </ScrollView>
                }
                {this.state.data&&
                    <View style={{flex:1, flexDirection:"row"}}>
                        <Text style={{flex:1, padding:10}}>
                            Moyenne générale: {this.state.data.globalAverage}
                        </Text>
                        {this.state.data.examAverage&&
                            <Text style={{flex:1, padding:10}}>
                                Moyenne examen: {this.state.data.examAverage}
                            </Text>
                        }
                    </View>
                }
                {this.state.data&&
                    <View style={{flex:1}}>
                        <Text style={{padding:10, color: Colors.tintColor}}>
                            {this.state.data.avisGlobale? this.state.data.avisGlobale.value : ""}
                        </Text>            
                    </View>
                }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content:{
        zIndex: 345,
        alignSelf: 'center',
        padding:10,
        backgroundColor:'white',
        flex:1,
        margin:5,
    },
    title:{
        backgroundColor:"white",
        fontSize: 24,
        textAlign:"center",
        width: Layout.window.width < 450 ? Layout.window.width -40 : Layout.window.width/1.5
    },
    avisContent:{
        flex:1, 
        borderColor:Colors.tintColor, 
        borderWidth:1,
        padding:5,
        borderTopWidth:0
    },
    moduleName:{
        paddingLeft:5
    },
});